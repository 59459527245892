import React, { useRef } from 'react'
import { createTheme } from '@material-ui/core/styles';
import { alpha, Button, Card, CircularProgress, FormControl, IconButton, InputBase, makeStyles, MenuItem, Modal, MuiThemeProvider, Select, Snackbar, Tooltip, Typography } from '@material-ui/core'
import url, { colors, guideCut, packaging, provinces, municipalities, discountOverTotalPrice, discountOverValue } from '../../config'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useState } from 'react'
import { AttachMoney, Cancel, CreditCard, DeleteForever, Flight, LocationOn, Person, RemoveShoppingCart, Score } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { restart_cart_action, update_cart_action } from '../../redux/actions/cartAction'
import WarningAmberIcon from '@material-ui/icons/PriorityHigh'
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import b2_truck from '../../assets/promotional_banner/b2_truck.png'
import Navbar from '../global/NavbarGlobal';
import TitleWithBottomArrow from '../global/TitleWithBottomArrow';
import reduceDecimalValue from '../../tools/productTools';
import Footer from '../global/Footer';
import airTaxImage from '../../assets/img/air_tax_icon.png'
import walletImage from '../../assets/img/wallet_icon.jpg'
import { FavoritesPageProductList } from '../pagesComponents/FavoritesPage/FavoritesPageProductList';
import StripeCheckout from 'react-stripe-checkout';
import { update_user_action } from '../../redux/actions/userAction';
import getUserFields from '../../tools/userTools';

const themeMUI = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          color: "white",
          backgroundColor: "rgba(251,82,100,0.85)",
          borderTop: 'solid 2px rgb(251,82,100)'
        }
      }
    }
  });

const useStyles = makeStyles((theme) => ({
    cartHolder: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up('lg')]: {
            width: '1140px',
        },
        [theme.breakpoints.down('md')]: {
            width: '95%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            display: 'block'
        },
    },
    blueBackground: {
        backgroundColor: colors.cartBlueBackground,
        paddingTop: '30px',
        paddingBottom: '30px', 
    },
    cardContainer: {
        margin: '10px 20px',
    },
    informativeCartNote: {
        marginBottom: '15px',
        fontSize: '13px',
        textAlign: 'center',
        opacity: '0.8'
    },
    cartContainer: {
        flex: 1,
        backgroundColor: colors.whiteAppbar,
        marginLeft: '10px',
        flexDirection: 'column',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '30px',
            marginLeft: '0px'
        },
    },
    cartCheckOut: {
        marginLeft: '20px',
        marginRight: '10px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    checkOutHolder: {
        backgroundColor: colors.whiteAppbar,
        width: 'inherit',
        paddingTop: '25px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '20px',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '80%',
            display: 'flex'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'inherit',
            width: '100%',
        },
        
    },
    checkOutPrimaryText: {
        color: colors.primary,
        fontSize: '20px',
        fontWeight: '600',
        marginRight: 'auto'
    },
    remainingPointsText: {
        fontSize: '11px',
        fontWeight: '600',
        marginTop: '10px',
        marginBottom: '10px',
        textAlign: 'center',
    },
    totalText: {
        fontSize: '26px',
        fontWeight: '600',
        color: colors.secondary,
        paddingBottom: '10px',
        textAlign: 'center'
    },
    lightGrayDivider: {
        width: '90%',
        margin: '0px auto',
        height: '1.5px',
        backgroundColor: colors.secondary,
        opacity: '0.1',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    lightGrayDividerElement: {
        width: 'calc(100%-40px)',
        margin: '7px 20px',
        height: '1.5px',
        backgroundColor: colors.secondary,
        opacity: '0.2',
    },
    miscellanyContainer: {
        flex: 1,
    },
    miscellanyItems: {
        position: 'relative',
        //borderRight: `solid 1.5px ${colors.primary}`,
        //minHeight: '280px',
        marginBottom: '5px',
        [theme.breakpoints.down('sm')]:{
            borderRight: `solid 0px ${colors.primary}`,
        }
    },
    otherProductsContainer: {
        flex: 1
    }, 
    subTotalContainer: {
        [theme.breakpoints.down('xs')]:{
            display: 'none',
        }
    },
    otherProductsItems: {
        [theme.breakpoints.down('xs')]:{
            marginTop: '30px',
        }
    },
    cartTypeText: {
        fontSize: '18px',
        marginLeft: '0px',
        fontWeight: '700',
        marginBottom: '15px',
        //textAlign: 'center',
        color: colors.secondary
    }, 
    emptyCartContainer: {
        paddingTop: '80px',
        paddingBottom: '130px', 
        marginBottom: '30px'
    },
    emptyCartIcon: {
        height: '80px',
        width: '80px',
        margin: '0px auto 10px auto',
        display: 'block',
        color: colors.secondary,
    }, 
    emptyCartPrimaryText:{
        textAlign: 'center',
        fontWeight: '600',
    },
    emptyCartSecondaryText: {
        textAlign: 'center',
        fontSize: '14px'
    },
    elementHolder: {
        display: 'flex',
        margin: '10px 20px 20px 20px',
        [theme.breakpoints.down('xs')]: {
            margin: '20px 14px 10px 14px',
        }
    },
    imageContainer: {
        [theme.breakpoints.down('xs')]: {
            flex: 1, 
        }
    },
    elementImage: {
        height: '140px',
        width: '140px',
        borderRadius: '3px',
        marginRight: '6px'
    },
    elementInfoAndPrice: {
        display: 'flex',
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.down('xs')]: {
            flex: 1
        }
    },
    elementInfo: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginRight: 'auto',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'start',
        }
    },
    priceInfo: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginRight: 'auto',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: '10px',
            marginLeft: '10px'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    currencyPrice: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '8px',
            fontWeight: '700'
        }
    },
    priceInfoResponsive: {
        display: 'none',
        flex: 1,
        flexDirection: 'column',
        marginRight: 'auto',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'start'
        },
    },
    leftInfoIcon: {
        height: '20px',
        opacity: '0.87',
    },
    leftInfoIconFilled: {
        height: '14px',
        width: '15px',
        padding: '0px 1px',
        margin: '1px 4.5px',
        opacity: '0.77',
        backgroundColor: 'black',
        color: colors.whiteAppbar,
        borderRadius: '2px'
    },
    leftInfoTag: {
        fontSize: '10px',
        fontWeight: '600',
    },
    rightInfoText: {
        fontSize: '16px',
        fontWeight: '600',
        [theme.breakpoints.down('xs')]: {
            marginRight: '25px'
        }
    },
    elementText: {
        fontSize: '14px',
        fontWeight: '600',
        padding: '0px 15px',
        [theme.breakpoints.down('xs')]: {
            padding: '0px 0px',
        } 
    }, 
    elementPrice: {
        fontSize: '14px',
        fontWeight: '600',
        color: colors.secondary 
    }, 
    elementTotal: {
        display: 'flex',
        paddingBottom: '10px',
        justifyContent: 'flex-end',
        paddingTop: '5px',
        width: 'calc(100%)',
        borderTop: `solid 1.5px ${colors.primary}`
    },
    elementHeader: {
        fontSize: '16px',
        fontWeight: '600',
        paddingRight: '20px',
        paddingLeft: '20px'
    },
    elementSecondary: {
        fontSize: '16px',
        lineHeight: '20px',
        paddingRight: '20px',
        paddingLeft: '20px',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    airTaxImage: {
        height: '60px',
        width: '60px',
        marginLeft: '20px',
    },
    walletImage: {
        height: '60px',
        width: '60px',
        marginLeft: '27px',
    },
    elementUnit: {
        textAlign: 'end',
        paddingLeft: '5px',
        fontSize: '16px',
        fontWeight: '600',
        color: colors.secondary,
    },
    elementSecondaryInfoHeader: {
        fontSize: '12px',
        fontWeight: '600',
        opacity: '0.8'
    },
    elementSecondaryInfoUnit: {
        marginLeft: '3px',
        fontSize: '12px',
        fontWeight: '600',
        opacity: '0.8'
    },
    minusCart: { 
        border: `solid 2.3px ${colors.secondary}`,
        marginLeft: '5px',
        width: '30px',
        borderRight: `solid 1.5px ${colors.secondary}`,
        backgroundColor: colors.secondary,
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
        fontSize: '18px',
        textAlign: 'center',
        lineHeight: '17px',
        fontWeight: '800',
        color: colors.whiteAppbar,
        cursor: 'pointer',
        userSelect: 'none',
        '&:hover': {
            backgroundColor: alpha(colors.secondary, 0.7)
        },
    },
    sumCart: {
        border: `solid 2.3px ${colors.secondary}`,
        width: '30px',
        borderLeft: `solid 1.5px ${colors.secondary}`,
        backgroundColor: colors.secondary,
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
        fontSize: '18px',
        textAlign: 'center',
        lineHeight: '20px',
        fontWeight: '800',
        userSelect: 'none',
        color: colors.whiteAppbar,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: alpha(colors.secondary, 0.7)
        }
    },
    cartCount: {
        borderTop: `solid 2.3px ${colors.cartProductCountBackground}`,
        borderBottom: `solid 2.3px ${colors.cartProductCountBackground}`,
        backgroundColor: colors.cartProductCountBackground,
        fontSize: '14px',
        paddingLeft: '12px',
        paddingRight: '12px',
        textAlign: 'center',
        lineHeight: '22px',
        fontWeight: '700',
    },
    deleteButton: {
        marginLeft: '15px', 
        fontSize: '29px',
        '&:hover': {
            color: colors.redError,
            cursor: 'pointer'
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: '0px',
            marginLeft: '4px',
            fontSize: '16px',
            paddingTop: '5px',
            paddingBottom: '5px'
        }
    },
    makeOrderButton: {
        color: 'white',
        //border: `solid 1.5px ${colors.secondary}`,
        marginTop: '10px',
        borderRadius: '0px',
        fontSize: '18px',
        width: '100%',
        backgroundColor: colors.secondary,
        boxShadow: '0px 0px 0px',
        '&:hover':{
            backgroundColor: alpha(colors.secondary, 0.9),
            boxShadow: '0px 0px 0px',
        },
        [theme.breakpoints.down('sm')]: {
            width: 'inherit',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    confirmButton: {
        height: '35px',
        color: colors.secondary,
        border: `solid 1.5px ${colors.secondary}`,
        marginTop: '10px',
        marginBottom: '2px',
        backgroundColor: 'white',
        '&:hover':{
            backgroundColor: alpha(colors.secondary, 0.2)
        }
    },
    countAndPriceHolder: {
        marginLeft: '10px', 
        display: 'flex',  
        alignItems: 'center',
        [theme.breakpoints.down('xs')]:{
            display: 'none'
        }
    },
    inputHolder: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        marginTop:'5px',
        marginBottom: '10px',
        backgroundColor:'#ffffff', 
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);', 
        borderRadius: '3px',
        '&:hover': {
          boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
        },
        '&:focus': {
          boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
        }
    },
    inputHolderFlex: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    inputRoot: {
        width: '100%',
    },
    inputInput: {
        height: 24,
        fontSize: '1rem',
        fontWeight: 400,
        // vertical padding + font size from searchIcon
        transition: theme.transitions.create('width'),
        width: '100%',
        paddingLeft: '5px',
        paddingRight: '5px',
        '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: 'rgba(0, 0, 0, 0.54)',
            opacity: 1 /* Firefox */
        },
        
        '&:-ms-input-placeholder': { /* Internet Explorer 10-11 */
            color: 'rgba(0, 0, 0, 0.54)'
        },
        
        '&::-ms-input-placeholder': { /* Microsoft Edge */
            color: 'rgba(0, 0, 0, 0.54)'
        },
    },
    modalPaper: {
        position: 'absolute',
        top: `50%`,
        left: `50%`,
        width: '400px',
        maxHeight: '90%',
        maxWidth: '95%',
        overflowY: 'scroll',
        transform: `translate(-50%, -50%)`,
        borderRadius: '3px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    cancelButton: {
        display: 'block',
        marginLeft: 'auto',
        padding: '6px'
    },
    errorButton: {
        color: colors.redError,
        padding: '3px',
        marginTop:'5px',
        "&:hover, &.Mui-focusVisible": { 
            backgroundColor: colors.redErrorOpacity,
        }
    },
    arrow: {
        fontSize: 20,
        color: "#4A4A4A",
        "&::before": {
            backgroundColor: colors.redError,
        }
    },
    dataSelect: {
        paddingLeft: '10px',
    },
    formControl: {
        flex: 1
    },
    dividerLongSize: {
        width: '10px',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    colorIcon: {
        color: colors.primary,
        padding:'0px',
        paddingLeft: '6px'
    },
    favoritesList: {
        marginTop: '30px',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up('lg')]: {
            width: '1140px',
        },
        [theme.breakpoints.down('md')]: {
            width: '95%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            display: 'block'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
}))

const AddDeliveryAddressModal = ({open, index, onConfirm, onError, onClose}) => {
    const classes = useStyles()
    const history = useHistory()

    const [adrName, setAdrName] = useState("")
    const [adrNameErrorOpen, setAdrNameErrorOpen] = useState(false)
    const [adrNameError, setAdrNameError] = useState("")

    const [adrAddress, setAdrAddress] = useState("")
    const [adrAddressErrorOpen, setAdrAddressErrorOpen] = useState(false)
    const [adrAddressError, setAdrAddressError] = useState("")

    const [adrRegionSelected, setAdrRegionSelected] = useState("La Habana")
    const [adrRegionSelectedErrorOpen, setAdrRegionSelectedErrorOpen] = useState(false)
    const [adrRegionSelectedError, setAdrRegionSelectedError] = useState("")

    const [adrMunicipalityIndex, setAdrMunicipalityIndex] = useState(9)
    const [adrMunicipalitySelected, setAdrMunicipalitySelected] = useState("Municipio")
    const [adrMunicipalitySelectedErrorOpen, setAdrMunicipalitySelectedErrorOpen] = useState(false)
    const [adrMunicipalitySelectedError, setAdrMunicipalitySelectedError] = useState("")

    const [adrPhone, setAdrPhone] = useState("")
    const [adrPhoneErrorOpen, setAdrPhoneErrorOpen] = useState(false)
    const [adrPhoneError, setAdrPhoneError] = useState("")

    const [adrCI, setAdrCI] = useState("")
    const [adrCIErrorOpen, setAdrCIErrorOpen] = useState(false)
    const [adrCIError, setAdrCIError] = useState("")

    const [addingAddress, setAddingAddress] = useState(false)

    const user = useSelector((state) => state.user_store.user)
    const userRef = useRef()
    userRef.current = user
    const dispatch = useDispatch()

    useEffect(() => {
        if(index >= 0){
            setAdrName(userRef.current.deliveryAddresses[index].name)
            setAdrAddress(userRef.current.deliveryAddresses[index].address)
            setAdrRegionSelected(userRef.current.deliveryAddresses[index].region)
            setAdrMunicipalitySelected(userRef.current.deliveryAddresses[index].municipality)
            setAdrPhone(userRef.current.deliveryAddresses[index].phone)
            setAdrCI(userRef.current.deliveryAddresses[index].ci)
        }
        else{
            setAdrName("")
            setAdrAddress("")
            setAdrRegionSelected("La Habana")
            setAdrMunicipalitySelected("Municipio")
            setAdrPhone("")
            setAdrCI("")
        }
    }, [index])

    useEffect(() => {
        if(user){
            userRef.current = user
        }
    }, [user])

    

    const handleTooltipErrors = (tooltipSelected) => {
        handleAdrNameErrorTooltipClose()
        handleAdrAddressErrorTooltipClose()
        handleAdrRegionErrorTooltipClose()
        handleAdrMunicipalityErrorTooltipClose()
        handleAdrCIErrorTooltipClose()
        handleAdrPhoneErrorTooltipClose()
        switch (tooltipSelected) {
          case 'adrName':{
            if(adrNameError) handleAdrNameErrorTooltipOpen()
            break;
          }
          case 'adrAddress':{
            if(adrAddressError) handleAdrAddressErrorTooltipOpen()
            break;
          }
          case 'adrRegion':{

            break;
          }
          case 'adrMunicipality':{
            
            break;
          }
          case 'adrCI':{
            if(adrCIError) handleAdrCIErrorTooltipOpen()
            break;
          }
          default:{
            if(adrPhoneError) handleAdrPhoneErrorTooltipOpen()
            break;
          }
        }
    }

    const handleChangeAdrName = (event) => {
        if(adrNameError){
            setAdrNameError("")
            handleAdrNameErrorTooltipClose()
        }
        setAdrName(event.target.value);
    };
    const handleChangeAdrAddress = (event) => {
        if(adrAddressError){
            setAdrAddressError("")
            handleAdrAddressErrorTooltipClose()
        }
        setAdrAddress(event.target.value);
    };
    const handleChangeAdrRegion = (event) => {
        handleTooltipErrors("adrRegion")
        setAdrMunicipalitySelected("Municipio")
        setAdrMunicipalityIndex(provinces.indexOf(event.target.value))
        setAdrRegionSelected(event.target.value);
    };
    const handleChangeAdrMunicipality = (event) => {
        handleTooltipErrors("adrMunicipality")
        setAdrMunicipalitySelected(event.target.value);
    };
    const handleChangeAdrPhone = (event) => {
        if(adrPhoneError){
            setAdrPhoneError("")
            handleAdrPhoneErrorTooltipClose()
        }
        setAdrPhone(event.target.value);
    };
    const handleChangeAdrCI = (event) => {
        if(adrCIError){
            setAdrCIError("")
            handleAdrCIErrorTooltipClose()
        }
        setAdrCI(event.target.value);
    };

    
    const handleAdrNameErrorTooltipClose = () => {
        setAdrNameErrorOpen(false);
    };
    const handleAdrNameErrorTooltipOpen = () => {
        setAdrNameErrorOpen(true);
    };
    const handleAdrAddressErrorTooltipClose = () => {
        setAdrAddressErrorOpen(false);
    };
    const handleAdrAddressErrorTooltipOpen = () => {
        setAdrAddressErrorOpen(true);
    };
    const handleAdrRegionErrorTooltipClose = () => {
        setAdrRegionSelectedErrorOpen(false);
    };
    const handleAdrRegionErrorTooltipOpen = () => {
        setAdrRegionSelectedErrorOpen(true);
    };
    const handleAdrMunicipalityErrorTooltipClose = () => {
        setAdrMunicipalitySelectedErrorOpen(false);
    };
    const handleAdrMunicipalityErrorTooltipOpen = () => {
        setAdrMunicipalitySelectedErrorOpen(true);
    };
    const handleAdrPhoneErrorTooltipClose = () => {
        setAdrPhoneErrorOpen(false);
    };
    const handleAdrPhoneErrorTooltipOpen = () => {
        setAdrPhoneErrorOpen(true);
    };
    const handleAdrCIErrorTooltipClose = () => {
        setAdrCIErrorOpen(false);
    };
    const handleAdrCIErrorTooltipOpen = () => {
        setAdrCIErrorOpen(true);
    };

    const onClickConfirm = async (token) => {
        if(addingAddress)return
        let errorType = ""
        try{
            if(!adrCI){
                setAdrCIError("Debe poner un # de Carnet de Identidad.")
                errorType = "9"
            }
            else{
                const adrCINumber = parseInt(adrCI)
                if(!adrCINumber && adrCINumber.length !== 11){
                    setAdrCIError("Debe poner un número de 11 dígitos.")
                    errorType = "9"
                }
            }
            if(!adrPhone){
                setAdrPhoneError("Debe poner un # de Teléfono.")
                errorType = "8"
            }
            else{
                for (let index = 0; index < adrPhone.length; index++) {
                    const element = adrPhone[index]
                    if(index === 0 && element === '+') continue;
                    const adrPhoneNumber = parseInt(element)
                    if(!adrPhoneNumber && adrPhoneNumber !== 0){
                        setAdrPhoneError("Debe poner un número válido.")
                        errorType = "8"
                    }
                }
            }
            if(adrMunicipalitySelected === 'Municipio'){
                setAdrMunicipalitySelectedError("Debe poner un Municipio.")
                errorType = "7"
            }
            if(adrRegionSelected === 'Provincia'){
                setAdrRegionSelectedError("Debe poner una Provincia.")
                errorType = "6"
            }
            if(!adrAddress){
                setAdrAddressError("Debe poner una dirección.")
                errorType = "5"
            }
            if(!adrName){
                setAdrNameError("Debe poner el nombre y los apellidos.")
                errorType = "4"
            }

            if(errorType){
                switch(errorType){
                    case "4":{
                        handleAdrNameErrorTooltipOpen()
                        break;
                    }
                    case "5":{
                        handleAdrAddressErrorTooltipOpen()
                        break;
                    }
                    case "6":{
                        handleAdrRegionErrorTooltipOpen()
                        break;
                    }
                    case "7":{
                        handleAdrMunicipalityErrorTooltipOpen()
                        break;
                    }
                    case "8":{
                        handleAdrPhoneErrorTooltipOpen()
                        break;
                    }
                    case "9":{
                        handleAdrCIErrorTooltipOpen()
                        break;
                    }
                    default: 
                        break;
                }
            }
            else{
                setAddingAddress(true)
                try{
                    let newAddress = {
                        name: adrName,
                        phone: adrPhone,
                        ci: adrCI,
                        address: adrAddress,
                        municipality: adrMunicipalitySelected,
                        region: adrRegionSelected,
                        isDefault: user.deliveryAddresses.length > 0? false : true
                    }
                    let deliveryAddresses = user.deliveryAddresses
                    if(index >= 0){
                        newAddress.isDefault = deliveryAddresses[index].isDefault
                        deliveryAddresses[index] = newAddress
                    }
                    else{
                        deliveryAddresses.push(newAddress)
                    }
                    const requestOptions = {
                        method: 'PATCH',
                        headers: { 'Content-Type': 'application/json',
                                    'Authorization': `${user.accessToken}`
                        },
                        body: JSON.stringify({
                            deliveryAddresses  
                        })
                    };
                    let result = await fetch(`${url}/api/accountImport/updateDeliveryAddresses`, requestOptions);
                    if(result.status === 200){
                        const data = await result.json()
                        const userToUpdate = getUserFields(data.user, user.accessToken)
                        dispatch(update_user_action(userToUpdate))
                        onConfirm(index)
                        setAdrAddress("")
                        setAdrCI("")
                        setAdrPhone("")
                        setAdrName("")
                        setAdrMunicipalitySelected(9)
                        setAdrMunicipalitySelected("Municipio")
                    }
                    else{
                        onError(index)
                    }
                    setAddingAddress(false)
                }
                catch(e){
                    console.log(e)
                    setAddingAddress(false)
                    onError(index)
                }
            }
        }
        catch(e){
            console.log(e)
            setAddingAddress(false)
        }
    }

    const onHandleClose = () => {
        if(addingAddress) return
        handleAdrNameErrorTooltipOpen()
        handleAdrAddressErrorTooltipOpen()
        handleAdrRegionErrorTooltipOpen()
        handleAdrMunicipalityErrorTooltipOpen()
        handleAdrPhoneErrorTooltipOpen()
        handleAdrCIErrorTooltipOpen()
        onClose()
    }

    return(
        <React.Fragment>
            <Modal
                open={open}
                onClose={onHandleClose}
                aria-labelledby="make order"
                aria-describedby="data necessary to process order"
                >
                <div className={classes.modalPaper}>
                    <IconButton size="large" onClick={onClose} aria-label="warning" className={classes.cancelButton}>
                        <Cancel />
                    </IconButton> 
                    
                    <React.Fragment>
                        <Typography className={classes.cartTypeText} style={{marginBottom: '10px', marginTop: '-10px'}}>DIRECCION DE ENTREGA</Typography>
                        <div className={classes.inputHolder}>
                            <Person className={classes.colorIcon}/>
                            <InputBase
                                onClick={() => 
                                    {
                                        handleTooltipErrors('adrName')
                                    }}
                                elevation={4}
                                placeholder="NOMBRE(S) Y APELLIDOS"
                                value={adrName}
                                onChange={handleChangeAdrName}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search', maxLength:'80' }}
                            />
                            {adrNameError &&
                            <div>
                                <MuiThemeProvider theme={themeMUI}>
                                    <Tooltip classes={{arrow: classes.arrow}}
                                        arrow
                                        PopperProps={{
                                        disablePortal: true,
                                        }}
                                        onClose={handleAdrNameErrorTooltipClose}
                                        open={adrNameErrorOpen}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title={adrNameError}
                                    >
                                        <IconButton onClick={handleAdrNameErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                            <WarningAmberIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </MuiThemeProvider>
                            </div>                       
                            }
                        </div>

                        <div className={classes.inputHolder}>
                            <LocationOn className={classes.colorIcon} style={{alignSelf: 'flex-start', marginTop: '4px'}}/>
                            <InputBase
                                onClick={() => 
                                    {
                                    handleTooltipErrors('adrAddress')
                                    }}
                                elevation={4}
                                placeholder="DIRECCION DE ENTREGA ( NO TIENE QUE COINCIDIR CON LA DEL CARNET DE IDENTIDAD )"
                                value={adrAddress}
                                minRows={4}
                                maxRows={4}
                                multiline
                                onChange={handleChangeAdrAddress}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search', maxLength:'160' }}
                            />
                            {adrAddressError &&
                            <div style={{alignSelf: 'flex-start'}}>
                                <MuiThemeProvider theme={themeMUI}>
                                    <Tooltip classes={{arrow: classes.arrow}}
                                        arrow
                                        PopperProps={{
                                        disablePortal: true,
                                        }}
                                        onClose={handleAdrAddressErrorTooltipClose}
                                        open={adrAddressErrorOpen}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title={adrAddressError}
                                    >
                                        <IconButton onClick={handleAdrAddressErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                            <WarningAmberIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </MuiThemeProvider>
                            </div>                       
                            }
                        </div>

                        <div className={classes.inputHolderFlex}>
                            <div style={{flex: 1}} className={classes.inputHolder}>
                                <FormControl className={classes.formControl}>
                                    <Select
                                    value={adrRegionSelected}
                                    onChange={handleChangeAdrRegion}
                                    disableUnderline
                                    className={classes.dataSelect}
                                    inputProps={{ 'aria-label': 'region' }}
                                    >
                                        {provinces.map(provinceName => (  
                                            <MenuItem value={provinceName}>{provinceName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {adrRegionSelectedError &&
                                <div>
                                    <MuiThemeProvider theme={themeMUI}>
                                        <Tooltip classes={{arrow: classes.arrow}}
                                            arrow
                                            PopperProps={{
                                            disablePortal: true,
                                            }}
                                            onClose={handleAdrRegionErrorTooltipClose}
                                            open={adrRegionSelectedErrorOpen}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            title={adrRegionSelectedError}
                                        >
                                            <IconButton onClick={handleAdrRegionErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                                <WarningAmberIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </MuiThemeProvider>
                                </div>                       
                                }
                            </div>
                            <div className={classes.dividerLongSize}/>
                            <div style={{flex: 1}} className={classes.inputHolder}>
                            <FormControl className={classes.formControl}>
                                <Select
                                value={adrMunicipalitySelected}
                                onChange={handleChangeAdrMunicipality}
                                disableUnderline
                                className={classes.dataSelect}
                                inputProps={{ 'aria-label': 'municipality' }}
                                >
                                    <MenuItem value={"Municipio"}>Municipio</MenuItem>
                                    {municipalities[adrMunicipalityIndex].map(municipalityName => (  
                                        <MenuItem value={municipalityName}>{municipalityName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                                {adrMunicipalitySelectedError &&
                                <div>
                                    <MuiThemeProvider theme={themeMUI}>
                                        <Tooltip classes={{arrow: classes.arrow}}
                                            arrow
                                            PopperProps={{
                                            disablePortal: true,
                                            }}
                                            onClose={handleAdrMunicipalityErrorTooltipClose}
                                            open={adrMunicipalitySelectedErrorOpen}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            title={adrMunicipalitySelectedError}
                                        >
                                            <IconButton onClick={handleAdrMunicipalityErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                                <WarningAmberIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </MuiThemeProvider>
                                </div>                       
                                }
                            </div>
                        </div>
                        
                        <div className={classes.inputHolderFlex}>
                            <div style={{flex: 1}} className={classes.inputHolder}>
                                <LocalPhoneIcon className={classes.colorIcon}/>
                                <InputBase
                                    onClick={() => 
                                        {
                                        handleTooltipErrors('adrPhone')
                                        }}
                                    elevation={4}
                                    type="text"
                                    placeholder="Teléfono (Cuba)"
                                    value={adrPhone}
                                    onChange={handleChangeAdrPhone}
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search', maxLength:'20' }}
                                />
                                {adrPhoneError &&
                                <div>
                                    <MuiThemeProvider theme={themeMUI}>
                                        <Tooltip classes={{arrow: classes.arrow}}
                                            arrow
                                            PopperProps={{
                                            disablePortal: true,
                                            }}
                                            onClose={handleAdrPhoneErrorTooltipClose}
                                            open={adrPhoneErrorOpen}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            title={adrPhoneError}
                                        >
                                            <IconButton onClick={handleAdrPhoneErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                                <WarningAmberIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </MuiThemeProvider>
                                </div>                       
                                }
                            </div>
                            <div className={classes.dividerLongSize}/>
                            <div style={{flex: 1}} className={classes.inputHolder}>
                                <CreditCard className={classes.colorIcon}/>
                                <InputBase
                                    onClick={() => 
                                        {
                                        handleTooltipErrors('adrCI')
                                        }}
                                    elevation={4}
                                    placeholder="C. Identidad"
                                    value={adrCI}
                                    onChange={handleChangeAdrCI}
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search', maxLength:'20' }}
                                />
                                {adrCIError &&
                                <div>
                                    <MuiThemeProvider theme={themeMUI}>
                                        <Tooltip classes={{arrow: classes.arrow}}
                                            arrow
                                            PopperProps={{
                                            disablePortal: true,
                                            }}
                                            onClose={handleAdrCIErrorTooltipClose}
                                            open={adrCIErrorOpen}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            title={adrCIError}
                                        >
                                            <IconButton onClick={handleAdrCIErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                                <WarningAmberIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </MuiThemeProvider>
                                </div>                       
                                }
                            </div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            {addingAddress?
                                <Button className={classes.confirmButton} variant='contained'>
                                    <CircularProgress size={20} style={{color: colors.secondary}}/>
                                </Button>
                            :
                                <Button onClick={onClickConfirm} className={classes.confirmButton} variant='contained'>
                                    {index >= 0? "EDITAR" : "AGREGAR"}
                                </Button>
                            }
                        </div>
                    </React.Fragment>
                </div>
            </Modal>
        </React.Fragment>
    )
}


export default AddDeliveryAddressModal