import { UPDATE_CATEGORIES } from "../actions/categoriesAction";

const default_cart = {
    categories: [],
}

function categories_store(state = default_cart, action){
    switch(action.type) {
        case UPDATE_CATEGORIES: {
            return{
                categories: action.payload.categories,
            }
        }
        default: return state;
    }
}

export default categories_store