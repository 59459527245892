import { UPDATE_USER, REMOVE_USER, UPDATE_USER_WALLET } from "../actions/userAction";

const getAccessToken = () => {
    const userLocal = window.localStorage.getItem(`user`)
    if(userLocal){
        const token = JSON.parse(userLocal).accessToken
        if(token) return token
        return null
    }
    return null
}

const default_user = {
    user: null,
    accessToken: getAccessToken()
}

function user_store(state = default_user, action){
    switch(action.type) {
        case UPDATE_USER: {
            return{
                user : {...action.payload},
                accessToken: action.payload.accessToken
            }
        }
        case UPDATE_USER_WALLET: {
            return{
                user : {...state.user, wallet: action.payload},
                accessToken: state.accessToken
            }
        }
        case REMOVE_USER: {
            return{
                user: null,
                accessToken: null
            }
        }
        default: return state;
    }
}

export default user_store