let url = "https://sevende.herokuapp.com"
// url = "http://localhost:5000"
const appShareUrl = "https://sevendeimport.com"
const supportEmail = "sevendeimport.info@gmail.com"

const allowedAduanaPoints = 500
const sendingCostValue = 5.0
const packaging = 0
const guideCut = 25
const discountOverValue = 10000000000
const discountOverTotalPrice = 25
const discountOverTotalPriceMessage = "Compra mayor a 350 usd"

const supportNumber = '529902054191'

const superAdminImportCompanyOptionsSelectors = {
    importCompanyPercent: {
        min: 0,
        max: 100
    },
    importCompanyFixed: {
        min: 0,
        max: 1000
    },
    walletLimitForProducts: 500,
    deliveryCalendar: {
        minHoursTime: 1,
        maxHoursTime: 336,
    }
}

const paymentTypeSelectors = [
    "OnDelivery",
    "OnlineStripe",
    // "OnlineTransfer",
]

const superAdminUserOptionsSelectors = {
    userPromotedEarnings: 100,
    discountCouponProductDiscount: 100,
    discountCouponEarningDiscount: 100
}

const calendarTimeOptions = {
    hours: [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ],
    minutes: [ 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
    ampm: [ "AM", "PM"]
}

const phoneAreas = ['+53', '+52', '+34', '+1', '+51', '+55']

const provinces = ["Provincia", "Artemisa", "Camagüey", "Ciego de Ávila", "Cienfuegos", "Granma",
    "Guantánamo", "Holguín", "Isla de la Juventud", "La Habana",
    "Las Tunas", "Matanzas", "Mayabeque", "Pinar del Río", "Sancti Spíritus",
    "Santiago de Cuba", "Villa Clara"]
const municipalities = [
    [

    ],
    [
        "Artemisa",
        "Alquízar",
        "Bahía Honda",
        "Bauta",
        "Caimito",
        "Candelaria",
        "Guanajay",
        "Güira de Melena",
        "Mariel",
        "San Antonio de los Baños",
        "San Cristóbal"],
    [
        "Camagüey",
        "Carlos Manuel de Céspedes",
        "Esmeralda",
        "Florida",
        "Guaimaro",
        "Jimagüayú",
        "Minas",
        "Najasa",
        "Nuevitas",
        "Santa Cruz del Sur",
        "Sibanicú",
        "Sierra de Cubitas",
        "Vertientes"
    ],
    [
        "Chambas",
        "Ciego de Ávila",
        "Ciro Redondo",
        "Baraguá",
        "Bolivia",
        "Florencia",
        "Majagua",
        "Morón",
        "Primero de Enero",
        "Venezuela"
    ],
    [
        "Abreus",
        "Aguada de Pasajeros",
        "Cienfuegos",
        "Cruces",
        "Cumanayagua",
        "Palmira",
        "Rodas",
        "Santa Isabel de las Lajas"
    ],
    [
        "Bartolomé Masó",
        "Bayamo",
        "Buey Arriba",
        "Campechuela",
        "Cauto Cristo",
        "Guisa",
        "Jiguaní",
        "Manzanillo",
        "Media Luna",
        "Niquero",
        "Pilón",
        "Río Cauto",
        "Yara"
    ],
    [
        "Baracoa",
        "Caimanera",
        "El Salvador",
        "Guantánamo",
        "Imías",
        "Maisí",
        "Manuel Tames",
        "Niceto Pérez",
        "San Antonio del Sur",
        "Yateras"
    ],
    [
        "Antilla",
        "Báguanos",
        "Banes",
        "Cacocum",
        "Calixto García",
        "Cueto",
        "Frank País",
        "Gibara",
        "Holguín",
        "Mayarí",
        "Moa",
        "Rafael Freyre",
        "Sagua de Tánamo",
        "Urbano Noris"
    ],
    [
        "Nueva Gerona",
        "Otros lugares"
    ],
    [
        "Arroyo Naranjo",
        "Boyeros",
        "Centro Habana",
        "Cerro",
        "Cotorro",
        "Diez de Octubre",
        "Guanabacoa",
        "Habana del Este",
        "Habana Vieja",
        "La Lisa",
        "Marianao",
        "Plaza de la Revolución",
        "Playa",
        "Regla",
        "San Miguel del Padrón"
    ],
    [
        "Amancio Rodríguez",
        "Colombia",
        "Jesús Menéndez",
        "Jobabo",
        "Las Tunas",
        "Majibacoa",
        "Manatí",
        "Puerto Padre"
    ],
    [
        "Calimete",
        "Cárdenas",
        "Ciénaga de Zapata",
        "Colón",
        "Jagüey Grande",
        "Jovellanos",
        "Limonar",
        "Los Arabos",
        "Martí",
        "Matanzas",
        "Pedro Betancourt",
        "Perico",
        "Unión de Reyes"
    ],
    [
        "Batabanó",
        "Bejucal",
        "Güines",
        "Jaruco",
        "Madruga",
        "Melena del Sur",
        "Nueva Paz",
        "Quivicán",
        "San José de las Lajas",
        "San Nicolás de Bari",
        "Santa Cruz del Norte"
    ],
    [
        "Consolación del Sur",
        "Guane",
        "La Palma",
        "Los Palacios",
        "Mantua",
        "Minas de Matahambre",
        "San Juan y Martínez",
        "San Luis",
        "Sandino",
        "Pinar del Río",
        "Viñales"
    ],
    [
        "Cabaiguán",
        "Fomento",
        "Jatibonico",
        "La Sierpe",
        "Sancti Spíritus",
        "Taguasco",
        "Trinidad",
        "Yaguajay"
    ],
    [
        "Contramaestre",
        "Guamá",
        "Julio Antonio Mella",
        "Palma Soriano",
        "San Luis",
        "Santiago de Cuba",
        "Segundo Frente",
        "Songo la Maya",
        "Tercer Frente"
    ],
    [
        "Caibarién",
        "Camajuaní",
        "Cifuentes",
        "Corralillo",
        "Encrucijada",
        "Manicaragua",
        "Placetas",
        "Quemado de Güines",
        "Ranchuelo",
        "Remedios",
        "Sagua la Grande",
        "Santa Clara",
        "Santo Domingo"
    ]]

const colors = {
    primary: '#3AC3DA',
    primaryLight: '#C8F7FF',
    secondary: '#3A3A3C',
    secondaryDark: '#191970', //CAMBIAR EL AZUL A MAS OSCURO
    whiteAppbar: '#ffffff',
    containerGradientFinal: '#94DEEB',
    topSellGradientStart: '#49E4D9',
    topSellGradientEnd: '#B7F3EF',
    lightGrayLogo: '#6D6E71',
    topSellHomePage: '#F3C702',
    republishButton: '#49E38D',
    orangeEdit: '#FFC50B',
    orangeEditDark: '#FF8417',
    redHeart: '#FF6B7C',
    redError: 'rgb(251,82,100)',
    redErrorOpacity: 'rgba(251,82,100,0.2)',
    blueShare: '#32C5FF',
    purpleHeart: '#7E57C2',
    greenCart: '#49E38D',
    requestedDarkKhaki: '#BDB76B',
    confirmedDarkCyan: '#008B8B',
    packagingDarkMagenta: '#8B008B',
    editAvailableCount: '#8B008B',
    sendedDarkSeaGreen: '#8FBC8F',
    recieveDarkGreen: '#006400',
    red: '#BA3B28',
    lightGreen: '#9CEEA4',
    blueLink: '#457DB6',
    officialStore: "#4169E1",
    premium: "#FFD700",
    instagram: '#DD2A7B',
    facebook: '#4267B2',
    fastDeliveryTag: '#ADD57C',
    importTag: '#98D3F2',
    importTagBySea: '#749DC6',
    possibleDiscountTag: '#D67EA3',
    productPromotedTag: '#DCC473',
    cartBlueBackground: '#E9FCFF',
    decoraTuHogar: '#F2E3D3',
    electrodomesticos: '#A8A8A8',
    aseoHigieneSalud: '#ABDCDB',
    cartProductCountBackground: '#ECECEC',
}

const iconsPath = {
    homeIcon: "M127.3,248C61.5,248,8.1,193.9,8,127.5C8,62.3,61.1,7.7,128.3,8C195.2,8.3,248.1,60.7,248,128  C247.8,198,192,248.1,127.3,248z M197.5,141.4c0-6.3-0.5-12.6,0.1-18.8c1.1-11.2-1.7-19.9-11.3-26.8c-9.1-6.5-17-14.7-25.3-22.2  c-2.7-2.4-4.7-2.4-7.4,0.2c-5.2,4.9-10.6,9.6-16.2,14c-2.7,2.1-2.7,3.6-0.2,5.8c8.6,7.5,16.9,15.3,25.6,22.6c3.1,2.6,4.1,5.2,4,9  c-0.1,17.2,0,34.5-0.1,51.7c0,4.3,1.5,5.7,5.6,5.5c4.2-0.3,8.5,0,12.7-0.1c8,0,12.4-4.4,12.5-12.4  C197.5,160.4,197.5,150.9,197.5,141.4z M59,127.1C59,127.1,59,127.1,59,127.1c0,5,0,9.9,0,14.9c0,2.9,1.3,3.4,3.4,1.6  c3-2.5,6-5.1,9-7.7c25.7-22.6,51.4-45.2,77.2-67.8c2.3-2,2.2-3.4,0.1-5.3c-3.2-2.8-6.3-5.7-9.5-8.4c-8.7-7.5-13.4-7.2-22,0.6  c-14.1,12.7-28.6,24.9-42.6,37.7c-15.9,14.6-15.9,9.4-15.7,33.1C59,126.2,59,126.7,59,127.1z M128.4,182.3c8.6,0,17.2-0.1,25.8,0  c3.2,0,4.9-1,4.8-4.5c-0.1-7.3-0.1-14.6,0-21.9c0-3.2-1.3-4.4-4.4-4.4c-17.5,0.1-35,0.1-52.5,0c-3.5,0-4.6,1.4-4.6,4.7  c0.1,7.1,0.1,14.3,0,21.4c-0.1,3.4,1.3,4.7,4.7,4.6C110.9,182.2,119.6,182.3,128.4,182.3z M90.4,155.9c0-6.9,0-13.7,0-20.6  c0-1.4,0.7-3.4-0.6-4.1c-1.6-0.9-2.8,1.1-3.9,2c-7.7,6.7-15.4,13.4-23,20.2c-5.5,4.9-5.4,19.7-0.1,25.3c2.2,2.4,4.8,3.7,8.1,3.6  c4.7-0.1,9.4-0.2,14,0c4.1,0.2,5.8-1.2,5.6-5.5C90.2,169.9,90.4,162.9,90.4,155.9z"
}

export default url
export {
    allowedAduanaPoints,
    colors,
    iconsPath,
    provinces,
    municipalities,
    appShareUrl,
    supportNumber,
    supportEmail,
    phoneAreas,
    sendingCostValue,
    packaging,
    guideCut,
    discountOverValue,
    discountOverTotalPrice,
    discountOverTotalPriceMessage,
    superAdminImportCompanyOptionsSelectors,
    superAdminUserOptionsSelectors,
    calendarTimeOptions,
    paymentTypeSelectors
}