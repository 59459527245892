import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ProductCard from "../../global/ProductCard";
import SkeletonCard from "../../global/SkeletonCard";
import { useSelector } from "react-redux";


const useStyles = makeStyles((theme) => ({
    
}))

  

 const ProductInfoContainer = ({product}) => {
  const [favoritesList,setFavoritesList] = useState({})
  const userLogged = useSelector((state) => state.user_store.user)

  useEffect(() => {
    loadFavoritesList()
  }, [userLogged])

  const loadFavoritesList = (abort) => {
    if(userLogged){
        const userId = userLogged._id
        const data = window.localStorage.getItem(`importFavorites`)
        if(data){
            const newData = JSON.parse(data)
            if(newData[`${userId}`]){
                setFavoritesList(newData[`${userId}`])
            }
        }
    }
    
}
    
  return (
    <React.Fragment>
      {product?
        <ProductCard product={product} isFavorite={favoritesList[`${product._id}`]} detailPage={true}/>
      :
        <SkeletonCard detailPage={true}/>
      }
    </React.Fragment>
  )
}

export default ProductInfoContainer