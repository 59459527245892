import { UPDATE_CART, RESTART_CART } from "../actions/cartAction";

const default_cart = {
    initLoading: true,
    count: 0,
    products: [],
    points: 0
}

function cart_store(state = default_cart, action){
    switch(action.type) {
        case UPDATE_CART: {
            return{
                ...state,
                count: state.count + action.payload.count,
                products: action.payload.products,
                points: action.payload.points,
                initLoading: false 
            }
        }
        case RESTART_CART: {
            return{
                ...state,
                count: action.payload.count,
                products: action.payload.products,
                points: action.payload.points
            }
        }
        default: return state;
    }
}

export default cart_store