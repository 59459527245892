import React, {useState, useEffect, useRef, memo} from 'react';
import ProductCard from '../../global/ProductCard';
import SkeletonCard from '../../global/SkeletonCard';
import Masonry from 'react-masonry-css';
import url from '../../../config';
import NoOtherProducts from '../../global/NoOtherProducts';
import NoConnection from '../../global/NoConnection';
import { makeStyles } from '@material-ui/core';

const userStyles = makeStyles((theme) => ({
    mainContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up('lg')]: {
            width: '1140px',
        },
        [theme.breakpoints.down('md')]: {
            width: '90%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '95%',
        },
    },
    errorConnection: {
        [theme.breakpoints.up('md')]: {
            marginLeft: '250px',
            marginBottom: '100px'
        }
    },
    productElement: {
        width: '208px',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('lg')]: {
            width: '90%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '90%',
        }
    },
}))
const skeleton = [-1,-2,-3,-4,-5,-6,-7,-8,-9,-10]

export const hasReached = (el) => {
    return el.getBoundingClientRect().top <= window.innerHeight;
  }

export const ProductsDetailPageProductList = memo((props) => {
    const classes = userStyles()
    const [loading, setLoading] = useState(true)
    const [apiError, setApiError] = useState(false)
    const [data, setData] = useState([])
    const [favoritesList,setFavoritesList] = useState({})
    const isFetching = useRef(false)
    const reachFinalProduct = useRef(false)
    const [page, setPage] = useState(1)
    const [reset,setReset] = useState(false)
    const [noProducts, setNoProducts] = useState(false)
    const firstTime = useRef(true)

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
      }, []);
    
    
    useEffect(() => {
        const fetchProducts = async () => {
            if(!props.categoryId) return
            try{
                loadFavoritesList()
                const requestOptions = {
                    method: 'GET'
                };
                var productsUrl = new URL(`${url}/api/productsImport/ofuser/${props.categoryId+'/-1'}`),
                params = {page:page, limit:"10"}
                Object.keys(params).forEach(key => productsUrl.searchParams.append(key, params[key]))
                let result = await fetch(productsUrl,requestOptions)
                if(result.status === 200){
                    let newData = await result.json()
                    let foundSameProduct = -1
                    for(var i = 0; i < newData.length; i++) {
                        if(newData[i]._id === props.productId){
                            foundSameProduct = i
                            break;
                        }
                    }
                    if  (newData.length < 10){
                        if(page === 1){
                            if(newData.length === 0){
                                setNoProducts(true)
                            }
                        }
                        reachFinalProduct.current = true
                    }
                    if(foundSameProduct !== -1){
                        newData.splice(foundSameProduct, 1)
                    }
                    setLoading(false)
                    setData(data.concat(newData))
                    isFetching.current = false
                }
                else{
                    setApiError(true)
                    setLoading(false)
                    isFetching.current = false
                }
            } catch(error){
                console.log(error)
                setApiError(true)
                setLoading(false)
                isFetching.current = false
            }
        }
        fetchProducts()
    }, [page, reset])

    useEffect(() => {
        const fetchCategories = () => {
            if (firstTime.current){
                firstTime.current = false
            }
            else{
                setLoading(true)
                reachFinalProduct.current = false
                window.scrollTo(0,0)
                setPage(prevPage => 1)
                setReset(prevReset => !prevReset)
                setData(prevData => [])
            }
        }
        fetchCategories() 
    }, [props])

    const handleScroll = () => {
        const wrappedElement = document.getElementById('fecthMoreProducts');
        if (wrappedElement != null && !isFetching.current && !reachFinalProduct.current){

            if (wrappedElement.getBoundingClientRect().top <= window.innerHeight){
                isFetching.current = true
                setPage(prevPage => prevPage+1)
                
            }
        }
        return;
    };

    const loadFavoritesList = () => {
        const isUserLogged = window.localStorage.getItem('user')
        if(isUserLogged){
            const userId = JSON.parse(isUserLogged).id
            const data = window.localStorage.getItem(`${userId}`)
            if(data){
                const newData = JSON.parse(data)
                setFavoritesList(newData)
            }
        }
    }
    
    const breakpoints = {
        default: 5,
        1140: 4,
        900: 3,
        640: 2,
    }

    return (
        <div>
            {false?
                <div className={classes.errorConnection}>
                    <NoConnection />
                </div>
            :
            <React.Fragment>
            <div className={classes.mainContainer}>
                {loading? 
                    <Masonry
                    breakpointCols={breakpoints}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                    >
                        {skeleton.map(skeletonId => (
                            <div  className={classes.productElement} key={skeletonId}>
                                <SkeletonCard />
                            </div>
                        ))}
                    </Masonry>
                :
                    [noProducts?
                        <NoOtherProducts />
                    :
                    <Masonry
                    breakpointCols={breakpoints}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                    >
                        {data.map((product, index) => {
                            if((index === data.length - 10) && !reachFinalProduct.current){
                                return(
                                    <div className={classes.productElement}>
                                        <div id="fecthMoreProducts"></div>
                                        <div key={product._id}>
                                            <ProductCard
                                                product={product}
                                                isFavorite={favoritesList[`${product._id}`]}
                                                homePage={true}/>
                                        </div>
                                    </div>
                                )
                            }
                            else{
                                return(
                                    <div className={classes.productElement} key={product._id}>
                                        <ProductCard
                                            product={product}
                                            isFavorite={favoritesList[`${product._id}`]}
                                            homePage={true}/>
                                    </div>
                                )
                            }
                        })}
                        <div id="fecthMoreProducts"></div>
                        {!reachFinalProduct.current?
                            skeleton.map(skeletonId => (  
                                <div className={classes.productElement} key={skeletonId}>
                                    <SkeletonCard/>
                                </div>
                        ))
                        :
                        <span></span>
                        }
                    </Masonry>
                    ]
                }
            </div>
            </React.Fragment>
            }
            
        </div>
    )
    
})