const hasSizeAndColor = (category) => {
    if(category === "64cd3508786b6d7b68c1ec29"){
        return true
    }
    if(category === "64fe5320aa6a3a67893b2257"){
        return true
    }
    return false
}

const getSizes = (category) => {
    //ROPA
    if(category === "64cd3508786b6d7b68c1ec29"){
        return ["XS", "S", "M", "L", "XL", "XXL"]
    }
    //ZAPATOS
    if(category === "64fe5320aa6a3a67893b2257"){
        return ["35", "35.5", "36", "36.5", "37", 
                "37.5", "38", "38.5", "39", "39.5", 
                "40", "40.5", "41", "41.5", "42", 
                "42.5", "43", "43.5", "44", "44.5", 
                "45", "45.5", "46", "46.5"]
    }
    return []
}

export default hasSizeAndColor
export { getSizes }
