import React, { useLayoutEffect, useState } from 'react';
import { alpha, Button, makeStyles, Typography } from '@material-ui/core';
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { colors, supportNumber } from '../../../config';
import { Email, Facebook, Instagram, LiveHelp, LocalOffer, LocalShipping, Policy } from '@material-ui/icons';
import plane_boxes from '../../../assets/promotional_banner/plane_boxes.png'
import svi_van from '../../../assets/promotional_banner/svi_van.png'
import products from '../../../assets/promotional_banner/products.png'
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        width: '100%',
    },
    blueBannerBackground: {
        zIndex: '-1',
        position: 'absolute',
        height: '370px',
        width: '100%',
        marginBottom: '200px',
        borderBottomLeftRadius: '150px',
        background: `linear-gradient(180deg, ${colors.primary} 0%, ${colors.primary} 80%, ${colors.containerGradientFinal} 100%)`,
        [theme.breakpoints.down('sm')]: {
            height: '210px',
        },
        [theme.breakpoints.down('xs')]: {
            height: '150px',
            borderBottomLeftRadius: '100px',
        }
    },
    fastDeliveryBannerBackground: {
        zIndex: '-1',
        position: 'absolute',
        height: '370px',
        width: '100%',
        marginBottom: '200px',
        borderBottomLeftRadius: '150px',
        background: `linear-gradient(165deg, ${colors.primary} 0%, ${colors.primary} 60%, ${colors.fastDeliveryTag} 100%)`,
        [theme.breakpoints.down('sm')]: {
            height: '210px',
        },
        [theme.breakpoints.down('xs')]: {
            height: '150px',
            borderBottomLeftRadius: '100px',
        }
    },
    possibleDiscountBannerBackground: {
        zIndex: '-1',
        position: 'absolute',
        height: '370px',
        width: '100%',
        marginBottom: '200px',
        borderBottomLeftRadius: '150px',
        background: `linear-gradient(173deg, ${colors.topSellHomePage} 0%, ${colors.topSellHomePage} 2%, ${colors.whiteAppbar} 3%, ${colors.whiteAppbar} 10%,  ${colors.topSellHomePage} 11%, ${colors.topSellHomePage} 65%, ${colors.primary} 66%, ${colors.primary} 90%,  ${colors.topSellHomePage} 91%, ${colors.topSellHomePage} 100%)`,
        [theme.breakpoints.down('sm')]: {
            height: '210px',
        },
        [theme.breakpoints.down('xs')]: {
            height: '150px',
            borderBottomLeftRadius: '100px',
        }
    },
    bannerContainer: {
        display: 'flex',
        marginLeft: '10px',
        marginRight: '10px',
        height: '455px',
        [theme.breakpoints.down('sm')]: {
            height: '280px',
        },
        [theme.breakpoints.down('xs')]: {
            height: '185px'
        },
        [theme.breakpoints.up('lg')]: {
            width: '1140px',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    leftImage: {
        display: 'block',
        position: 'absolute',
        height: '410px',
        bottom: '0',
        width: 'auto !important',
        [theme.breakpoints.down('md')]: {
            height: '350px',
            bottom: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            height: '250px',
        },
        [theme.breakpoints.down('xs')]: {
            height: '150px',
            bottom: '5px'
        }
    },
    bannerLeftElements: {
        marginLeft: 'auto',
        marginRight: '10px',
        [theme.breakpoints.down('md')]: {
            // marginLeft: 'calc(5%)'
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: '40px',
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: '20px',
        }
    },
    mainTextHolder: {
        paddingTop: '50px',
        marginRight: '90px',
        [theme.breakpoints.down('md')]: {
            paddingTop: '25px',
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: '20px',
            marginRight: '20px',
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: '20px',
            marginRight: '0px',
        }
    },
    textMainTop: {
        letterSpacing: '2px',
        textAlign: 'right',
        fontFamily: '"Nerko One", cursive !important',
        color: colors.whiteAppbar,
        fontSize: '60px',
        lineHeight: '50px',
        fontWeight: '800',
        [theme.breakpoints.down('sm')]: {
            fontSize: '26px',
            lineHeight: '22px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '20px',
            lineHeight: '22px',
            letterSpacing: '0px',
        }
    },
    textMiddle: {
        letterSpacing: '2px',
        textAlign: 'right',
        color: colors.whiteAppbar,
        fontFamily: '"Nerko One", cursive !important',
        fontSize: '115px',
        lineHeight: '125px',
        fontWeight: '800',
        [theme.breakpoints.down('md')]: {
            fontSize: '95px',
            lineHeight: '135px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '55px',
            lineHeight: '95px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '35px',
            lineHeight: '45px',
        }
    },
    textSecondary: {
        textAlign: 'right',
        fontFamily: '"Nerko One", cursive !important',
        color: colors.whiteAppbar,
        fontSize: '36px',
        lineHeight: '36px',
        fontWeight: '500',
        [theme.breakpoints.down('md')]: {
            fontSize: '32px',
            lineHeight: '32px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
            lineHeight: '20px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '13px',
            lineHeight: '16px',
        }
    },
    followText: {
        fontSize: '28px',
        fontWeight: '700',
        color: colors.secondary,
        [theme.breakpoints.down('sm')]: {
            fontSize: '22px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '16px',
            marginLeft: 'auto'
        }
    },
    instagramIcon: {
        zIndex: '10',
        height: '35px',
        marginLeft: '4px',
        width: '35px',
        color: colors.secondary,
        '&:hover': {
            color: colors.instagram,
            cursor: 'pointer'
        },
        [theme.breakpoints.down('sm')]: {
            height: '30px',
            width: '30px',
        },
        [theme.breakpoints.down('xs')]: {
            height: '25px',
            width: '25px',
        }
    },
    facebookIcon: {
        zIndex: '10',
        marginLeft: '4px',
        height: '37px',
        width: '37px',
        color: colors.secondary,
        '&:hover': {
            color: colors.facebook,
            cursor: 'pointer'
        },
        [theme.breakpoints.down('sm')]: {
            height: '30px',
            width: '30px'
        },
        [theme.breakpoints.down('xs')]: {
            height: '25px',
            width: '25px',
            marginRight: 'auto',
        }
    },
    bannerOptions: {
        zIndex: '10',
        marginLeft: '30px',
        display: 'flex',
        alignItems: 'center',
        color: colors.secondary,
        '&:hover': {
            color: colors.primary,
            cursor: 'pointer'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '20px',
        }
    },
    bannerOptionsIcon: {
        marginRight: '4px',
        [theme.breakpoints.down('sm')]: {
            marginRight: '2px',
        },
        [theme.breakpoints.down('sm')]: {
            height: '20px',
            width: '20px'
        }
    },
    bannerOptionsText: {
        fontSize: '18px', 
        fontWeight: '700',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '15px',
        }
    },
    colaborationMarginTop: {
        height: '10px',
        [theme.breakpoints.down('md')]: {
            height: '10px',
        },
        [theme.breakpoints.down('xs')]: {
            height: '0px',
        }
    },
    //SECOND BANNER
    leftImageVan: {
        display: 'block',
        position: 'absolute',
        height: '410px',
        bottom: '20px',
        marginLeft: '-15px',
        width: 'auto !important',
        [theme.breakpoints.down('md')]: {
            height: '350px',
        },
        [theme.breakpoints.down('sm')]: {
            height: '250px',
            marginLeft: '0px',
        },
        [theme.breakpoints.down('xs')]: {
            height: '150px',
            bottom: '18px',
            marginLeft: '-5px',
        }
    },
    mainTextHolderFastDelivery: {
        paddingTop: '65px',
        [theme.breakpoints.down('md')]: {
            paddingTop: '25px',
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: '20px',
            paddingTop: '40px',
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: '25px',
            marginRight: '0px',
        }
    },
    textMainTopFastDelivery: {
        fontFamily: '"Nerko One", cursive !important',
        letterSpacing: '2px',
        textAlign: 'right',
        color: colors.whiteAppbar,
        fontSize: '100px',
        lineHeight: '84px',
        fontWeight: '800',
        [theme.breakpoints.down('md')]: {
            fontSize: '74px',
            lineHeight: '74px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '42px',
            lineHeight: '34px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '22px',
            lineHeight: '22px',
            letterSpacing: '0px',
        }
    },
    textMainTopFastDeliverySecondary: {
        letterSpacing: '2px',
        textAlign: 'right',
        fontFamily: '"Nerko One", cursive !important',
        color: colors.whiteAppbar,
        fontSize: '84px',
        lineHeight: '72px',
        fontWeight: '800',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'inherit',
            fontSize: '36px',
            lineHeight: '34px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '19.5px',
            lineHeight: '21px',
            letterSpacing: '0px',
        }
    },
    textMiddleFastDelivery: {
        letterSpacing: '2px',
        textAlign: 'left',
        paddingLeft: '5px',
        marginBottom: '-5px',
        color: colors.whiteAppbar,
        fontFamily: '"Nerko One", cursive !important',
        fontSize: '32px',
        lineHeight: '39px',
        fontWeight: '800',
        [theme.breakpoints.down('md')]: {
            fontSize: '32px',
            lineHeight: '65px',
            textAlign: 'right',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
            lineHeight: '20px',
            marginTop: '15px',
            marginBottom: '10px'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '13px',
            lineHeight: '13px',
            letterSpacing: '0px',
            marginTop: '0px',
            marginBottom: '0px',
            textAlign: 'center',
        }
    },
    textSecondaryFastDelivery: {
        textAlign: 'left',
        fontFamily: '"Nerko One", cursive !important',
        paddingLeft: '5px',
        color: colors.whiteAppbar,
        fontSize: '62px',
        lineHeight: '71px',
        fontWeight: '800',
        [theme.breakpoints.down('md')]: {
            textAlign: 'right',
            marginLeft: 'auto',
            fontSize: '58px',
            lineHeight: '74px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '26px',
            lineHeight: '36px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '19px',
            lineHeight: '21px',
        }
    },
    fastDeliveryIcon: {
        color: colors.whiteAppbar,
        height: '70px',
        width: '70px',
        marginLeft: '10px',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'inherit',
            height: '35px',
            width: '35px',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    },
    fastDeliveryIconMd: {
        color: colors.whiteAppbar,
        height: '100px',
        width: '100px',
        marginLeft: 'auto',
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'inherit'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'inherit',
            height: '35px',
            width: '35px',
            marginRight: '23px',
            marginTop: '-2px'
        },
    },

    //THIRD BANNER
    leftImageProducts: {
        display: 'block',
        position: 'absolute',
        height: '430px',
        bottom: '0px',
        marginLeft: '-15px',
        width: 'auto !important',
        [theme.breakpoints.down('md')]: {
            height: '350px',
        },
        [theme.breakpoints.down('sm')]: {
            height: '250px',
            marginLeft: '0px',
        },
        [theme.breakpoints.down('xs')]: {
            height: '160px',
            bottom: '9px',
            marginLeft: '-5px',
        }
    },
    mainTextHolderPossibleDiscount: {
        paddingTop: '45px',
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            paddingTop: '25px',
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: '45px',
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: '22px',
            marginRight: '0px',
            marginBottom: '12px'
        }
    },
    textMainTopPossibleDiscount: {
        letterSpacing: '2px',
        textAlign: 'right',
        fontFamily: '"Nerko One", cursive !important',
        color: colors.whiteAppbar,
        fontSize: '80px',
        lineHeight: '80px',
        fontWeight: '800',
        [theme.breakpoints.down('md')]: {
            fontSize: '70px',
            lineHeight: '70px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '38px',
            lineHeight: '30px',
            marginLeft: 'auto'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '28px',
            lineHeight: '22px',
            letterSpacing: '0px',
        }
    },
    textMiddlePossibleDiscount: {
        letterSpacing: '2px',
        textAlign: 'right',
        paddingLeft: '5px',
        marginBottom: '-5px',
        color: colors.whiteAppbar,
        fontFamily: '"Nerko One", cursive !important',
        fontSize: '38px',
        lineHeight: '44px',
        fontWeight: '600',
        [theme.breakpoints.down('md')]: {
            fontSize: '38px',
            lineHeight: '44px',
            textAlign: 'right',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '19px',
            lineHeight: '26px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '14px',
            lineHeight: '12px',
            letterSpacing: '0px',
            marginTop: '0px',
            marginBottom: '0px',
            textAlign: 'right',
        }
    },
    textSecondaryPossibleDiscount: {
        textAlign: 'right',
        fontFamily: '"Nerko One", cursive !important',
        paddingLeft: '5px',
        color: colors.whiteAppbar,
        fontSize: '60px',
        lineHeight: '60px',
        fontWeight: '800',
        [theme.breakpoints.down('md')]: {
            textAlign: 'right',
            fontSize: '54px',
            lineHeight: '54px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '27px',
            lineHeight: '30px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '18px',
            lineHeight: '21px',
        }
    },
    secondaryContainer: {
        marginTop: '28px',
        transform: 'rotate(352.9deg)',
        [theme.breakpoints.down('md')]: {
            marginTop: '52px',
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '15px',
            marginLeft: 'auto',
            width: '230px'
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '18px',
            width: '170px'
        }    
    },
    secondaryTextHolder: {
        marginLeft: '170px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '240px',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '50px',
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '50px',
        },
    },
    possibleDiscountIcon: {
        color: colors.whiteAppbar,
        height: '70px',
        width: '70px',
        marginLeft: '10px',
        marginTop: '5px',
        mozTransform: 'scale(-1, 1)',
        webkitTransform: 'scale(-1, 1)',
        oTransform: 'scale(-1, 1)',
        msTransform: 'scale(-1, 1)',
        transform: 'scale(-1, 1)',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'inherit',
            height: '35px',
            width: '35px',
            marginLeft: '5px',
        },
        [theme.breakpoints.down('xs')]: {
            height: '21px',
            width: '21px',
            marginTop: '0px',
            marginLeft: '2px',
        },
    },
    possibleDiscountIconMd: {
        color: colors.whiteAppbar,
        height: '80px',
        width: '80px',
        mozTransform: 'scale(-1, 1)',
        webkitTransform: 'scale(-1, 1)',
        oTransform: 'scale(-1, 1)',
        msTransform: 'scale(-1, 1)',
        transform: 'scale(-1, 1)',
        marginLeft: 'auto',
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'inherit'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },

    socialHideSmall: {
        display: 'flex', 
        paddingLeft: '10px',
        paddingRight: '10px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '5%',
            marginRight: '5%',
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column-reverse'
        }
    },
    socialHolder: {
        display: 'flex', 
        alignItems: 'center', 
        marginRight: 'auto',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 'auto',
            marginTop: '5px'
        }
    },
    bannerHolder: {
        display: 'flex', 
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '10px',
            transform: `translateX(-10px)`
        }
    },
    bannerContainerLayout: {
        // width :'100%',
        [theme.breakpoints.up('lg')]: {
            width: '1140px',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    }
}))

export default function PromotionalBanner(){
    const classes = useStyles()
    const [size, setSize] = useState(0)
    const history = useHistory()

    useLayoutEffect(() => {
        function updateSize() {
          setSize([window.innerWidth]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const onClickInsta = () => {
        window.open('https://instagram.com/sevendeimport?igshid=YmMyMTA2M2Y=')
    }

    const onClickFace = () => {
        window.open('https://www.facebook.com/profile.php?id=100088417074749&mibextid=LQQJ4d')
    }

    const onClickHelp = () => {
        window.open(`https://wa.me/${supportNumber}?text=${encodeURI("Hola, tengo una pregunta.")}`)
    }

    const toLegalInformationPage = (event) => {
        history.push("/legalinformation")
    }

    return (
        <div className={classes.mainContainer}>
            
            <div className={`banner-carousel`}>
                <Carousel 
                    autoPlay 
                    interval={5000} 
                    showStatus={false} 
                    showIndicators={true} 
                    showThumbs={false} 
                    showArrows={false}
                    useKeyboardArrows
                    // emulateTouch={true}
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={10}
                    renderIndicator={(onClickHandler, isSelected, index, label) => {
                        const defStyle = { marginLeft: '15px', backgroundColor: colors.lightGrayLogo, cursor: "pointer", width: size < 900? '10px' : '12px', height: size < 900? '10px' : '12px', borderRadius: '50%' };
                        const style = isSelected
                            ? { ...defStyle, backgroundColor: colors.primary }
                            : { ...defStyle };
                        return (
                            <div
                                style={style}
                                onClick={onClickHandler}
                                onKeyDown={onClickHandler}
                                value={index}
                                key={index}
                                role="button"
                                tabIndex={0}
                                aria-label={`${label} ${index + 1}`}
                            >
                            </div>
                        );
                    }}
                    infiniteLoop={true}
                >
                    <div>
                        <div className={classes.fastDeliveryBannerBackground} />
                        <div className={classes.bannerContainer}>
                            <img src={svi_van} className={classes.leftImageVan}/>
                            <div className={classes.bannerLeftElements}>
                                <div className={classes.mainTextHolderFastDelivery}>
                                    <Typography className={classes.textMainTopFastDelivery}>
                                    {size < 1140 && size >= 900? "ENTREGA INMEDIATA" : "ENTREGA"} 
                                    </Typography>
                                    <Typography className={classes.textMainTopFastDeliverySecondary}>
                                        INMEDIATA
                                    </Typography>
                                </div>
                                <div>
                                    <Typography className={classes.textMiddleFastDelivery}>
                                        {size < 640? "DE PRODUCTOS" : "DE PRODUCTOS CON"} 
                                    </Typography>
                                    {size < 640 &&
                                        <Typography className={classes.textMiddleFastDelivery}>
                                            CON LA 
                                        </Typography>
                                    }
                                </div>
                                <div style={{display: 'flex'}}>
                                    <Typography className={classes.textSecondaryFastDelivery}>
                                        {size < 640? "ETIQUETA" : "LA ETIQUETA"}
                                    </Typography>
                                    <LocalShipping className={classes.fastDeliveryIcon}/>
                                </div>
                                <div>
                                    <LocalShipping className={classes.fastDeliveryIconMd}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={classes.possibleDiscountBannerBackground} />
                        <div className={classes.bannerContainer}>
                            <img src={products} className={classes.leftImageProducts}/>
                            <div className={classes.bannerLeftElements}>
                                <div className={classes.mainTextHolderPossibleDiscount}>
                                    <Typography className={classes.textMainTopPossibleDiscount}>
                                        DESCUENTOS 
                                    </Typography>
                                    <LocalOffer className={classes.possibleDiscountIconMd}/>
                                </div>
                                <div style={{display: 'flex'}}>
                                    <div style={{marginLeft: 'auto'}}>
                                        <Typography className={classes.textMiddlePossibleDiscount}>
                                            TODOS LOS PRODUCTOS
                                        </Typography>
                                        <Typography className={classes.textMiddlePossibleDiscount}>
                                            SI UTILIZA CODIGO DE USUARIO
                                        </Typography>
                                    </div>
                                    {/* <LocalOffer className={classes.possibleDiscountIcon}/> */}
                                </div>
                                <div className={classes.secondaryContainer}>
                                    <div style={{display: 'flex'}}>
                                        <Typography className={classes.textSecondaryPossibleDiscount} style={{fontWeight: '500', marginRight: size < 640? '0px' : '7px', marginLeft: 'auto'}}>
                                            HASTA 
                                        </Typography>
                                        <Typography className={classes.textSecondaryPossibleDiscount}>
                                            5 %
                                        </Typography>
                                    </div>
                                    <div className={classes.secondaryTextHolder} style={{display: 'flex'}}>
                                        <Typography className={classes.textSecondaryPossibleDiscount} style={{fontWeight: '500', marginRight: size < 640? '0px' : '7px'}}>
                                            POR 
                                        </Typography>
                                        <Typography className={classes.textSecondaryPossibleDiscount}>
                                            PRODUCTO
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div>
                        <div className={classes.blueBannerBackground} />
                        <div className={classes.bannerContainer}>
                            <img src={plane_boxes} className={classes.leftImage}/>
                            <div className={classes.bannerLeftElements}>
                                <div className={classes.mainTextHolder}>
                                    <Typography className={classes.textMainTop}>
                                        LOS PEDIDOS TIENEN UN
                                    </Typography>
                                    <Typography className={classes.textMainTop}>
                                        IMPUESTO AEREO
                                    </Typography>
                                </div>
                                <div>
                                    <Typography className={classes.textMiddle}>
                                        AHORRA +
                                    </Typography>
                                </div>
                                <div>
                                    <Typography className={classes.textSecondary}>
                                        HAZ UNA COMPRA DE MAS DE <span style={{fontWeight: '800'}}>350 USD</span>
                                    </Typography>
                                    <Typography className={classes.textSecondary} style={{fontWeight: '800'}}>
                                        Y NO PAGUES IMPUESTO AEREO
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </Carousel>
            </div>
            <div className={classes.colaborationMarginTop}>

            </div>
            <div className={`${classes.bannerContainerLayout} ${classes.socialHideSmall}`}>
                <div className={classes.socialHolder}>
                    <Typography className={classes.followText}>SIGUENOS.</Typography>
                    <Instagram onClick={onClickInsta} className={classes.instagramIcon}/>
                    <Facebook onClick={onClickFace} className={classes.facebookIcon}/>
                </div>
                <div className={classes.bannerHolder}>
                    <div onClick={toLegalInformationPage} className={classes.bannerOptions}>
                        <Policy className={classes.bannerOptionsIcon}/>
                        <Typography className={classes.bannerOptionsText}>LEGAL</Typography>
                    </div>
                    <div onClick={onClickHelp} className={classes.bannerOptions}>
                        <LiveHelp className={classes.bannerOptionsIcon}/>
                        <Typography className={classes.bannerOptionsText}>AYUDA</Typography>
                    </div>
                    <div onClick={onClickHelp} className={classes.bannerOptions}>
                        <Email className={classes.bannerOptionsIcon}/>
                        <Typography className={classes.bannerOptionsText}>CONTACTAR</Typography>
                    </div>
                </div>
            </div>
            
        </div>
    )
}