import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../config'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Skeleton from '@material-ui/lab/Skeleton'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
    },
    media: {
        width: '100%',
        aspectRatio: '1',
    },
    title:{
        color: '#494949',
        fontSize: 14,
        fontWeight: 600,
        marginBottom: 3
    },
    price:{
        fontSize: 20,
        fontWeight: 700
    },
    group: {
        width: '100%',
        borderTop: `0.2px solid #E6E6E6`,
        marginLeft: '5px',
        justifyContent: 'end',
    },
    favorite: {
        color: colors.secondary,
        padding: '8px',
        '&:hover': {
            color: colors.redHeart
        },
        [theme.breakpoints.down('sm')]:{
            padding: '3px',
        }
     },
     favoriteOn: {
        color: colors.primary,
        padding: '8px',
        '&:hover': {
            color: colors.redHeart
        },
        [theme.breakpoints.down('sm')]:{
            padding: '3px',
        }
     },
     addCart: {
        color: colors.secondary,
        padding: '8px',
        '&:hover': {
            color: colors.greenCart
        },
        [theme.breakpoints.down('sm')]:{
            padding: '3px',
        }
     },
    editIcon: {
        color: colors.orangeEdit,
        padding: '8px',
        '&:hover': {
            color: colors.orangeEdit
        },
        [theme.breakpoints.down('sm')]:{
            padding: '3px',
        }
    },
    deleteIcon: {
        color: colors.redError,
        padding: '8px',
        '&:hover': {
            color: colors.redError
        },
        [theme.breakpoints.down('sm')]:{
            padding: '3px',
        }
    },
    share: {
        color: colors.secondary,
        padding: '8px',
        '&:hover': {
            color: colors.blueShare
        },
        [theme.breakpoints.down('sm')]:{
            padding: '3px',
            marginRight: '2px',
        }
    },
    productCard: {
        borderRadius: '7px'
    },
    box: {
        position: "relative",
        width: "100%",		/* desired width */
        '&:before': {
            content: "",
            float: "left",
            paddingTop: "100%"
          }
    },
    content: {
        float: "left"
    }
}))

export default function SkeletonCard({detailPage}) {
    const classes = useStyles()
    
    return (
        <Card elevation={3} className={classes.productCard}>
            <CardActionArea>
                {!detailPage &&
                <CardMedia>
                    <Skeleton variant="rect" width="100%" height="250px"/> 
                </CardMedia>
                }
                <CardContent>
                    {!detailPage &&
                        <Skeleton variant="text" width="90%" height="20px" style={{marginTop: '-15px'}}/>
                    }
                    {!detailPage &&
                        <Skeleton variant="text" width="90%" height="20px" style={{marginTop: '-3px'}}/>
                    }
                    {!detailPage &&
                        <Skeleton variant="text" width="70%" height="20px" style={{marginTop: '-3px'}}/>
                    }
                    <div style={{display: 'flex', marginTop: '3px', alignItems: 'center'}}>
                        <Skeleton variant="text" width="25%" height="20px" style={{marginTop: '0px'}}/>
                        <Skeleton variant="text" width="40%" height="25px" style={{marginLeft: detailPage? 'auto' : '10px'}}/>
                    </div>
                    <div style={{display: 'flex', marginTop: '3px', alignItems: 'center'}}>
                        <Skeleton variant="text" width="25%" height="20px" style={{marginTop: '0px'}}/>
                        <Skeleton variant="text" width="40%" height="25px" style={{marginLeft: detailPage? 'auto' : '10px'}}/>
                    </div>
                    <div style={{display: 'flex', marginTop: '3px', alignItems: 'center'}}>
                        <Skeleton variant="text" width="25%" height="20px" style={{marginTop: '0px'}}/>
                        <Skeleton variant="text" width="40%" height="25px" style={{marginLeft: detailPage? 'auto' : '10px'}}/>
                    </div>
                </CardContent>
            </CardActionArea>
            <CardActions style={{display: 'block'}} disableSpacing>
            
                <div style={{display: 'flex', alignItems: 'end'}}>
                    <Grid container alignItems='center' className={classes.group}>
                        <Grid item>
                            <IconButton
                                className={classes.favorite}
                                aria-label="add to favorites">
                                    <FavoriteBorderOutlinedIcon  />
                            </IconButton>
                        </Grid>
                        
                        <Grid item>
                            <IconButton className={classes.share} aria-label="share">
                                <ShareOutlinedIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <IconButton className={classes.addCart} aria-label="add Cart">
                        <AddShoppingCartIcon style={{height: '36px', width: '36px'}} />
                    </IconButton>
                </div>
            </CardActions>
        </Card>
    );
}