import React from 'react';
import { alpha, makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import no_favorites from '../../assets/img/no_favorites.svg';
import no_favorites_secondary from '../../assets/img/no_favorites_secondary.svg';
import { colors } from '../../config';


const useStyles = makeStyles((theme) => ({
    holder: {
        width: '100%',
        paddingTop: '60px',
        paddingBottom: '50px',
        backgroundColor: colors.whiteAppbar,
        [theme.breakpoints.down('xs')]: {
            marginBottom: '50px'
        }
    },
    image: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width:'auto',
        height: '160px',
    },
    mainText: {
        marginTop: '60px',
        textAlign: 'center',
        fontSize: '18px',
        fontWeight: '600'
    },
    secondaryText: {
        marginTop: '10px',
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: '400'
    },
}))

  export default function NoFavorites() {
    const classes  = useStyles()
    
    return(
        <div className={classes.holder}>
            <img src={no_favorites_secondary} className={classes.image}/>
            <Typography className={classes.mainText}>
                No tienes anuncios favoritos :(
            </Typography>
            <Typography className={classes.secondaryText}>
                Cuando selecciones como favorito alguno de nuestros anuncios en Sevende, aparecerá aquí.
            </Typography>
        </div>
    )
}