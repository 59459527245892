import React, { useEffect, useState } from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { Button, Menu, MenuItem, Typography } from '@material-ui/core';
import { getMonthString, isValidCalendarDate } from '../../tools/productTools';
import { calendarTimeOptions, colors } from '../../config';
import { getTimeString } from '../../tools/userTools';


const useStyles = makeStyles(() => ({
    menuButtonHolder: {
        width: '30px'
      },
    menuButton: {
        width:'100%',
        minWidth: '30px !important',
        padding: "4px 7px",
        marginBottom: '5px',
        marginTop:'3px',
        backgroundColor:'#ffffff', 
        borderRadius: '3px',
        justifyContent:'left',
        '&:hover': {
            backgroundColor: alpha('#ffffff', 1),
        }
    },
    labelMenuButton: {
        textTransform: 'capitalize',
        fontWeight: 600,
        fontSize: '13px'
    },
}))

  export default function PickCalendarProduct({yearsMonthsDaysAvailable, selectedDate, newDateSetter, formErrorText, formErrorTextSetter}) {
    const classes  = useStyles()

    const [anchorElYear, setAnchorElYear] = useState(null);
    const openElYear = Boolean(anchorElYear);

    const [anchorElMonth, setAnchorElMonth] = useState(null);
    const openElMonth = Boolean(anchorElMonth);

    const [anchorElDay, setAnchorElDay] = useState(null);
    const openElDay = Boolean(anchorElDay);

    const [anchorElHours, setAnchorElHours] = useState(null);
    const openElHours = Boolean(anchorElHours);

    const [anchorElMinutes, setAnchorElMinutes] = useState(null);
    const openElMinutes = Boolean(anchorElMinutes);

    const [anchorElAmpm, setAnchorElAmpm] = useState(null);
    const openElAmpm = Boolean(anchorElAmpm);

    const [daysAvailable, setDaysAvailable] = useState([])
    const [monthsAvailable, setMonthsAvailable] = useState([])

    const [dayOfWeek, setDayOfWeek] = useState("")

    useEffect(() => {
        if(selectedDate.year === -1 || selectedDate.month === -1) return
        yearsMonthsDaysAvailable.forEach(elementYear => {
            if(elementYear.year === selectedDate.year){
                setMonthsAvailable(elementYear.months)
                elementYear.months.forEach(elementMonth => {
                    if(elementMonth.month === selectedDate.month){
                        setDaysAvailable(elementMonth.days)
                        if(!elementMonth.days.includes(selectedDate.day) && selectedDate.day !== -1){
                            newDateSetter({
                                ...selectedDate,
                                day: elementMonth.days[0]
                            })
                        }
                        if(selectedDate.year !== -1 && selectedDate.month !== -1 && selectedDate.day !== -1){
                            const date = new Date(selectedDate.year, selectedDate.month-1, selectedDate.day, 0, 0)
                            switch (date.getDay()) {
                                case 0:
                                    setDayOfWeek("(DOMINGO)")
                                    break;
                                case 1:
                                    setDayOfWeek("(LUNES)")
                                    break;
                                case 2:
                                    setDayOfWeek("(MARTES)")
                                    break;
                                case 3:
                                    setDayOfWeek("(MIERCOLES)")
                                    break;
                                case 4:
                                    setDayOfWeek("(JUEVES)")
                                    break;
                                case 5:
                                    setDayOfWeek("(VIERNES)")
                                    break;
                                case 6:
                                    setDayOfWeek("(SABADO)")
                                    break;
                                default:
                                    setDayOfWeek("(CULO)");
                            }
                        }
                        else{
                            setDayOfWeek("")
                        }
                        return
                    }
                });
            }
        });
    }, [selectedDate.year, selectedDate.month, selectedDate.day])

    //#region //!!! MENU BUTTON CLICK
    const handleYearClick = (event) => {
        formErrorTextSetter("")
        setAnchorElYear(event.currentTarget)
    }
    const handleMonthClick = (event) => {
        formErrorTextSetter("")
        setAnchorElMonth(event.currentTarget)
    }
    const handleDayClick = (event) => {
        formErrorTextSetter("")
        setAnchorElDay(event.currentTarget)
    }
    const handleHoursClick = (event) => {
        formErrorTextSetter("")
        setAnchorElHours(event.currentTarget)
    }
    const handleMinutesClick = (event) => {
        formErrorTextSetter("")
        setAnchorElMinutes(event.currentTarget)
    }
    const handleAmpmClick = (event) => {
        formErrorTextSetter("")
        setAnchorElAmpm(event.currentTarget)
    }
    //#endregion MENU BUTTON CLICK

    //#region //!!! MENU CLOSE
    const handleYearClose = (year) => {
        setAnchorElYear(null)
        if(year === -1) return
        newDateSetter({
            ...selectedDate,
            year: year
        })
    }
    const handleMonthClose = (month) => {
        setAnchorElMonth(null)
        if(month === -1) return
        newDateSetter({
            ...selectedDate,
            month: month
        })
    }
    const handleDayClose = (day) => {
        setAnchorElDay(null)
        if(day === -1) return
        newDateSetter({
            ...selectedDate,
            day: day
        })
    }
    const handleHoursClose = (hours) => {
        setAnchorElHours(null)
        if(hours === -1) return
        newDateSetter({
            ...selectedDate,
            hours: hours
        })
    }
    const handleMinutesClose = (minutes) => {
        setAnchorElMinutes(null)
        if(minutes === -1) return
        newDateSetter({
            ...selectedDate,
            minutes: minutes
        })
    }
    const handleAmpmClose = (ampm) => {
        setAnchorElAmpm(null)
        if(ampm === -1) return
        newDateSetter({
            ...selectedDate,
            ampm: ampm
        })
    }
    //#endregion

    return(
        <div>
                        
            
            <div style={{marginTop: '5px', marginRight: '10px', flex: 1}}>
                <Typography style={{fontSize: '14px', fontWeight: '600', marginBottom: '1px'}}>Fecha/Hora Entrega {dayOfWeek}</Typography>
                <div style={{display: 'flex'}}>
                    <div className={classes.menuButtonHolder}>
                        <Button
                            variant="contained"
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={handleDayClick}
                            className={`${classes.menuButton}`}
                            classes={{
                                label: classes.labelMenuButton,
                            }}
                        >
                            {selectedDate.day !== -1? getTimeString(selectedDate.day) : "--"}
                        </Button>
                        <Menu
                            id="long-menu"
                            anchorEl={anchorElDay}
                            keepMounted
                            open={openElDay}
                            onClose={() => handleDayClose(-1)}
                            PaperProps={{
                                style: {
                                },
                            }}
                            >
                            {daysAvailable.map((day, index) => (
                                <MenuItem key={index} selected={day === selectedDate.day} onClick={() => handleDayClose(day)}>
                                    {getTimeString(day)}
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>
                    <Typography style={{fontSize: '18px', fontWeight: '600', marginLeft: '3px', marginRight: '3px', paddingTop: '7px'}}>
                        /
                    </Typography>
                    <div className={classes.menuButtonHolder} style={{width: '44px'}}>
                        <Button
                            variant="contained"
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={handleMonthClick}
                            className={`${classes.menuButton}`}
                            classes={{
                                label: classes.labelMenuButton,
                            }}
                        >
                            {selectedDate.month !== -1? getMonthString(selectedDate.month) : "---"}
                        </Button>
                        <Menu
                            id="long-menu"
                            anchorEl={anchorElMonth}
                            keepMounted
                            open={openElMonth}
                            onClose={() => handleMonthClose(-1)}
                            PaperProps={{
                                style: {
                                },
                            }}
                            >
                            {monthsAvailable.map((element, index) => (
                                <MenuItem key={index} selected={element.month === selectedDate.month} onClick={() => handleMonthClose(element.month)}>
                                    {getMonthString(element.month)}
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>
                    <Typography style={{fontSize: '18px', fontWeight: '600', marginLeft: '3px', marginRight: '3px', paddingTop: '7px'}}>
                        /
                    </Typography>
                    <div className={classes.menuButtonHolder} style={{width: '45px'}}>
                        <Button
                            variant="contained"
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={handleYearClick}
                            className={`${classes.menuButton}`}
                            classes={{
                                label: classes.labelMenuButton,
                            }}
                        >
                            {selectedDate.year !== -1? getTimeString(selectedDate.year) : "----"}
                        </Button>
                        <Menu
                            id="long-menu"
                            anchorEl={anchorElYear}
                            keepMounted
                            open={openElYear}
                            onClose={() => handleYearClose(-1)}
                            PaperProps={{
                                style: {
                                },
                            }}
                            >
                            {yearsMonthsDaysAvailable.map((element, index) => (
                                <MenuItem key={index} selected={element.year === selectedDate.year} onClick={() => handleYearClose(element.year)}>
                                    {getTimeString(element.year)}
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>
                    <div className={classes.menuButtonHolder} style={{marginLeft: '15px'}}>
                        <Button
                            variant="contained"
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={handleHoursClick}
                            className={`${classes.menuButton}`}
                            classes={{
                                label: classes.labelMenuButton,
                            }}
                        >
                            {selectedDate.hours !== -1? getTimeString(selectedDate.hours) : "--"}
                        </Button>
                        <Menu
                            id="long-menu"
                            anchorEl={anchorElHours}
                            keepMounted
                            open={openElHours}
                            onClose={() => handleHoursClose(-1)}
                            PaperProps={{
                                style: {
                                },
                            }}
                            >
                            {calendarTimeOptions.hours.map((hour, index) => (
                                <MenuItem key={index} selected={hour === selectedDate.hours} onClick={() => handleHoursClose(hour)}>
                                    {getTimeString(hour)}
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>
                    <Typography style={{fontSize: '20px', fontWeight: '600', marginLeft: '3px', marginRight: '3px', paddingTop: '2.5px'}}>
                        :
                    </Typography>
                    <div className={classes.menuButtonHolder}>
                        <Button
                            variant="contained"
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={handleMinutesClick}
                            className={`${classes.menuButton}`}
                            classes={{
                                label: classes.labelMenuButton,
                            }}
                        >
                            {selectedDate.minutes !== -1? getTimeString(selectedDate.minutes) : "--"}
                        </Button>
                        <Menu
                            id="long-menu"
                            anchorEl={anchorElMinutes}
                            keepMounted
                            open={openElMinutes}
                            onClose={() => handleMinutesClose(-1)}
                            PaperProps={{
                                style: {
                                },
                            }}
                            >
                            {calendarTimeOptions.minutes.map((minutes, index) => (
                                <MenuItem key={index} selected={minutes === selectedDate.minutes} onClick={() => handleMinutesClose(minutes)}>
                                    {getTimeString(minutes)}
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>
                    <div className={classes.menuButtonHolder}  style={{marginLeft: "5px", width: '35px'}}>
                        <Button
                            variant="contained"
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={handleAmpmClick}
                            className={`${classes.menuButton}`}
                            classes={{
                                label: classes.labelMenuButton,
                            }}
                        >
                            {selectedDate.ampm !== -1? getTimeString(selectedDate.ampm) : "--"}
                        </Button>
                        <Menu
                            id="long-menu"
                            anchorEl={anchorElAmpm}
                            keepMounted
                            open={openElAmpm}
                            onClose={() => handleAmpmClose(-1)}
                            PaperProps={{
                                style: {
                                },
                            }}
                            >
                            {calendarTimeOptions.ampm.map((ampm, index) => (
                                <MenuItem key={index} selected={ampm === selectedDate.ampm} onClick={() => handleAmpmClose(ampm)}>
                                    {getTimeString(ampm)}
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>          
                </div>
            </div>
        {formErrorText &&<Typography style={{color: colors.redError, fontSize: '14px'}}>{formErrorText}</Typography>}
        </div>
    )
}