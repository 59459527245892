import React, { useCallback, useLayoutEffect } from "react";
import { useState } from "react";
import { colors } from "../../../config";
import { makeStyles, } from "@material-ui/core";
import '../../../assets/css/imageContainer.css';
import { Skeleton } from "@material-ui/lab";
import NoConnection from "../../global/NoConnection";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import placeholder from '../../../assets/img/placeholder.png'
import { update_login_action } from "../../../redux/actions/loginAction";
import { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
      width: '100%',
    },
    imageHolder: {
      width: 'calc(100% - 80px)',
      '&:hover': {
        cursor: 'pointer'
      },
      minHeight: '270px'
    },
    selectedImageIndicator: {
      width: '50px',
      height: '50px',
      borderRadius: '3px',
      border: `solid 4px ${colors.primary}`,
      marginBottom: '5px',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    unselectedImageIndicator: {
      width: '50px',
      height: '50px',
      borderRadius: '3px',
      border: `solid 1px ${colors.secondary}`,
      marginBottom: '5px',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    lazyImage: {
      width: 'inherit',
      aspectRatio: '1',
      objectFit: 'cover',
      borderRadius: '3px',
    },
    main: {
        textAlign:'center',
        height: '320px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
    },
    image: {
        height: '320px',
        marginRight: '2px',
        borderRadius: '15px',
        display: 'inline-block',
        lineHeight: '100px',
        '&:hover': {
          cursor: 'pointer'
        },
        [theme.breakpoints.down('sm')]: {
          borderRadius: '15px'
        },
    },
    imageContainer: {
        verticalAlign: 'middle',
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflowX: 'auto',
        overflowY: 'hidden',
        borderRadius: '15px',
        width: '90%',
        marginLeft: '5%',
        marginRight: '5%',
        // [theme.breakpoints.up('lg')]: {
        //   width: '80%',
        //   marginLeft: '10%',
        //   marginRight: '10%',
        // },
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          borderRadius: '0px',
          marginLeft: '0px',
          marginRight: '0px',
        },
    },
    next: {
        textAlign: 'center',
        position: 'absolute',
        color: 'black',
        right: '0',
        verticalAlign: 'center',
        top: '70px',
        paddingLeft: '10px',
        zIndex: '100',
        height: '250px',
        lineHeight: '250px',
        borderRadius: '10px 0px 0px 10px',
        width: '60px',
        fontSize: '25px',
        cursor: 'pointer',
        transition: '0.6s ease',
        
        [theme.breakpoints.down('sm')]: {
          top: '100px',
          display: 'none'
        },
    },
    prev: {
      textAlign: 'center',
      position: 'absolute',
      left: '0',
      color: 'black',
      verticalAlign: 'center',
      top: '70px',
      paddingRight: '10px',
      zIndex: '100',
      height: '250px',
      lineHeight: '250px',
      borderRadius: '0px 10px 10px 0px',
      width: '60px',
      fontSize: '25px',
      cursor: 'pointer',
      transition: '0.6s ease',
      
      [theme.breakpoints.down('sm')]: {
        top: '100px',
        display: 'none'
      },
    },
    
}))

  

 const ProductImageContainer = ({imageUrls}) => {
    const classes  = useStyles();

    //Calification Dialog
    const [openLightbox, setOpenLightbox] = useState(false)
    const [lightboxIndex, setLightboxIndex] = useState(0)

    const pictureOnSelect = (key) => {
      setLightboxIndex(key)
      setOpenLightbox(true)
    };

    const [size, setSize] = useState(0)

    useLayoutEffect(() => {
        function updateSize() {
          setSize([window.innerWidth]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
      console.log(imageUrls)
    }, [imageUrls])
    
    return (
      <React.Fragment>
        {imageUrls.length === 0?
          <div>
            <div style={{display: 'flex'}}>
                <LazyLoadImage
                  className={classes.imageHolder}
                  style={{aspectRatio: '1', objectFit: 'cover', borderRadius: '3px'}}
                  alt={`Imagen Cargando`}
                  placeholderSrc={placeholder}
                  visibleByDefault={true}
                  effect="blur"
                  src={placeholder} />
                  <div style={{flex: 1}}>
                    <LazyLoadImage
                      style={{width: '60px', marginBottom: '5px', marginLeft: '10px', aspectRatio: '1', objectFit: 'cover', borderRadius: '3px'}}
                      alt={`Imagen Cargando`}
                      placeholderSrc={placeholder}
                      visibleByDefault={true}
                      effect="blur"
                      src={placeholder} />
                    <LazyLoadImage
                      style={{width: '60px', marginBottom: '5px', marginLeft: '10px', aspectRatio: '1', objectFit: 'cover', borderRadius: '3px'}}
                      alt={`Imagen Cargando`}
                      placeholderSrc={placeholder}
                      visibleByDefault={true}
                      effect="blur"
                      src={placeholder} />
                    <LazyLoadImage
                      style={{width: '60px', marginBottom: '5px', marginLeft: '10px', aspectRatio: '1', objectFit: 'cover', borderRadius: '3px'}}
                      alt={`Imagen Cargando`}
                      placeholderSrc={placeholder}
                      visibleByDefault={true}
                      effect="blur"
                      src={placeholder} />
                    <LazyLoadImage
                      style={{width: '60px', marginBottom: '0px', marginLeft: '10px', aspectRatio: '1', objectFit: 'cover', borderRadius: '3px'}}
                      alt={`Imagen Cargando`}
                      placeholderSrc={placeholder}
                      visibleByDefault={true}
                      effect="blur"
                      src={placeholder} />
                </div>
            </div>  
          </div>
        :
          <div>
            {openLightbox &&
              <Lightbox
                mainSrc={imageUrls[lightboxIndex].url}
                nextSrc={placeholder}
                prevSrc={placeholder}
                onCloseRequest={() => setOpenLightbox(false)}
                onMovePrevRequest={() =>
                  setLightboxIndex(
                    ((lightboxIndex + imageUrls.length - 1) % imageUrls.length),
                  )
                }
                onMoveNextRequest={() =>
                  setLightboxIndex(
                    (lightboxIndex + 1) % imageUrls.length,
                  )
                }
              />
            }
            <div className={classes.mainContainer}>
              <div className={`banner-product-detail`}>
                  <Carousel 
                      autoPlay={false} 
                      interval={5000} 
                      showStatus={false} 
                      showIndicators={true} 
                      showThumbs={false} 
                      showArrows={false}
                      useKeyboardArrows
                      preventMovementUntilSwipeScrollTolerance={true}
                      swipeScrollTolerance={10}
                      renderIndicator={(onClickHandler, isSelected, index, label) => {
                          return (
                              <div
                                  className={isSelected? classes.selectedImageIndicator : classes.unselectedImageIndicator}
                                  onClick={onClickHandler}
                                  onKeyDown={onClickHandler}
                                  value={index}
                                  key={index}
                                  role="button"
                                  tabIndex={0}
                                  aria-label={`${label} ${index + 1}`}
                              >
                                <LazyLoadImage
                                  className={classes.lazyImage}
                                  style={{borderRadius: '0px'}}
                                  alt={`Imagen ${index+1}`}
                                  placeholderSrc={placeholder}
                                  visibleByDefault={true}
                                  effect="blur"
                                  src={imageUrls[index].url} />
                              </div>
                          );
                      }}
                      infiniteLoop={true}
                  >
                    {imageUrls.map((element, index) => {
                        return(
                          <div className={classes.imageHolder}
                          onClick={() => {pictureOnSelect(index)}}>
                            <LazyLoadImage
                              className={classes.lazyImage}
                              alt={`Imagen ${index+1}`}
                              placeholderSrc={placeholder}
                              visibleByDefault={true}
                              effect="blur"
                              src={element.url} />
                          </div>
                        )
                      })
                    }
                  </Carousel>
              </div>
            </div>
          </div>
        }
      </React.Fragment>
    )
  }

export default ProductImageContainer