import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core';
import Masonry from 'react-masonry-css';
import { colors } from '../../../config';
import NoConnection from '../../global/NoConnection';
import NoFavorites from '../../global/NoFavorites';
import { useSelector } from 'react-redux';
import ProductFavoriteCard from '../../global/ProductFavoriteCard';
import TitleWithBottomArrow from '../../global/TitleWithBottomArrow';

const userStyles = makeStyles((theme) => ({
    mainContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: '10px',
        paddingRight: '10px',
        [theme.breakpoints.up('lg')]: {
            width: '1140px',
        },
        [theme.breakpoints.down('md')]: {
            width: '90%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '95%',
            marginBottom: '50px'
        },
    },
    productElement: {
        width: '208px',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('lg')]: {
            width: '90%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '90%',
        }
    },
    cardListContainer: {
        backgroundColor: colors.whiteAppbar,
        padding: '30px 10px 10px 10px',
    }
}))

export const hasReached = (el) => {
    return el.getBoundingClientRect().top <= window.innerHeight;
  }

export const FavoritesPageProductList = () => {
    const classes = userStyles()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [apiError, setApiError] = useState(false)
    //const isFetching = useRef(false)
    const [emptyFavorites, setEmptyFavorites] = useState(false)
    const userLogged = useSelector((state) => state.user_store.user)
    
    
    useEffect(() => {
        const fetchProducts = () => {
            try{
                
                if(userLogged){
                    const userId = userLogged._id
                    const dataLoaded = window.localStorage.getItem(`importFavorites`)
                    if(dataLoaded){
                        const newData = JSON.parse(dataLoaded)
                        //console.log(newData)
                        if(newData[`${userId}`]){
                            setData(newData[`${userId}`])
                        }
                        else{
                            setEmptyFavorites(true)
                        }
                        setLoading(false)
                    }
                    else{
                        setEmptyFavorites(true)
                        setLoading(false)
                    }
                }
                
            } catch(error){
                setApiError(true)
                setLoading(false)
                //isFetching.current = false
            }
        }
        fetchProducts()
    }, [userLogged])

    const updateFavorites = () => {
        try{
            
            setLoading(true)
            if(userLogged){
                const userId = userLogged._id
                const dataLoaded = window.localStorage.getItem(`importFavorites`)
                if(dataLoaded){
                    const newData = JSON.parse(dataLoaded)
                    //console.log(newData)
                    if(newData[`${userId}`]){
                        setData(newData[`${userId}`])
                    }
                    else{
                        setEmptyFavorites(true)
                    }
                    setLoading(false)
                }
                else{
                    setEmptyFavorites(true)
                    setLoading(false)
                }
            }
            
        } catch(error){
            setApiError(true)
            setLoading(false)
            //isFetching.current = false
        }
    } 
    const breakpoints = {
        default: 5,
        1140: 4,
        900: 3,
        640: 2,
    }
    
    return (
        <React.Fragment>
            {apiError?
                <NoConnection />
            :
            <div className={classes.mainContainer}>
            {emptyFavorites?
                <div>
                    <TitleWithBottomArrow title={"FAVORITOS"} />
                    <NoFavorites />
                </div>
            :
            <React.Fragment>
                <TitleWithBottomArrow title={"FAVORITOS"} />
                    <div className={classes.cardListContainer}>
                        <Masonry
                        breakpointCols={breakpoints}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                        >
                            {Object.keys(data).map((key, index) => {
                                return(
                                <div className={classes.productElement} key={data[key]._id}>
                                    <ProductFavoriteCard
                                        product={data[key]}
                                        isFavorite={true}
                                        fromFavorites={true}
                                        userId={-1}
                                        updateFromFavorites={updateFavorites}/>
                                </div>
                                )
                            })}
                        </Masonry>
                    </div>
            </React.Fragment>
            }
                
            </div>
            }
        </React.Fragment>
    )
    
}