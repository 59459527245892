import { alpha, Button, IconButton, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import b2_truck from '../../../assets/promotional_banner/b2_truck.png'

import "react-responsive-carousel/lib/styles/carousel.min.css";
import url, { colors, sendingCostValue, supportNumber } from "../../../config";
import LinesEllipsis from "react-lines-ellipsis";
import { useHistory } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import ProductCard from "../../global/ProductCard";
import SkeletonCard from "../../global/SkeletonCard";
import { Carousel } from "react-responsive-carousel";
import request_product_image from '../../../assets/img/request_product_image.png'

const useStyles = makeStyles((theme) => ({
    externalHolder: {
        background: `linear-gradient(to right, ${colors.primary} 60%, ${colors.primary} 100%)`,
        marginTop: '0px',
        paddingBottom: '0px',
        position: 'relative',
        display: 'flex',
    },
    externalBottomHolder: {
        height: '50px',
        backgroundColor: colors.decoraTuHogar,
        [theme.breakpoints.down('md')]: {
            height: '0px'
        },
    },
    topSellText: {
        fontSize: '40px',
        width: '250px',
        textAlign: 'center',
        lineHeight: '26px',
        fontFamily: '"Nerko One", cursive',
        color: colors.whiteAppbar,
        [theme.breakpoints.down('xs')]: {
            fontSize: '32px',
            lineHeight: '22px'
        }
    },
    lgContainer: {
        display: 'flex',
        paddingLeft: '40px',
        paddingRight: '40px',
        flex: 1,
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            flex: 0.5,
            paddingLeft: '40px',
            paddingRight: '40px',
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '20px',
            paddingRight: '20px',
        }
    },
    image: {
        position: 'absolute',
        height: '225px',
        left: '50%',
        top: '-10px',
        zIndex: '0',
        transform: 'translateX(-50%)',
        [theme.breakpoints.down('lg')]: {
            transform: 'translateX(-65%)',
            height: '235px',
        },
        [theme.breakpoints.down('sm')]: {
            transform: 'translateX(0)',
            right: '20px',
            left: 'auto',
            height: '325px',
        },
        [theme.breakpoints.down('xs')]: {
            transform: 'translateX(0)',
            right: '0px',
            left: 'auto',
            top: '70px',
            height: '150px',
        }
    },
    titleContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
            marginBottom: '18px',
            marginTop: '30px'
        }
    },
    middleFlexContainer: {
        width: '300px',
        [theme.breakpoints.down('lg')]: {
            width: '400px'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.down('xs')]: {
            zIndex: 1
        }
    },
    stepsContainer: {
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
        },
        [theme.breakpoints.down('xs')]: {
            zIndex: 1
        }
    },
    stepText: {
        fontSize: '18px',
        lineHeight: '20px',
        fontWeight: '700',
        color: colors.whiteAppbar,
        [theme.breakpoints.down('sm')]: {
            fontSize: '13px',
            lineHeight: '15px'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '12px',
            lineHeight: '14px'
        }
    }, 
    stepTextWidth: {
        width: '440px',
        [theme.breakpoints.down('lg')]: {
            width: '300px'
        },
        [theme.breakpoints.down('sm')]: {
            width: '200px',
        }
    },
    requestButton: {
        backgroundColor: colors.secondary,
        color: colors.whiteAppbar,
        borderRadius: '20px',
        paddingLeft: '25px',
        paddingRight: '25px',
        marginTop: '10px',
        marginBottom: '20px',
        '&:hover': {
            backgroundColor: alpha(colors.secondary, 0.9)
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '25px',
            marginTop: '15px',
            marginLeft: '40px'
        }
    }
}))

const RequestProductBanner = () => {
    const classes = useStyles()

    // const [size, setSize] = useState(0)

    // useLayoutEffect(() => {
    //     function updateSize() {
    //       setSize([window.innerWidth]);
    //     }
    //     window.addEventListener('resize', updateSize);
    //     updateSize();
    //     return () => window.removeEventListener('resize', updateSize);
    // }, []);

    const requestProduct = () => {
        const textMessage = `Hola, me gustaria solicitar un nuevo producto`
        const whatsapUrl = `https://wa.me/${supportNumber}?text=${encodeURI(textMessage)}`
        window.open(whatsapUrl)
    }

    return (
        <React.Fragment>
            <div className={classes.externalHolder}>
                <div className={classes.lgContainer}>
                    <div className={classes.titleContainer}>
                        <Typography className={classes.topSellText}>OFRECEMOS SERVICIOS DE PRODUCTOS POR ENCARGO</Typography>
                    </div>
                    <div className={classes.middleFlexContainer} />
                    <div className={classes.stepsContainer}>
                        <div style={{display: 'flex'}}>
                            <Typography className={classes.stepText}>1.</Typography>
                            <Typography className={`${classes.stepText} ${classes.stepTextWidth}`}>SOLICITE UN CHAT CON COMERCIAL</Typography>
                        </div>
                        <div style={{display: 'flex'}}>
                            <Typography className={classes.stepText}>2.</Typography>
                            <Typography className={`${classes.stepText} ${classes.stepTextWidth}`}>DESCRIBA EL PRODUCTO QUE DESEE COMPRAR</Typography>
                        </div>
                        <div style={{display: 'flex'}}>
                            <Typography className={classes.stepText}>3.</Typography>
                            <Typography className={`${classes.stepText} ${classes.stepTextWidth}`}>EN BREVE LE DAREMOS RESPUESTA A SU SOLICITUD</Typography>
                        </div>
                        <Button onClick={requestProduct} className={classes.requestButton} variant='contained'>
                            SOLICITAR
                        </Button>
                    </div>
                </div>
                <img src={request_product_image} className={classes.image}/>
            </div>
            <div className={classes.externalBottomHolder} />
        </React.Fragment>
    )
}

export default RequestProductBanner