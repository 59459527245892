import React from 'react';
import {  colors } from '../../../config'
import { makeStyles, Typography } from '@material-ui/core';
import der_logo from '../../../assets/colaborations/der_logo.svg'
import mikels_logo from '../../../assets/colaborations/mikels_logo.svg'
import fdiaz_logo from '../../../assets/colaborations/fdiaz_logo.png'
import heratbeat_logo from '../../../assets/colaborations/heartbeat_logo.svg'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useLayoutEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        marginBottom: '5px',
        marginLeft: '10px',
        marginRight: '10px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '5%',
            marginRight: '5%'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('lg')]: {
            width: '1140px',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    mainContainerSmall: {
        marginBottom: '5px',
        marginLeft: '5%',
        marginRight: '5%',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    logoContainer: {
        display: 'flex', 
        flex: 1, 
        alignItems: 'center', 
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            height: '110px',
            marginBottom: '30px',
            justifyContent: 'center',
        },
        [theme.breakpoints.down('xs')]: {
            height: '110px',
            marginBottom: '20px',
            justifyContent: 'center',
        }
    },
    brandMarginTop: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '5px',
        },
    },
    brandLogo: {
        height: '90px',
        width: 'auto !important',
        marginRight: '5px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 'auto',
        },
    },
    brandTextContainer: {
        [theme.breakpoints.down('sm')]: {
            marginRight: 'auto',
        },
    },
    brandName: {
        textAlign: 'left',
        color: colors.secondary,
        fontSize: '12px',
        fontWeight: '800',
    },
    brandSlogan: {
        textAlign: 'left',
        color: colors.secondary,
        fontSize: '12px',
        fontWeight: '600',
        marginTop: '-5px',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    },
}))

export default function ColaborationBanner(){
    const classes = useStyles()
    const history = useHistory();
    // const [size, setSize] = useState(0)

    // useLayoutEffect(() => {
    //     function updateSize() {
    //       setSize([window.innerWidth]);
    //     }
    //     window.addEventListener('resize', updateSize);
    //     updateSize();
    //     return () => window.removeEventListener('resize', updateSize);
    // }, []);

    const toDistributorPage = (event) => {
        return
        // history.push(`/distributor/64c7d977ce092f9781ef12da`)
        // window.scrollTo(0, 0);
    }

    return (
        <React.Fragment>
            <div className={`${classes.mainContainer} banner-top-sell`}>
                <Carousel
                    autoPlay={false} 
                    interval={5000} 
                    showStatus={false} 
                    showIndicators={false} 
                    showThumbs={false} 
                    showArrows={false}
                    useKeyboardArrows
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={10}
                    renderIndicator={(onClickHandler, isSelected, index, label) => {
                        const defStyle = { marginLeft: '15px', backgroundColor: colors.lightGrayLogo, cursor: "pointer", width: '12px', height: '12px', borderRadius: '50%' };
                        const style = isSelected
                            ? { ...defStyle, backgroundColor: colors.secondary }
                            : { ...defStyle };
                        return (
                            <div
                                style={style}
                                onClick={onClickHandler}
                                onKeyDown={onClickHandler}
                                value={index}
                                key={index}
                                role="button"
                                tabIndex={0}
                                aria-label={`${label} ${index + 1}`}
                            >
                            </div>
                        );
                    }}
                    infiniteLoop={false}
                >
                    <div>
                        <div className={classes.logoContainer}>
                            <div onClick={toDistributorPage} className={classes.brandMarginTop}  style={{display: 'flex', alignItems: 'center'}}>
                                <img className={classes.brandLogo} src={heratbeat_logo} />
                                <div className={classes.brandTextContainer}>
                                    <Typography className={classes.brandName}>HEARTBEAT BY KARLY</Typography>
                                    <Typography className={classes.brandSlogan}>TIENDA DE</Typography>
                                    <Typography className={classes.brandSlogan}>ROPA DEPORTIVA</Typography>
                                </div>
                            </div>
                            {/* <div style={{width: '1px', height: '80px', backgroundColor: colors.secondary, opacity: '0.7'}}/> */}
                            <div className={classes.brandMarginTop} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <img className={classes.brandLogo} style={{height: '50px'}} src={mikels_logo} />
                                <div className={classes.brandTextContainer}>
                                    <Typography className={classes.brandName}>MIKEL'S</Typography>
                                    <Typography className={classes.brandSlogan}>CUIDAR SU SEGURIDAD</Typography>
                                    <Typography className={classes.brandSlogan}>CON CALIDAD</Typography>
                                </div>
                            </div>
                            {/* <div className={classes} style={{width: '1px', height: '80px', backgroundColor: colors.secondary, opacity: '0.7'}}/> */}
                            <div className={classes.brandMarginTop} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <img className={classes.brandLogo} src={der_logo} />
                                <div className={classes.brandTextContainer}>
                                    <Typography className={classes.brandName}>DER LOGISTIC EXPRESS</Typography>
                                    <Typography className={classes.brandSlogan}>EMPRESA TRANSITARIA</Typography>
                                    <Typography className={classes.brandSlogan}>Y DE IMPORT/EXPORT</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </Carousel>
            </div>
            <div className={`${classes.mainContainerSmall} banner-top-sell`}>
                <Carousel
                    autoPlay={false} 
                    interval={5000} 
                    showStatus={false} 
                    showIndicators={true} 
                    showThumbs={false} 
                    showArrows={false}
                    useKeyboardArrows
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={10}
                    renderIndicator={(onClickHandler, isSelected, index, label) => {
                        const defStyle = { marginLeft: '15px', backgroundColor: colors.lightGrayLogo, width: '10px', height: '10px', borderRadius: '50%' };
                        const style = isSelected
                            ? { ...defStyle, backgroundColor: colors.secondary }
                            : { ...defStyle };
                        return (
                            <div
                                style={style}
                                onClick={onClickHandler}
                                onKeyDown={onClickHandler}
                                value={index}
                                key={index}
                                role="button"
                                tabIndex={0}
                                aria-label={`${label} ${index + 1}`}
                            >
                            </div>
                        );
                    }}
                    infiniteLoop={false}
                >
                    <div>
                        <div className={classes.logoContainer}>
                            <div onClick={toDistributorPage} className={classes.brandMarginTop}  style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                                <img className={classes.brandLogo} src={heratbeat_logo} />
                                <div className={classes.brandTextContainer}>
                                    <Typography className={classes.brandName}>HEARTBEAT BY KARLY</Typography>
                                    <Typography className={classes.brandSlogan}>TIENDA DE</Typography>
                                    <Typography className={classes.brandSlogan}>ROPA DEPORTIVA</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={classes.logoContainer}>
                            <div className={classes.brandMarginTop} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <img className={classes.brandLogo} style={{height: '50px'}} src={mikels_logo} />
                                <div className={classes.brandTextContainer}>
                                    <Typography className={classes.brandName}>MIKEL'S</Typography>
                                    <Typography className={classes.brandSlogan}>CUIDAR SU SEGURIDAD</Typography>
                                    <Typography className={classes.brandSlogan}>CON CALIDAD</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={classes.logoContainer}>
                            <div className={classes.brandMarginTop} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <img className={classes.brandLogo} src={der_logo} />
                                <div className={classes.brandTextContainer}>
                                    <Typography className={classes.brandName}>DER LOGISTIC EXPRESS</Typography>
                                    <Typography className={classes.brandSlogan}>EMPRESA TRANSITARIA</Typography>
                                    <Typography className={classes.brandSlogan}>Y DE IMPORT/EXPORT</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </Carousel>
            </div>
        </React.Fragment>
    )
}