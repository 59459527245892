import React from "react";
import { Skeleton } from "@material-ui/lab";
import { Collapse, makeStyles, Typography } from "@material-ui/core";
import { colors } from "../../../config";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    paddingRight: '20px',
    paddingLeft: '20px',
    [theme.breakpoints.down('md')]: {
      paddingRight: '10px',
      paddingLeft: '10px',
    },
    [theme.breakpoints.down('xs')]: {
      
    }
  },
  nameText: {
    fontSize: '22px',
    fontWeight: '800',
    lineHeight: '24px',
    marginBottom: '10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    }
  },
  descriptionText: {
    fontSize: '14px',
    lineHeight: '17px',
    opacity: '0.8',
    fontWeight: '500',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    }
  },
  nameSkeleton: {
    marginBottom: '10px',
    backgroundColor: colors.secondary,
    borderRadius: '3px'
  },
  descriptionSkeleton: {
    marginBottom: '10px',
    borderRadius: '3px'
  },
  collpaseText: {
    textAlign: 'right',
    padding: '5px 0px',
    marginRight: '10px',
    fontWeight: '500',
    cursor: 'pointer'
  }
}))

  

 const ProductNameAndDescriptionContainer = ({name, description}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)  

  return (
    <React.Fragment>
      {name?
        <div className={classes.mainContainer}>
          <div>
            <Typography className={classes.nameText}>
              {name.toUpperCase()}
            </Typography>
          </div>
          <div>
            <Collapse in={open} timeout="auto" collapsedSize={120}>
              <Typography className={classes.descriptionText}>
                {description.toUpperCase()}
              </Typography>
            </Collapse>
            {!open?
              <Typography
                onClick={() => {setOpen(true)}} 
                className={classes.collpaseText}>
                LEER MAS
              </Typography>
            :
              <Typography
                onClick={() => {setOpen(false)}} 
                className={classes.collpaseText}>
                LEER MENOS
              </Typography>
            }
          </div>
        </div>
      :
        <div className={classes.mainContainer}>
            <Skeleton className={classes.nameSkeleton}
            variant='rect' width='80%' height='30px'/>
            <Skeleton className={classes.descriptionSkeleton}
            variant='rect' width='100%' height='80px'/>
            <Skeleton className={classes.descriptionSkeleton}
            variant='rect' width='100%' height='80px'/>
            <Skeleton className={classes.descriptionSkeleton}
            variant='rect' width='100%' height='80px'/>
        </div>
      }
    </React.Fragment>
  )
}

export default ProductNameAndDescriptionContainer