import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors, supportNumber } from '../../config'
import footerLogo from '../../assets/img/logo_text_color.png'
import { Typography } from '@material-ui/core'
import { Email, Facebook, Instagram, LiveHelp, Policy } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
        height: '90px',
        backgroundColor: colors.whiteAppbar,
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            height: '130px',
            marginBottom: '50px'
        }
    },
    rootXs: {
        width: '100%',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
        height: '90px',
        backgroundColor: colors.whiteAppbar,
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            height: '130px',
            marginBottom: '50px'
        }
    },
    footerHolder: {
        maxWidth: '1140px',
        display: 'flex',
        height: '90px',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('lg')]: {
            width: '95%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%'
        },
        [theme.breakpoints.down('xs')]: {
            height: '70px'
        }
    },
    footerLogo: {
        height: '70px',
        width: 'auto',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    copyrightText: {
        position: 'absolute',
        fontSize: '10px',
        fontWeight: '600',
        color: colors.secondary,
        opacity: '0.5',
        bottom: '5px',
        width: '100%',
        textAlign: 'center'
    },
    instagramIcon: {
        zIndex: '10',
        height: '35px',
        marginLeft: '4px',
        width: '35px',
        color: colors.secondary,
        '&:hover': {
            color: colors.instagram,
            cursor: 'pointer'
        },
        [theme.breakpoints.down('sm')]: {
            height: '30px',
            width: '30px',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    facebookIcon: {
        zIndex: '10',
        marginLeft: '4px',
        height: '37px',
        width: '37px',
        color: colors.secondary,
        '&:hover': {
            color: colors.facebook,
            cursor: 'pointer'
        },
        [theme.breakpoints.down('sm')]: {
            height: '30px',
            width: '30px'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    bannerOptions: {
        zIndex: '10',
        marginLeft: '25px',
        marginRigth: '25px',
        display: 'flex',
        alignItems: 'center',
        color: colors.secondary,
        '&:hover': {
            color: colors.primary,
            cursor: 'pointer'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '20px',
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '7px',
            marginRight: '7px',
            paddingBottom: '24px'
        }
    },
    bannerOptionsIcon: {
        marginRight: '4px',
        [theme.breakpoints.down('sm')]: {
            marginRight: '2px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
        }
    },
    bannerOptionsText: {
        fontSize: '16px', 
        fontWeight: '600',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        }
    },
    footerHolderXs: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            height: '60px',
            alignItems: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            justifyContent: 'center'
        }
    },
    footerLogoXs: {
        height: '50px',
        width: 'auto',
        cursor: 'pointer'
    },
    instagramIconXs: {
        zIndex: '10',
        marginRight: '5px',
        color: colors.secondary,
        height: '30px',
        width: '30px',
        '&:hover': {
            color: colors.instagram,
            cursor: 'pointer'
        }
    },
    facebookIconXs: {
        zIndex: '10',
        marginLeft: '5px',
        color: colors.secondary,
        height: '30px',
        width: '30px',
        '&:hover': {
            color: colors.facebook,
            cursor: 'pointer'
        }
    },
}))

export default function Footer({hideXs}){
    const classes = useStyles()
    const history = useHistory()
    const date = new Date()

    const onClickHome = () => {
        history.push("/")
    }

    const onClickInsta = () => {
        window.open('https://instagram.com/sevendeimport?igshid=YmMyMTA2M2Y=')
    }

    const onClickFace = () => {
        window.open('https://www.facebook.com/profile.php?id=100088417074749&mibextid=LQQJ4d')
    }

    const onClickHelp = () => {
        window.open(`https://wa.me/${supportNumber}?text=${encodeURI("Hola, tengo una pregunta.")}`)
    }

    const toLegalInformationPage = (event) => {
        history.push("/legalinformation")
    }
    
    return (
        <div className={hideXs? classes.rootXs : classes.root}>
            <Typography className={classes.copyrightText}>COPYRIGHT © {date.getFullYear()} | SEVENDEIMPORT.COM | ALL RIGHTS RESERVED</Typography>
            <div className={classes.footerHolderXs}>
                <Instagram onClick={onClickInsta} className={classes.instagramIconXs}/>
                <img onClick={onClickHome} src={footerLogo} className={classes.footerLogoXs} />
                <Facebook onClick={onClickFace} className={classes.facebookIconXs}/>
            </div>
            <div className={classes.footerHolder}>
                <img onClick={onClickHome} src={footerLogo} className={classes.footerLogo} />
                <div onClick={toLegalInformationPage} style={{flex: 1, display: 'flex', justifyContent: 'center', marginRight: '15px'}}>
                    <div className={classes.bannerOptions}>
                        <Policy className={classes.bannerOptionsIcon}/>
                        <Typography className={classes.bannerOptionsText}>LEGAL</Typography>
                    </div>
                    <div onClick={onClickHelp} className={classes.bannerOptions}>
                        <LiveHelp className={classes.bannerOptionsIcon}/>
                        <Typography className={classes.bannerOptionsText}>AYUDA</Typography>
                    </div>
                    <div onClick={onClickHelp} className={classes.bannerOptions}>
                        <Email className={classes.bannerOptionsIcon}/>
                        <Typography className={classes.bannerOptionsText}>CONTACTAR</Typography>
                    </div>
                </div>
                <Instagram onClick={onClickInsta} className={classes.instagramIcon}/>
                <Facebook onClick={onClickFace} className={classes.facebookIcon}/>
            </div>
        </div>
    )
}