export const UPDATE_ORDERS = 'UPDATE_ORDERS'
export const UPDATE_SEARCH_ORDER_PARAMS = 'UPDATE_SEARCH_ORDER_PARAMS'

export const update_orders_action = (orders, page) => {
    return {
        type : UPDATE_ORDERS,
        payload: {
            orders: orders,
            page: page,
        }
    }
}

export const update_search_order_params_action = (payload) => {
    return {
        type : UPDATE_SEARCH_ORDER_PARAMS,
        payload: payload
    }
}