import { io } from "socket.io-client";
import url from "../../config";
import { v4 as uuidv4 } from 'uuid'
import { NO_UNREAD_MESSAGES, REMOVE_SOCKET, UNREAD_MESSAGES } from "../actions/socketAction";

const socket_connected = io(`${url}`)
socket_connected.on("connect", () =>{
    const isUserLogged = window.localStorage.getItem('user')
    const isSession = window.localStorage.getItem('session')
    let sessionId = null
    if(isSession){
        sessionId = JSON.parse(isSession)._id
    }
    else{
        const newSessionId = uuidv4()
        window.localStorage.setItem(
        'session', JSON.stringify({_id: newSessionId})
        )
        sessionId = newSessionId
    }
    if(isUserLogged && sessionId){
        const user = (JSON.parse(isUserLogged))
    
        // socket_connected.emit("addSocketToUser", {
        //     userId: user._id,
        //     deviceId: sessionId
        // })
        
    }
})

const default_socket = {
    socket: socket_connected,
    newMessages: false
}

function socket_store(state = default_socket, action){
    switch(action.type) {
        case REMOVE_SOCKET: {
            return{
                ...state,
                socket: action.payload
            }
        }
        case UNREAD_MESSAGES: {
            return{
                ...state,
                newMessages: action.payload
            }
        }
        case NO_UNREAD_MESSAGES: {
            return{
                ...state,
                newMessages: action.payload
            }
        }
        default: return state;
    }
}

export default socket_store