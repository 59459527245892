import reduceDecimalValue from "./productTools"

const getPaymentTypeName = (name) => {
    switch (name) {
        case "OnlineStripe":
            return "EN LINEA (STRIPE)"
        case "OnlineTransfer":
            return "EN LINEA (TRANSFERENCIA)"
        case "OnDelivery":
            return "CONTRA ENTREGA"
        case "OnRequest":
            return "PAGO ADELANTADO"
        case "SELECT ONE":
            return "SELECCIONE UNO"
        default:
            return "DESCONOCIDO"
    }
}

const getProductDiscountForCoupon = (product, discount) => {
    if(discount > 0){
        switch (product.platformEarningType) {
            case 'fixed':{
                let earning = product.fixedPlatformEarning
                console.log("= FIXED : ", earning)
                return reduceDecimalValue(earning/2)
            }
            default:{
                let originalPrice = (product.price*100)/(product.percent + 100)
                let realDiscount = product.percent >= discount? discount : product.percent
                let earning = originalPrice * realDiscount / 100
                return reduceDecimalValue(earning)
            }
        }
    }
    else{
        return reduceDecimalValue(0)
    }
}

const getDate = (currentDate) => {
    const date = new Date(currentDate)
    let hours = date.getHours()
    if(hours > 12) hours -= 12
    if(hours === 0) hours = 12
    if(hours < 10) hours = `0${hours}`
    let minutes = date.getMinutes()
    if(minutes < 10) minutes = `0${minutes}` 
    let ampm = "PM"
    if(date.getHours() < 12) ampm = "AM"
    return `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${hours}:${minutes} ${ampm}`
}

const getTotalOrder = (order) => {
    let total = 0
    if(order.totalProductsPrice) total += order.totalProductsPrice
    if(order.totalDeliveryCost) total += order.totalDeliveryCost
    if(order.airWaybill) total += order.airWaybill
    if(order.walletPayment) total -= order.walletPayment
    if(order.totalProductsCouponDiscount) total -= order.totalProductsCouponDiscount
    if(order?.totalProductsCancelled?.count > 0){
        total -= order.totalProductsCancelled.price
        total += order.totalProductsCancelled.wallet
        total += order.totalProductsCancelled.discount
        // total -= order.totalProductsCancelled.deliveryCost
        // total -= order.totalProductsCancelled.airWaybill
    }
    return reduceDecimalValue(total, 2) 
}


export { 
    getPaymentTypeName,
    getProductDiscountForCoupon,
    getDate,
    getTotalOrder
}