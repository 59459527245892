export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_WALLET = 'UPDATE_USER_WALLET'
export const REMOVE_USER = 'REMOVE_USER'

export const update_user_action = (user) => {
    return {
        type : UPDATE_USER,
        payload: user
    }
}

export const update_user_wallet_action = (wallet) => {
    return {
        type : UPDATE_USER_WALLET,
        payload: wallet
    }
}

export const remove_user_action = () => {
    return {
        type : REMOVE_USER,
        payload: null
    }
}