import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import no_access from '../../assets/img/01_forget_password.svg';
import no_access_secondary from '../../assets/img/01_forget_password_secondary.svg';


const useStyles = makeStyles(() => ({
    holder: {
        width: '100%',
        marginTop: '80px'
    },
    image: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width:'auto',
        height: '160px',
    },
    mainText: {
        marginTop: '60px',
        textAlign: 'center',
        fontSize: '18px',
        fontWeight: '600'
    },
    secondaryText: {
        marginTop: '10px',
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: '400'
    },
}))

  export default function NoAccess() {
    const classes  = useStyles()
    
    return(
        <div className={classes.holder}>
            <img src={no_access_secondary} className={classes.image}/>
            <Typography className={classes.mainText}>
                No tiene acceso a esta página :(
            </Typography>
            <Typography className={classes.secondaryText}>
                Inicie sesión para poder acceder.
            </Typography>
        </div>
    )
}