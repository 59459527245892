import { makeStyles, Typography } from '@material-ui/core'
import { ArrowBackIos, Assessment, Assignment, AssignmentInd, AssignmentTurnedIn, Favorite, Inbox, ListAlt, LocalShipping, LocationOn, ShoppingCart } from '@material-ui/icons'
import React from 'react'
import { colors } from '../../config'

const useStyles = makeStyles((theme) => ({
    holder: {
        display: 'flex',
        width: '100%',
        backgroundColor: colors.cartBlueBackground
    },
    holderCheckout: {
        display: 'flex',
        width: '100%',
        backgroundColor: colors.cartBlueBackground,
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        }
    },
    
    arrowLeftSquare: {
        flex: 1,
        backgroundImage:
            `linear-gradient(45deg, ${colors.whiteAppbar} 50%, ${colors.primary} 50.1%, ${colors.primary} 100%)`,
    },
    arrowRightSquare: {
        flex: 1,
        backgroundImage:
            `linear-gradient(315deg, ${colors.whiteAppbar} 50%, ${colors.primary} 50.1%, ${colors.primary} 100%)`,
    },
    title: {
        display: 'flex',
        position: 'relative',
        backgroundColor: colors.primary,
        color: colors.whiteAppbar,
        padding: '5px 30px',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    titleText: {
        fontSize: '20px',
        fontWeight: '600',
        marginRight: '8px',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 'auto'
        }
    },
    titleTextOrder: {
        
    },
    icon: {
        width: '0.5px',
        [theme.breakpoints.down('xs')]: {
            marginRight: 'auto'
        }
    },
    arrow: {
        display:'flex',
        position: 'absolute',
        width: '30px',
        height: '15px',
        marginTop: '54px',
        left: '50%',
        transform: 'translatex(-50%)',
    },
    svg: {
        width: "30px",
        height: "15px",
    },
    polygon: {
        fill: colors.primary,
      },
    backArrow: {
    height: '23px',
    width: '23px',
    minWidth: '23px',
    backgroundColor: colors.primary,
    borderRadius: '50%',
    border: `solid 2.5px ${colors.whiteAppbar}`,
    margin: '2px 5px 2px -10px',
    cursor: 'pointer',
    color: colors.whiteAppbar,
    [theme.breakpoints.down('xs')]: {
        marginLeft: '20px',
        marginRight: '20px'
    },
    '&:hover': {
        color: colors.primary,
        backgroundColor: colors.whiteAppbar
    }
    },
}))

const TitleWithBottomArrow = ({title, page, backButtonAction}) => {
    const classes = useStyles()

    return(
        <div className={title === "CHECKOUT"? classes.holderCheckout : classes.holder}>
            <div className={classes.title}>
                {backButtonAction&&
                    <div onClick={backButtonAction} className={classes.backArrow}>
                        <ArrowBackIos style={{marginLeft: '4px', height: '18px', width: '18px', marginTop: '0.5px', color: "inherit"}}/>
                    </div>
                }
                <Typography className={classes.titleText}>{title}</Typography>
                {title === "CARRITO" && <ShoppingCart />}
                {title === "CHECKOUT" && <AssignmentTurnedIn />}
                {title === "FAVORITOS" && <Favorite />}
                {title === "DIRECCION DE ENTREGA" && <LocationOn />}
                {page === "delivers" && <LocalShipping />}
                {page === "orders" && <Assignment />}
                {page === "adminUsers" && <AssignmentInd />}
                {page === "financesPage" && <Assessment />}
                {page === "adminProducts" && <Inbox />}
                {page === "myProducts" && <ListAlt />}
                <Favorite className={classes.icon} />
                <div className={classes.arrow}>
                    <svg className={classes.svg}>
                        <polygon points="30,0 15,15, 0,0" className={classes.polygon} />
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default TitleWithBottomArrow