import React, { useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import NotFoundPage from './components/pages/NotFoundPage';
import { createTheme, ThemeProvider} from '@material-ui/core';
import { colors } from './config'; 
import HomePage from './components/pages/HomePage'
import ProductDetailPage from './components/pages/ProductDetailPage' 
import OrdersPage from './components/pages/OrdersPage';
import CartPage from './components/pages/CartPage';

import { Provider } from 'react-redux'
import store from './redux/store';
import { subscribeUser } from './subscription' 

import LoadingComponent from './components/global/LoadingComponent';
import SetStartItems from './components/global/SetStartItems';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import SellDashboardPage from './components/pages/SellDashboardPage';
import DistributorPage from './components/pages/DistributorPage';

const UserDetailPage = React.lazy(() => import('./components/pages/UserDetailPage'));
const FavoritesPage = React.lazy(() => import('./components/pages/FavoritesPage'));
const CreateProductPage = React.lazy(() => import('./components/pages/CreateProductPage'));
const EditProductPage = React.lazy(() => import('./components/pages/EditProductPage'));
const ConversationsPage = React.lazy(() => import('./components/pages/ConversationsPage'));
const MyProductsPage = React.lazy(() => import('./components/pages/MyProductsPage'))
const AccountPage = React.lazy(() => import('./components/pages/AccountPage'))
const FinancePage = React.lazy(() => import('./components/pages/FinancePage'))
const AdminUsersPage = React.lazy(() => import('./components/pages/AdminUsersPage'))
const AdminProductsPage = React.lazy(() => import('./components/pages/AdminProductsPage'))
const AdminCategoriesPage = React.lazy(() => import('./components/pages/AdminCategoriesPage'))
const AdminDistributorsPage = React.lazy(() => import('./components/pages/AdminDistributorsPage'))
const DeliversPage = React.lazy(() => import('./components/pages/DeliversPage'))
const PrivacyPolicyPage = React.lazy(() => import('./components/pages/PrivacyPolicyPage'))
const LegalInformationPage = React.lazy(() => import('./components/pages/LegalInformationPage'))
const HelpPage = React.lazy(() => import('./components/pages/HelpPage'))

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary
    },
    secondary: {
      main: colors.secondary
    },
  },
  typography: {
    fontFamily: 'Montserrat',
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 800
  },
  breakpoints: createBreakpoints({
    values: {
      xs: 0,
      sm: 640,
      md: 900,
      lg: 1140,
      xl: 1200,
    },
  }),
})

function App() {
  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    }
    else if (Notification.permission === "granted") {
      // If it's okay let's create a notification
      subscribeUser()
    }
    else if (Notification.permission !== 'denied' || Notification.permission === "default") {
      Notification.requestPermission(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          console.log("Aceptadas las notificaciones")
          subscribeUser()
        }
      });
    }
  },[]);
  
  return (
    <Provider store={store}>
      <SetStartItems />
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route path="/" exact>
                <HomePage />
            </Route>
            <Route path="/selldashboard">
                <SellDashboardPage />
            </Route>
            <Route path="/distributor/:id">
                <DistributorPage />
            </Route>
            <Route 
              path="/products/detail/:id"
              render={({ match }) => {
                  return(
                    // <Suspense fallback={<LoadingComponent page={'Cargando Página de Detalles...'}/>}>
                      <ProductDetailPage key={match.params.id || 'empty'} />
                    // </Suspense>
                  )
                }
              }
            />
            <Route path="/users/:id"
              render={({ match }) => {
                  return(
                    <Suspense fallback={<LoadingComponent page={'Cargando Página de Usuario...'}/>}>
                      <UserDetailPage key={match.params.id || 'empty'} />
                    </Suspense>
                  )
                }
              }
            />
            <Route path="/cart">
                <CartPage />
            </Route>
            {/* <Route path="/pdftest">
                <OrderPdf />
            </Route> */}
            <Route path="/account/">
              <Suspense fallback={<LoadingComponent page={'Cargando Página de Perfil...'}/>}>
                <AccountPage />
              </Suspense>
            </Route>
            <Route path="/favorites/">
              <Suspense fallback={<LoadingComponent page={'Cargando Página de Favoritos...'}/>}>
                <FavoritesPage />
              </Suspense>
            </Route>
            <Route path="/myproducts/create">
              <Suspense fallback={<LoadingComponent page={'Cargando Página de Crear Anuncio...'}/>}>
                <CreateProductPage />
              </Suspense>
            </Route>
            <Route path="/myproducts/edit/:id"
              render={({ match }) => {
                return(
                  <Suspense fallback={<LoadingComponent page={'Cargando Página de Editar Anuncio...'}/>}>
                    <EditProductPage key={match.params.id || 'empty'} />
                  </Suspense>
                )
              }
            }
            />
            <Route path="/myproducts/">
              <Suspense fallback={<LoadingComponent page={'Cargando Página de Mis Productos...'}/>}>
                <MyProductsPage />
              </Suspense>
            </Route>
            <Route path="/orders/:id?/:orderId?"
              render={({ match }) => {
                  return(
                      <OrdersPage key={`${match.params.id}${match.params.orderId}` || 'empty'} />
                  )
                }
              }
            />
            <Route path="/adminUsers/">
              <Suspense fallback={<LoadingComponent page={'Cargando Página de Administración de Usuarios...'}/>}>
                <AdminUsersPage />
              </Suspense>
            </Route>
            <Route path="/adminProducts/">
              <Suspense fallback={<LoadingComponent page={'Cargando Página de Administración de Productos...'}/>}>
                <AdminProductsPage />
              </Suspense>
            </Route>
            <Route path="/delivers/:id?/:trackingOrderId?"
              render={({ match }) => {
                return(
                    <Suspense fallback={<LoadingComponent page={'Cargando Página de Entregas...'}/>}>
                      <DeliversPage key={`${match.params.id}${match.params.trackingOrderId}` || 'empty'} />
                    </Suspense>
                )
              }
            }
            />
            <Route path="/adminCategories/">
              <Suspense fallback={<LoadingComponent page={'Cargando Página de Administración de Categorías...'}/>}>
                <AdminCategoriesPage />
              </Suspense>
            </Route>
            <Route path="/adminDistributors/">
              <Suspense fallback={<LoadingComponent page={'Cargando Página de Administración de Distribuidores...'}/>}>
                <AdminDistributorsPage />
              </Suspense>
            </Route>
            <Route path="/finances/">
              <Suspense fallback={<LoadingComponent page={'Cargando Página de Administración de Finanzas...'}/>}>
                <FinancePage />
              </Suspense>
            </Route>
            <Route path="/conversations/">
              <Suspense fallback={<LoadingComponent page={'Cargando Página de Conversaciones...'}/>}>
                <ConversationsPage />
              </Suspense>
            </Route>
            <Route path="/privacypolicy/">
              <Suspense fallback={<LoadingComponent page={'Cargando Página de Política de Privacidad...'}/>}>
                <PrivacyPolicyPage />
              </Suspense>
            </Route>
            <Route path="/legalinformation/">
              <Suspense fallback={<LoadingComponent page={'Cargando Página de Informacion Legal...'}/>}>
                <LegalInformationPage />
              </Suspense>
            </Route>
            <Route path="/help/">
              <Suspense fallback={<LoadingComponent page={'Cargando Página de Informacion Legal...'}/>}>
                <HelpPage />
              </Suspense>
            </Route>
            <Route path="/:id" >
                <HomePage registerPromotion={true}/>
            </Route>
            <Route>
              <NotFoundPage />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </Provider>
   );
}

export default App;
