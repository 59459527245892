import React, { useRef } from 'react'
import { createTheme } from '@material-ui/core/styles';
import { alpha, Button, FormControl, IconButton, makeStyles, MenuItem, Modal, MuiThemeProvider, Select, Tooltip, Typography } from '@material-ui/core'
import { colors, provinces } from '../../config'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useState } from 'react'
import WarningAmberIcon from '@material-ui/icons/PriorityHigh'
import { update_products_action, update_search_params_action } from '../../redux/actions/productsAction';
import { Cancel } from '@material-ui/icons';

const themeMUI = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          color: "white",
          backgroundColor: "rgba(251,82,100,0.85)",
          borderTop: 'solid 2px rgb(251,82,100)'
        }
      }
    }
  });

const useStyles = makeStyles((theme) => ({
    cartTypeText: {
        flex: 1,
        fontSize: '18px',
        fontWeight: '700',
        paddingTop: '5px',
        //textAlign: 'center',
        color: colors.secondary
    },
    confirmButton: {
        height: '35px',
        color: colors.primary,
        border: `solid 1.5px ${colors.primary}`,
        marginTop: '10px',
        marginBottom: '2px',
        backgroundColor: 'white',
        '&:hover':{
            backgroundColor: alpha(colors.primary, 0.2)
        }
    },
    inputHolder: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        marginTop:'5px',
        marginBottom: '10px',
        backgroundColor:'#ffffff', 
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);', 
        borderRadius: '3px',
        '&:hover': {
          boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
        },
        '&:focus': {
          boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
        }
    },
    inputHolderFlex: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    modalPaper: {
        position: 'absolute',
        top: `50%`,
        left: `50%`,
        width: '400px',
        maxHeight: '90%',
        maxWidth: '95%',
        overflowY: 'scroll',
        transform: `translate(-50%, -50%)`,
        borderRadius: '3px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    errorButton: {
        color: colors.redError,
        padding: '3px',
        marginTop:'5px',
        "&:hover, &.Mui-focusVisible": { 
            backgroundColor: colors.redErrorOpacity,
        }
    },
    arrow: {
        fontSize: 20,
        color: "#4A4A4A",
        "&::before": {
            backgroundColor: colors.redError,
        }
    },
    dataSelect: {
        paddingLeft: '10px',
    },
    formControl: {
        flex: 1
    },
    cancelButton: {
        display: 'block',
        marginLeft: 'auto',
        padding: '6px'
    },
}))

const SelectRegionModal = ({open, onClose}) => {
    const classes = useStyles()

    const [adrRegionSelected, setAdrRegionSelected] = useState("La Habana")
    const [adrRegionSelectedErrorOpen, setAdrRegionSelectedErrorOpen] = useState(false)
    const [adrRegionSelectedError, setAdrRegionSelectedError] = useState("")

    const searchParamsToExecute = useSelector((state) => state.products_store.searchParameters)
    const searchParamsToExecuteUseRef = useRef()
    searchParamsToExecuteUseRef.current = searchParamsToExecute
    const user = useSelector((state) => state.user_store.user)
    const userRef = useRef()
    userRef.current = user
    const dispatch = useDispatch()

    useEffect(() => {
        if(user){
            userRef.current = user
        }
    }, [user])

    

    const handleTooltipErrors = (tooltipSelected) => {
        switch (tooltipSelected) {
          case 'adrRegion':{
            if(adrRegionSelectedError) handleAdrRegionErrorTooltipOpen()
            break;
          }
          default:{
            break;
          }
        }
    }

    const handleChangeAdrRegion = (event) => {
        handleTooltipErrors("adrRegion")
        setAdrRegionSelected(event.target.value);
    };

    const handleAdrRegionErrorTooltipClose = () => {
        setAdrRegionSelectedErrorOpen(false);
    };
    const handleAdrRegionErrorTooltipOpen = () => {
        setAdrRegionSelectedErrorOpen(true);
    };

    const onClickConfirm = async () => {
        let params = {...searchParamsToExecuteUseRef.current}
        window.localStorage.setItem(
            'region', JSON.stringify(adrRegionSelected)
        )
        dispatch(update_products_action([], 0, false))
        params.page = 1
        params.onBackPressed = false
        params.selectedProvince = adrRegionSelected
        dispatch(update_search_params_action(params))
        onClose()
    }
    

    return(
        <React.Fragment>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="change region"
                aria-describedby="data necessary to change region"
                >
                <div className={classes.modalPaper}>
                    <div style={{display: 'flex', alignItems: 'flex-start', marginBottom: '15px'}}>
                        <Typography className={classes.cartTypeText}>SELECCIONE UNA PROVINCIA</Typography>
                        <IconButton size="large" onClick={onClose} aria-label="warning" className={classes.cancelButton}>
                            <Cancel />
                        </IconButton>
                    </div>
                     
                    
                    <React.Fragment>
                        <div className={classes.inputHolderFlex}>
                            <div style={{flex: 1}} className={classes.inputHolder}>
                                <FormControl className={classes.formControl}>
                                    <Select
                                    value={adrRegionSelected}
                                    onChange={handleChangeAdrRegion}
                                    disableUnderline
                                    className={classes.dataSelect}
                                    inputProps={{ 'aria-label': 'region' }}
                                    >
                                        {provinces.map((provinceName, index) => { 
                                                if(index > 0){
                                                    return(
                                                        <MenuItem value={provinceName}>{provinceName}</MenuItem>
                                                    )
                                                } 
                                            }
                                        )}
                                    </Select>
                                </FormControl>
                                {adrRegionSelectedError &&
                                <div>
                                    <MuiThemeProvider theme={themeMUI}>
                                        <Tooltip classes={{arrow: classes.arrow}}
                                            arrow
                                            PopperProps={{
                                            disablePortal: true,
                                            }}
                                            onClose={handleAdrRegionErrorTooltipClose}
                                            open={adrRegionSelectedErrorOpen}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            title={adrRegionSelectedError}
                                        >
                                            <IconButton onClick={handleAdrRegionErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                                <WarningAmberIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </MuiThemeProvider>
                                </div>                       
                                }
                            </div>
                        </div>
                        
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button onClick={onClickConfirm} className={classes.confirmButton} variant='contained'>
                                ACEPTAR
                            </Button>
                        </div>
                    </React.Fragment>
                </div>
            </Modal>
        </React.Fragment>
    )
}


export default SelectRegionModal