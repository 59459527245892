import React from "react"
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import url from "../../config"
import { update_cart_action } from "../../redux/actions/cartAction"
import { update_user_action } from "../../redux/actions/userAction"
import getUserFields from "../../tools/userTools"
import { update_products_action, update_search_params_action } from "../../redux/actions/productsAction"

export default function SetStartItems(){
    const dispatch = useDispatch()
    const searchParamsToExecute = useSelector((state) => state.products_store.searchParameters)

    useEffect(() => {
        const fetchUser = async () => {
            try{
                const regionItem = window.localStorage.getItem('region')
                if(regionItem){
                    const region = JSON.parse(regionItem)
                    if(region && region !== searchParamsToExecute.selectedProvince){
                        let params = {...searchParamsToExecute}
                        dispatch(update_products_action([], 0, false))
                        params.page = 1
                        params.onBackPressed = false
                        params.selectedProvince = region
                        dispatch(update_search_params_action(params))
                    }
                }
                const isUserLogged = window.localStorage.getItem('user')
                if(!isUserLogged) return
                const token = JSON.parse(isUserLogged).accessToken
                const requestOptions = {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `${token}` 
                    }
                }    
                let result = await fetch(`${url}/api/accountImport/getUserWithToken`, requestOptions)
                if(result.status === 200){
                    const data = await result.json()
                    const userToUpdate = getUserFields(data.user, data.token)
                    window.localStorage.setItem(
                        'user', JSON.stringify({idToken: data.user._id, accessToken: data.token})
                    )
                    dispatch(update_user_action(userToUpdate))
                    calculate_cart_values(data.user.cart)
                }
            } catch(error){
                console.log(error);
            }
        }
        fetchUser()
    }, [])

    const calculate_cart_values = (cart) => {
        const local_store_cart = window.localStorage.getItem('cart')
        if(local_store_cart){
            window.localStorage.removeItem('cart')
        }
        let initCount = 0
        let initProducts = []
        let initPoints = 0
        if(cart?.length > 0){
            cart.forEach(element => {
                if(element.product){
                    initCount += element.count
                    initPoints += element.product.fastDelivery? 0 : (element.product.points * element.count)
                }
            });
            initProducts = cart
        }
        dispatch(update_cart_action(initCount, initProducts, initPoints))
    }

    return null
}