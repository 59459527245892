import React, {useState} from 'react';
import { alpha, makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { InputBase, Button, Typography, Snackbar, Select, MenuItem } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import url, { colors, supportEmail, supportNumber } from '../../config';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import WarningAmberIcon from '@material-ui/icons/PriorityHigh'
import VisibilityOnIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { CircularProgress } from '@material-ui/core';

import { Stepper, StepLabel, Step } from '@material-ui/core';
import forget_password from '../../assets/img/01_forget_password.svg'
import forget_password_secondary from '../../assets/img/01_forget_password_secondary.svg'
import verificate_password from '../../assets/img/02_verificate_password.svg'
import verificate_password_secondary from '../../assets/img/02_verificate_password_secondary.svg'
import recovery_password from '../../assets/img/03_recovery_password.svg'
import recovery_password_secondary from '../../assets/img/03_recovery_password_secondary.svg'
import { Alert } from '@material-ui/lab';
import { subscribeUser } from '../../subscription';
import { useDispatch, useSelector } from 'react-redux';
import { provinces, phoneAreas } from '../../config';
import { update_user_action } from '../../redux/actions/userAction';
import { update_login_action } from '../../redux/actions/loginAction';


const themeMUI = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          color: "white",
          backgroundColor: "rgba(251,82,100,0.85)",
          borderTop: 'solid 2px rgb(251,82,100)'
        }
      }
    }
  });

const useStyles = makeStyles((theme) => ({
    primaryButton: {
        display: 'block',
        width: '80%',
        margin: '10px auto 10px auto',
        backgroundColor: colors.primary,
        borderColor: colors.primary,
        borderRadius: '3px',
        border: 'solid 1.5px',
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: alpha(colors.primary, 0.85),
        },
    },
    secondaryButton: {
        display: 'block',
        width: '80%',
        margin: '10px auto 10px auto',
        borderColor: colors.primary,
        borderRadius: '3px',
        border: 'solid 2px',
        color: colors.primary,
        '&:hover': {
            backgroundColor: alpha(colors.primary, 0.15),
        },
    },
    nextButton: {
        display: 'block',
        backgroundColor: colors.primary,
        borderColor: colors.primary,
        borderRadius: '3px',
        border: 'solid 1.5px',
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: alpha(colors.primary, 0.85),
        },
    },
    circularProgressButton: {
        display: 'block',
        height: '40px',
        backgroundColor: colors.primary,
        borderColor: colors.primary,
        borderRadius: '3px',
        border: 'solid 1.5px',
        width: '107px',
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: alpha(colors.primary, 0.85),
        },
    },
    circularProgressButtonLastStep: {
        display: 'block',
        height: '40px',
        backgroundColor: colors.primary,
        borderColor: colors.primary,
        borderRadius: '3px',
        border: 'solid 1.5px',
        width: '120px',
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: alpha(colors.primary, 0.85),
        },
    },
    backButton: {
        display: 'block',
        borderRadius: '3px',
        color: 'rgba(0,0,0,0.65)',
    },
    
    inputHolder: {
        display: 'flex',
        width: '80%',
        marginLeft: '10%',
        marginBottom: '15px',
        backgroundColor:'#ffffff', 
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);', 
        borderRadius: '3px',
        '&:hover': {
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
          },
          '&:focus': {
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
        },
        [theme.breakpoints.down('xs')]:{
            width: '100%',
            marginLeft: '0px',
        }
    },
    inputRootLogin: {
        width: '100%',
        paddingRight: '20px'
    },
    inputInputLogin: {
        height: 22,
        fontSize: '1rem',
        fontWeight: 400,
        // vertical padding + font size from searchIcon
        transition: theme.transitions.create('width'),
        width: '100%',
        paddingLeft: '10px',
        paddingRight: '10px',
        '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: 'rgba(0, 0, 0, 0.54)',
            opacity: 1 /* Firefox */
          },
          
          '&:-ms-input-placeholder': { /* Internet Explorer 10-11 */
            color: 'rgba(0, 0, 0, 0.54)'
          },
          
          '&::-ms-input-placeholder': { /* Microsoft Edge */
            color: 'rgba(0, 0, 0, 0.54)'
          },
    },
    inputInputLoginPhone: {
        height: 22,
        fontSize: '1rem',
        fontWeight: 400,
        // vertical padding + font size from searchIcon
        transition: theme.transitions.create('width'),
        width: '100%',
        paddingRight: '10px',
        '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: 'rgba(0, 0, 0, 0.54)',
            opacity: 1 /* Firefox */
          },
          
          '&:-ms-input-placeholder': { /* Internet Explorer 10-11 */
            color: 'rgba(0, 0, 0, 0.54)'
          },
          
          '&::-ms-input-placeholder': { /* Microsoft Edge */
            color: 'rgba(0, 0, 0, 0.54)'
          },
    },
    inputVerificateHolder: {
        display: 'flex',
        width: '40px',
        height: '50px',
        marginLeft: '5px',
        marginRight: '5px',
        backgroundColor:'#ffffff', 
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);', 
        borderRadius: '3px',
        '&:hover': {
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
          },
          '&:focus': {
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
          }
    },
    inputRootVerificate: {
        width: '100%',
    },
    inputInputVerificate: {
        height: 22,
        fontSize: '16px',
        fontWeight: '600',
        // vertical padding + font size from searchIcon
        transition: theme.transitions.create('width'),
        width: '100%',
        paddingLeft: '15px',
        '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: 'rgba(0, 0, 0, 0.54)',
            opacity: 1 /* Firefox */
          },
          
          '&:-ms-input-placeholder': { /* Internet Explorer 10-11 */
            color: 'rgba(0, 0, 0, 0.54)'
          },
          
          '&::-ms-input-placeholder': { /* Microsoft Edge */
            color: 'rgba(0, 0, 0, 0.54)'
          },
    },
    errorButton: {
        color: colors.redError,
        padding: '8px',
        "&:hover, &.Mui-focusVisible": { 
            backgroundColor: colors.redErrorOpacity,
        }
    },
    showButton: {
        padding: '8px',
    },
    arrow: {
        fontSize: 20,
        color: "#4A4A4A",
        "&::before": {
          backgroundColor: colors.redError,
        }
    },
    stepperImage: {
        display: 'block',
        height: '150px',
        width: 'auto',
        margin: '10px auto 20px auto'
    },
    stepperMainText: {
        color: 'rgba(0,0,0,0.85)',
        textAlign: 'center',
        marginBottom: '5px',
        fontWeight: '600',
        fontSize: '18px'
    },
    stepperSecondaryText: {
        color: 'rgba(0,0,0,0.0.5)',
        textAlign: 'center',
        marginBottom: '25px',
        fontWeight: '400',
        fontSize: '16px',
    },
    stepperSecondaryTextRegister: {
        color: 'rgba(0,0,0,0.0.5)',
        textAlign: 'center',
        marginBottom: '10px',
        fontWeight: '400',
        fontSize: '16px',
        width:'80%',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    stepperTerciaryText: {
        color: 'rgba(0,0,0,0.0.5)',
        textAlign: 'center',
        marginBottom: '20px',
        fontWeight: '400',
        fontSize: '14px',
        marginTop: '10px'
    },
    stepperErrorText: {

    },
    verificateErrorText: {
        color: colors.redError,
        textAlign: 'center',
        fontSize: '14px',
        marginTop: '10px'
    },
    codeResendedText: {
        color: 'orange',
        textAlign: 'center',
        fontSize: '14px',
        marginTop: '10px'
    },
    userDataSelect: {
        color: 'rgb(74, 74, 74)',
        marginTop: '4px',
        marginBottom: '4px',
        paddingLeft: '10px',
        marginRight: '5px',
        width: '100%',
        fontSize: '17px',
        fontWeight: '400',
    },
    userDataSelectPhone: {
        color: 'rgb(74, 74, 74)',
        marginTop: '4px',
        marginBottom: '4px',
        paddingLeft: '10px',
        width: '75px',
        fontSize: '17px',
        fontWeight: '400',
    },
}))

// #region STEPPER
const getSteps = () => {
    return ['', '', ''];
}  

// #endregion

// #region TAB
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        style={{boxShadow:'0px 0px 0px 0px'}}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ boxShadow: 0 }} p={2}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  // #endregion

  export default function LoginRegister({setOpenSuccesSnack, setOpenApiErrorSnack, setOpenRegisterSuccesSnack, setOpenRegisterApiErrorSnack, registerPromotion}) {
    const classes  = useStyles()
    const descriptionElementRef = React.useRef(null);
    const [registerLoading, setRegisterLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [apiError, setApiError] = useState(false)
    const [loginUserName, setLoginUserName] = useState("")
    const [login, setLogin] = useState(true)

    const [loginPassword, setLoginPassword] = useState("")
    const [loginPasswordShow, setLoginPasswordShow] = useState(false)

    const [registerUserName, setRegisterUserName] = useState("")
    const [registerFirstName, setRegisterFirstName] = useState('')
    const [registerLastName, setRegisterLastName] = useState('')
    const [registerPhone, setRegisterPhone] = useState('')
    const [registerPassword, setRegisterPassword] = useState("")
    const [registerPasswordShow, setRegisterPasswordShow] = useState(false)
    const [registerEmail, setRegisterEmail] = useState("")

    const [registerConfirm, setRegisterConfirm] = useState("")
    const [registerConfirmShow, setRegisterConfirmShow] = useState(false)
    //Error Tooltip Field
    const [loginUserNameErrorOpen, setLoginUserNameErrorOpen] = useState(false)
    const [loginUserNameError, setLoginUserNameError] = useState("")
    const [loginPasswordErrorOpen, setLoginPasswordErrorOpen] = useState(false)
    const [loginPasswordError, setLoginPasswordError] = useState("")

    const [registerUserNameErrorOpen, setRegisterUserNameErrorOpen] = useState(false)
    const [registerUserNameError, setRegisterUserNameError] = useState("")
    const [registerFirstNameErrorOpen, setRegisterFirstNameErrorOpen] = useState(false)
    const [registerFirstNameError, setRegisterFirstNameError] = useState("")
    const [registerLastNameErrorOpen, setRegisterLastNameErrorOpen] = useState(false)
    const [registerLastNameError, setRegisterLastNameError] = useState("")
    const [registerPhoneErrorOpen, setRegisterPhoneErrorOpen] = useState(false)
    const [registerPhoneError, setRegisterPhoneError] = useState("")
    const [registerEmailErrorOpen, setRegisterEmailErrorOpen] = useState(false)
    const [registerEmailError, setRegisterEmailError] = useState("")
    const [registerPasswordErrorOpen, setRegisterPasswordErrorOpen] = useState(false)
    const [registerPasswordError, setRegisterPasswordError] = useState("")
    const [registerConfirmErrorOpen, setRegisterConfirmErrorOpen] = useState(false)
    const [registerConfirmError, setRegisterConfirmError] = useState("")
    const [regionSelected, setRegionSelected] = useState("Seleccione Provincia")
    const [phoneRegion, setPhoneRegion] = useState("+53")

    //STEPPER
    const [recoveringPassword, setRecoveringPassword] = useState(false)
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const [recoverEmailErrorOpen, setRecoverEmailErrorOpen] = useState(false)
    const [recoverEmailError, setRecoverEmailError] = useState("")
    const [recoverEmail, setRecoverEmail] = useState("")
    const [verificateError, setVerificateError] = useState("")
    const [verificate1, setVerificate1] = useState("")
    const [verificate2, setVerificate2] = useState("")
    const [verificate3, setVerificate3] = useState("")
    const [verificate4, setVerificate4] = useState("")
    const [emailConfirming, setEmailConfirming] = useState(false)
    const [recoveryPassword, setRecoveryPassword] = useState("")
    const [recoveryPasswordShow, setRecoveryPasswordShow] = useState(false)
    const [recoveryConfirm, setRecoveryConfirm] = useState("")
    const [recoveryConfirmShow, setRecoveryConfirmShow] = useState(false)
    const [recoveryPasswordErrorOpen, setRecoveryPasswordErrorOpen] = useState(false)
    const [recoveryPasswordError, setRecoveryPasswordError] = useState("")
    const [recoveryConfirmErrorOpen, setRecoveryConfirmErrorOpen] = useState(false)
    const [recoveryConfirmError, setRecoveryConfirmError] = useState("")
    const [sendingCode, setSendingCode] = useState(false)
    const [reSendingCode, setResendingCode] = useState(false)
    const [codeResended, setCodeResended] = useState(false)
    const [code, setCode] = useState("")
    const [openChangePasswordSuccesSnack, setOpenChangePasswordSuccesSnack] = useState(false)
    const [changePasswordError, setChangePasswordError] = useState("")
    const [sendEmailError, setSendEmailError] = useState("")

    const dispatch = useDispatch()
    const socket = useSelector((state) => state.socket_store.socket)
    const openLogin = useSelector((state) => state.login_store)
    const {id} = useParams();

    React.useEffect(() => {
        if (openLogin) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
          }
        }
    }, [openLogin]);

    React.useEffect(() => {
        if(registerPromotion) {
            setLogin(false)
        }
    }, []);

    const handleChangeRegion = (event) => {
        setRegionSelected(event.target.value);
    };

    const handleChangePhoneRegion = (event) => {
        setPhoneRegion(event.target.value);
    };

    const ResendCode = async (event) => {
        if(sendingCode)return;
        setCodeResended(false)
        setResendingCode(true)
        try{
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            };
            const controller = 'accountImport'
            let result = await fetch(`${url}/api/${controller}/recoverPassword/${recoverEmail}`, requestOptions)
            if(result.status === 200){
                let data = await result.json()
                setVerificateError("")
                setCodeResended(true)
                setCode(data.code)
                setResendingCode(false)
            }
            else{
                setVerificateError("No se pudo reenviar codigo. Verifique su conexión a internet.")
                handleRecoverEmailErrorTooltipOpen()
                setResendingCode(false)
                return;
            }
        }
        catch(e){
            console.log(e)
            setResendingCode(false)
            setVerificateError("No se pudo reenviar codigo. Verifique su conexión a internet.")
            handleRecoverEmailErrorTooltipOpen()
            return;
        }
    }

    const ResendRegisterCode = async (event) => {
        if(sendingCode)return;
        setCodeResended(false)
        setResendingCode(true)
        try{
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            };
            const controller = 'accountImport'
            let codeResult = await fetch(`${url}/api/${controller}/confirmRegister/${registerEmail}/${registerEmail}`, requestOptions)
            if(codeResult.status === 200){
                let registerData = await codeResult.json()
                setRegisterLoading(false)
                setCode(registerData.code)
                console.log(registerData.code)
                setRegisterLoading(false)
                setEmailConfirming(true)
                setVerificateError("")
                setCodeResended(true)
                setResendingCode(false)
                return;
            }
            else{
                if(codeResult.status === 400){
                    let registerData = await codeResult.json()
                    if(registerData.errorType === 0)
                    {
                        setRegisterUserNameError("Nombre de usuario en uso.")
                        handleRegisterUserNameErrorTooltipOpen()
                        setResendingCode(false)
                        setRegisterLoading(false)
                        return;
                    }
                    if(registerData.errorType === 1)
                    {
                        setRegisterEmailError("Correo en uso.")
                        handleRegisterEmailErrorTooltipOpen()
                        setResendingCode(false)
                        setRegisterLoading(false)
                        return;
                    }
                    if(registerData.errorType === 2)
                    {
                        setRegisterEmailError("Correo incorrecto, por favor verifique.")
                        handleRegisterEmailErrorTooltipOpen()
                        setResendingCode(false)
                        setRegisterLoading(false)
                        return;
                    }
                    setOpenRegisterApiErrorSnack()
                    setResendingCode(false)
                    setRegisterLoading(false)
                }
                else{
                    setOpenRegisterApiErrorSnack()
                    setResendingCode(false)
                    setRegisterLoading(false)
                }
            }
        }
        catch(e){
            console.log(e)
            setResendingCode(false)
            setVerificateError("No se pudo reenviar codigo. Verifique su conexión a internet.")
            return;
        }
    }
    // #region STEPPER HANDLE
    const handleNext = async () => {
        if(activeStep === 0){
            if(!recoverEmail){
                setRecoverEmailError("Debe poner un correo.")
                handleRecoverEmailErrorTooltipOpen()
                return;
            }
            else{
                if(recoverEmail.includes("@") === false){
                    setRecoverEmailError("Debe poner un correo electrónico válido.")
                    handleRecoverEmailErrorTooltipOpen()
                    return;
                }
            }
            try
            {
                setSendingCode(true)
                const requestOptions = {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                };
                const controller = 'accountImport'
                let result = await fetch(`${url}/api/${controller}/recoverPassword/${recoverEmail}`, requestOptions)
                if(result.status === 200){
                    let data = await result.json()
                    setCode(data.code)
                    console.log(data.code)
                    setSendingCode(false)
                }
                else{
                    let data = await result.json()
                    console.log(data.errorType)
                    if(data.errorType === 0){
                        setRecoverEmailError("Este correo no pertenece a ningún usuario registrado.")
                        handleRecoverEmailErrorTooltipOpen()
                    }
                    else{
                        if(data.errorType === 1){
                            setRecoverEmailError("No se pudo enviar el codigo a este correo, verifique que su correo sea correcto.")
                            handleRecoverEmailErrorTooltipOpen()
                        }
                        else{
                            setSendEmailError("Verifique su conexión a internet.")
                            handleRecoverEmailErrorTooltipOpen()
                        }
                    }
                    setSendingCode(false)
                    return;
                }
            }
            catch(e){
                console.log(e)
                setSendingCode(false)
                setSendEmailError("Verifique su conexión a internet.")
                return;
            }
        }
        if(activeStep === 1){
            if(!verificate1){
                setVerificateError("Debe poner el código.")
                return;
            }
            if(!verificate2){
                setVerificateError("Debe poner el código.")
                return;
            }
            if(!verificate3){
                setVerificateError("Debe poner el código.")
                return;
            }
            if(!verificate4){
                setVerificateError("Debe poner el código.")
                return;
            }
            try
            {
                setSendingCode(true)
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                        code : code,
                        emailCode : `${verificate1}${verificate2}${verificate3}${verificate4}` 
                    })
                };
                const controller = 'accountImport'
                let result = await fetch(`${url}/api/${controller}/recoverPassword/`, requestOptions)
                if(result.status === 200){
                    setSendingCode(false)
                }
                else{
                    let data = await result.json()
                    if(data.errorType === 0){
                        setVerificateError("Su código no coincide con el enviado a su correo.")
                    }
                    else{
                        setVerificateError("Verifique su conexión a internet.")
                    }
                    handleRecoverEmailErrorTooltipOpen()
                    setSendingCode(false)
                    return;
                }
            }
            catch(e){
                console.log(e)
                setSendingCode(false)
                setRecoverEmailError("Verifique su conexión a internet.")
                handleRecoverEmailErrorTooltipOpen()
                return;
            }
        }
        if(activeStep === 2){
            if(!recoveryPassword){
                setRecoveryPasswordError("Debe poner una contraseña.")
                handleRecoveryPasswordErrorTooltipOpen()
                return;
            }
            if(!recoveryConfirm){
                setRecoveryConfirmError("Debe poner una confirmación.")
                handleRecoveryConfirmErrorTooltipOpen()
                return;
            }
            if(recoveryPassword !== recoveryConfirm){
                setRecoveryConfirmError("La contraseña y la confirmación no coinciden.")
                handleRecoveryConfirmErrorTooltipOpen()
                return;
            }
            try
            {
                setSendingCode(true)
                const requestOptions = {
                    method: 'PATCH',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                        password: recoveryPassword,
                        email: recoverEmail 
                    })
                };
                const controller = 'accountImport'
                let result = await fetch(`${url}/api/${controller}/recoverPassword/`, requestOptions)
                if(result.status === 200){
                    setSendingCode(false)
                    setOpenChangePasswordSuccesSnack(true)
                    setRecoveringPassword(false)
                    return
                }
                else{
                    setChangePasswordError("Verifique su conexión a internet.")
                    setSendingCode(false)
                    return;
                }
            }
            catch(e){
                console.log(e)
                setSendingCode(false)
                setChangePasswordError("Verifique su conexión a internet.")
                return;
            }
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleCloseChangePasswordSuccesSnack = () => {
        setOpenChangePasswordSuccesSnack(false)
    }

    const handleBack = () => {
        if(activeStep === 0)
        {
            setRecoveringPassword(false)
            setVerificate1("")
            setVerificate2("")
            setVerificate3("")
            setVerificate4("")
            setResendingCode(false)
            setCodeResended(false)
            setSendingCode(false)
            setCode("")
        
        }
        else{
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };
    // #endregion


    const handleLoginClose = () => {
        dispatch(update_login_action(false))
    };

    

    // #region Handle Change Events
    const handleChangeLoginUserName = (event) => {
        if(loginUserNameError){
            setLoginUserNameError("")
            handleLoginUserNameErrorTooltipClose()
        }
        setLoginUserName(event.target.value);
    };
    const handleChangeLoginPassword = (event) => {
        if(loginPasswordError){
            setLoginPasswordError("")
            handleLoginPasswordErrorTooltipClose()
        }
        setLoginPassword(event.target.value);
    };
    const handleChangeRegisterUserName = (event) => {
        if(registerUserNameError){
            setRegisterUserNameError("")
            handleRegisterUserNameErrorTooltipClose()
        }
        setRegisterUserName(event.target.value);
    };
    const handleChangeRegisterFirstName = (event) => {
        if(registerFirstNameError){
            setRegisterFirstNameError("")
            handleRegisterFirstNameErrorTooltipClose()
        }
        setRegisterFirstName(event.target.value);
    };
    const handleChangeRegisterLastName = (event) => {
        if(registerLastNameError){
            setRegisterLastNameError("")
            handleRegisterLastNameErrorTooltipClose()
        }
        setRegisterLastName(event.target.value);
    };
    const handleChangeRegisterPhone = (event) => {
        if(registerPhoneError){
            setRegisterPhoneError("")
            handleRegisterPhoneErrorTooltipClose()
        }
        setRegisterPhone(event.target.value);
    };
    const handleChangeRegisterEmail = (event) => {
        if(registerEmailError){
            setRegisterEmailError("")
            handleRegisterEmailErrorTooltipClose()
        }
        setRegisterEmail(event.target.value);
    };
    const handleChangeRegisterPassword = (event) => {
        if(registerPasswordError){
            setRegisterPasswordError("")
            handleRegisterPasswordErrorTooltipClose()
        }
        setRegisterPassword(event.target.value);
    };
    const handleChangeRegisterConfirm = (event) => {
        if(registerConfirmError){
            setRegisterConfirmError("")
            handleRegisterConfirmErrorTooltipClose()
        }
        setRegisterConfirm(event.target.value);
    };

    const handleChangeRecoverEmail = (event) => {
        if(recoverEmailError){
            setRecoverEmailError("")
            handleRecoverEmailErrorTooltipClose()
        }
        if(sendEmailError){
            setSendEmailError("")
        }
        setRecoverEmail(event.target.value);
    };
    const handleChangeVerificate1 = (event) => {
        setVerificate1(event.target.value);
        if(!verificate2)
        {
            const nextSibling = document.querySelector(
                `input[name=ver-2]`
            );
          // If found, focus the next field
            if (nextSibling !== null) {
                nextSibling.focus();
            }
        }
        else{
            if(!verificate3)
            {
                const nextSibling = document.querySelector(
                    `input[name=ver-3]`
                );
                // If found, focus the next field
                if (nextSibling !== null) {
                    nextSibling.focus();
                }
            }
            else{
                if(!verificate4)
                {
                    const nextSibling = document.querySelector(
                        `input[name=ver-4]`
                    );
                    // If found, focus the next field
                    if (nextSibling !== null) {
                        nextSibling.focus();
                    }
                }
            }    
        }
    };
    const handleChangeVerificate2 = (event) => {
        setVerificate2(event.target.value);
        if(!verificate3)
        {
            const nextSibling = document.querySelector(
                `input[name=ver-3]`
            );
          // If found, focus the next field
            if (nextSibling !== null) {
                nextSibling.focus();
            }
        }
        else{
            if(!verificate4)
            {
                const nextSibling = document.querySelector(
                    `input[name=ver-4]`
                );
                // If found, focus the next field
                if (nextSibling !== null) {
                    nextSibling.focus();
                }
            }
        }
    };
    const handleChangeVerificate3 = (event) => {
        setVerificate3(event.target.value);
        if(!verificate4)
        {
            const nextSibling = document.querySelector(
                `input[name=ver-4]`
            );
          // If found, focus the next field
            if (nextSibling !== null) {
                nextSibling.focus();
            }
        }
    };
    const handleChangeVerificate4 = (event) => {
        setVerificate4(event.target.value);
    };
    const handleChangeRecoveryPassword = (event) => {
        if(recoveryPasswordError){
            setRecoveryPasswordError("")
            handleRecoveryPasswordErrorTooltipClose()
        }
        setRecoveryPassword(event.target.value);
    };
    const handleChangeRecoveryConfirm = (event) => {
        if(recoveryConfirmError){
            setRecoveryConfirmError("")
            handleRecoveryConfirmErrorTooltipClose()
        }
        setRecoveryConfirm(event.target.value);
    };
    // #endregion

    //TooltipErrors
    const handleLoginUserNameErrorTooltipClose = () => {
        setLoginUserNameErrorOpen(false);
    };
    const handleLoginUserNameErrorTooltipOpen = () => {
        setLoginUserNameErrorOpen(true);
    };
    const handleLoginPasswordErrorTooltipClose = () => {
        setLoginPasswordErrorOpen(false);
    };
    const handleLoginPasswordErrorTooltipOpen = () => {
        setLoginPasswordErrorOpen(true);
    };

    const handleRegisterUserNameErrorTooltipClose = () => {
        setRegisterUserNameErrorOpen(false);
    };
    const handleRegisterUserNameErrorTooltipOpen = () => {
        setRegisterUserNameErrorOpen(true);
    };
    const handleRegisterFirstNameErrorTooltipClose = () => {
        setRegisterFirstNameErrorOpen(false);
    };
    const handleRegisterFirstNameErrorTooltipOpen = () => {
        setRegisterFirstNameErrorOpen(true);
    };
    const handleRegisterLastNameErrorTooltipClose = () => {
        setRegisterLastNameErrorOpen(false);
    };
    const handleRegisterLastNameErrorTooltipOpen = () => {
        setRegisterLastNameErrorOpen(true);
    };
    const handleRegisterPhoneErrorTooltipClose = () => {
        setRegisterPhoneErrorOpen(false);
    };
    const handleRegisterPhoneErrorTooltipOpen = () => {
        setRegisterPhoneErrorOpen(true);
    };
    const handleRegisterEmailErrorTooltipClose = () => {
        setRegisterEmailErrorOpen(false);
    };
    const handleRegisterEmailErrorTooltipOpen = () => {
        setRegisterEmailErrorOpen(true);
    };
    const handleRegisterPasswordErrorTooltipClose = () => {
        setRegisterPasswordErrorOpen(false);
    };
    const handleRegisterPasswordErrorTooltipOpen = () => {
        setRegisterPasswordErrorOpen(true);
    };
    const handleRegisterConfirmErrorTooltipClose = () => {
        setRegisterConfirmErrorOpen(false);
    };
    const handleRegisterConfirmErrorTooltipOpen = () => {
        setRegisterConfirmErrorOpen(true);
    };

    //RECOVER
    const handleRecoverEmailErrorTooltipClose = () => {
        setRecoverEmailErrorOpen(false);
    };
    const handleRecoverEmailErrorTooltipOpen = () => {
        setRecoverEmailErrorOpen(true);
    };
    const handleRecoveryPasswordErrorTooltipClose = () => {
        setRecoveryPasswordErrorOpen(false);
    };
    const handleRecoveryPasswordErrorTooltipOpen = () => {
        setRecoveryPasswordErrorOpen(true);
    };
    const handleRecoveryConfirmErrorTooltipClose = () => {
        setRecoveryConfirmErrorOpen(false);
    };
    const handleRecoveryConfirmErrorTooltipOpen = () => {
        setRecoveryConfirmErrorOpen(true);
    };

    const mailToSupportEmail = `mailto: ${supportEmail}`

    //Handle Password Hide and Show

    const handleSubmitLogin = async (event) => {
        event.preventDefault();
        let errorType = ""
        try{
            if(!loginPassword){
                setLoginPasswordError("Debe poner una contraseña.")
                errorType = "2"
            }
            if(!loginUserName){
                setLoginUserNameError("Debe poner un nombre de usuario o correo.")
                errorType = "1"
            }
            if(errorType){
                if(errorType === "1"){
                    handleLoginUserNameErrorTooltipOpen()
                }
                else{
                    handleLoginPasswordErrorTooltipOpen()
                }
            }
            else{
                setLoading(true)
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                        username : loginUserName.trim(),
                        password : loginPassword,
                    })
                };
                const controller = 'accountImport'
                let result = await fetch(`${url}/api/${controller}/login`, requestOptions)
                if(result.status === 200){
                    let loginData = await result.json()
                    let user = { _id: loginData.user._id, username: loginData.user.username, email: loginData.user.email, profilePictureUrl: loginData.user.profilePictureUrl, isImportCompany: loginData.user.isImportCompany, isImportAdmin: loginData.user.isImportAdmin, isSuperAdmin: loginData.user.isSuperAdmin, accessToken: loginData.token}
                    user.name = loginData.user.name + ' ' + loginData.user.lastName
                    user.phone = loginData.user.phone
                    window.localStorage.setItem(
                        'user', JSON.stringify({idToken: user._id, accessToken: user.accessToken})
                    )
                    dispatch(update_user_action(user))
                    socket.emit("addSocketToImportUser", {
                        userId: loginData.user._id
                    })
                    subscribeUser()
                    setLoading(false)
                    setOpenSuccesSnack()
                    setLoginUserName("");
                    setLoginPassword("");
                    setRegisterUserName("");
                    setRegisterEmail("");
                    setRegisterPassword("");
                    setRegisterConfirm("");
                    dispatch(update_login_action(false));
                }
                else{
                    let loginData = await result.json()
                    if (loginData.errorType === 0) {
                        setLoginUserNameError("Nombre de usuario o correo incorrecto.")
                        handleLoginUserNameErrorTooltipOpen()
                        setLoading(false)
                        return;
                    } 
                    if (loginData.errorType === 1){
                        setLoginPasswordError("Contraseña incorrecta.")
                        handleLoginPasswordErrorTooltipOpen()
                        setLoading(false)
                        return;
                    }
                    setOpenApiErrorSnack()
                    setLoading(false)
                }  
            }      
        }
        catch(e){
            console.log(e)
            setOpenApiErrorSnack()
            setLoading(false)
        }
    }

    const handleSubmitRegister = async (event) => {
        if(registerLoading)return;
        event.preventDefault();
        setApiError(false)
        let errorType = ""
        if(registerConfirm){
            if(registerPassword){
                if(!(registerConfirm === registerPassword)){
                    setRegisterConfirmError("La confirmación y la contraseña deben coincidir.")
                    errorType = "4"
                }
            }    
        }
        else{
            setRegisterConfirmError("Debe poner la confirmación.")
            errorType = "4"
        }
        if(registerPassword === ""){
            setRegisterPasswordError("Debe poner una contraseña nueva.")
            errorType = "3"
        }
        else{
            if(registerPassword.length < 6){
                setRegisterPasswordError("La contraseña debe tener al menos 6 caracteres.")
                errorType = "3"
            }
        }
        if(registerPhone === ""){
            setRegisterPhoneError("Debe poner un telefono.")
            errorType = 5
        }
        else{
            if(!parseInt(registerPhone)){
                setRegisterPhoneError("Debe poner un telefono valido.")
                errorType = 5
            }
        }
        if(registerLastName === ""){
            setRegisterLastNameError("Debe poner sus apellidos.")
            errorType = 6
        }
        if(registerFirstName === ""){
            setRegisterFirstNameError("Debe poner su(s) nombre(s).")
            errorType = 7
        }
        if(registerEmail === ""){
            setRegisterEmailError("Debe poner un correo electrónico.")
            errorType = "2"
        }
        else{
            if(registerEmail.includes("@") === false){
                setRegisterEmailError("Debe poner un correo electrónico válido.")
                errorType = "2"
            }
        }
        if(errorType){
            switch(errorType){
                case "1":{
                    handleRegisterUserNameErrorTooltipOpen()
                    break;
                }
                case "2":{
                    handleRegisterEmailErrorTooltipOpen()
                    break;
                }
                case "3":{
                    handleRegisterPasswordErrorTooltipOpen()
                    break;
                }
                case "4":{
                    handleRegisterConfirmErrorTooltipOpen()
                    break;
                }
                case "5": {
                    handleRegisterPhoneErrorTooltipOpen()
                }
                case "6": {
                    handleRegisterLastNameErrorTooltipOpen()
                }
                case "7": {
                    handleRegisterFirstNameErrorTooltipOpen()
                }
            }
        }
        else{
            try{
                setRegisterLoading(true)
                if(!code){
                    const requestRegisterOptions = {
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json' }
                    };
                    const controller = 'accountImport'
                    let codeResult = await fetch(`${url}/api/${controller}/confirmRegister/${registerEmail}/${registerEmail}${'/' + registerFirstName + ' ' + registerLastName}${'/'+phoneRegion+registerPhone}`, requestRegisterOptions)
                    if(codeResult.status === 200){
                        let registerData = await codeResult.json()
                        setRegisterLoading(false)
                        setCode(registerData.code)
                        console.log(registerData.code)
                        setRegisterLoading(false)
                        setEmailConfirming(true)
                        return;
                    }
                    else{
                        if(codeResult.status === 400){
                            let registerData = await codeResult.json()
                            console.log(registerData)
                            if(registerData.errorType === 0)
                            {
                                setRegisterEmailError("Este correo ya esta registrado en la plataforma")
                                handleRegisterEmailErrorTooltipOpen()
                                setRegisterLoading(false)
                                return;
                            }
                            if(registerData.errorType === 1)
                            {
                                setRegisterEmailError("Este correo ya esta registrado en la plataforma.")
                                handleRegisterEmailErrorTooltipOpen()
                                setRegisterLoading(false)
                                return;
                            }
                            if(registerData.errorType === 2)
                            {
                                setRegisterEmailError("Correo incorrecto, por favor verifique.")
                                handleRegisterEmailErrorTooltipOpen()
                                setRegisterLoading(false)
                                return;
                            }
                            setOpenRegisterApiErrorSnack()
                            setRegisterLoading(false)
                            return;
                        }
                    }
                }
                if(!verificate1){
                    setVerificateError("Debe poner el código.")
                    setRegisterLoading(false)
                    return;
                }
                if(!verificate2){
                    setVerificateError("Debe poner el código.")
                    setRegisterLoading(false)
                    return;
                }
                if(!verificate3){
                    setVerificateError("Debe poner el código.")
                    setRegisterLoading(false)
                    return;
                }
                if(!verificate4){
                    setVerificateError("Debe poner el código.")
                    setRegisterLoading(false)
                    return;
                }
                let userToRegister = {
                    username : registerUserName,
                    email: registerEmail.trim(),
                    phone : phoneRegion + registerPhone,
                    password : registerPassword,
                    region: regionSelected === "Seleccione Provincia"? "Desconocida": regionSelected,
                    code: code,
                    emailCode: `${verificate1}${verificate2}${verificate3}${verificate4}`
                }
                userToRegister.username = registerEmail
                userToRegister.region = "Desconocida"
                userToRegister.name = registerFirstName
                userToRegister.lastName = registerLastName
                if(registerPromotion){
                    userToRegister.promotionUser = id
                }
                const requestRegisterOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(userToRegister)
                };
                const controller = 'accountImport'
                let registerResult = await fetch(`${url}/api/${controller}`, requestRegisterOptions)
                if(registerResult.status === 200){
                    setOpenRegisterSuccesSnack()
                    const requestLoginOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ 
                            username : registerEmail,
                            password : registerPassword,
                        })
                    };
                    const controller = 'accountImport'
                    let loginResult = await fetch(`${url}/api/${controller}/login`, requestLoginOptions)
                    if(loginResult.status === 200){
                        let loginData = await loginResult.json()
                        let user = { _id: loginData.user._id, username: loginData.user.username, email: loginData.user.email, profilePictureUrl: loginData.user.profilePictureUrl, isImportCompany: loginData.user.isImportCompany, isImportAdmin: loginData.user.isImportAdmin, isSuperAdmin: loginData.user.isSuperAdmin, accessToken: loginData.token}
                        user.name = loginData.user.name + ' ' + loginData.user.lastName
                        user.phone = loginData.user.phone
                        window.localStorage.setItem(
                            'user', JSON.stringify({idToken: user._id, accessToken: user.accessToken})
                        )
                        dispatch(update_user_action(user))
                        socket.emit("addSocketToImportUser", {
                            userId: loginData.user._id
                        })
                        subscribeUser()
                        setRegisterLoading(false)
                        setOpenSuccesSnack()
                        setLoginUserName("");
                        setLoginPassword("");
                        setRegisterUserName("");
                        setRegisterEmail("");
                        setRegisterPassword("");
                        setRegisterConfirm("");
                        dispatch(update_login_action(false));
                    }
                    else{
                        setOpenApiErrorSnack()
                        setRegisterLoading(false)
                    }      
                }
                else{
                    let registerData = await registerResult.json()
                    if(registerData.errorType === 0)
                    {
                        setRegisterUserNameError("Nombre de usuario en uso.")
                        handleRegisterUserNameErrorTooltipOpen()
                        setRegisterLoading(false)
                        return;
                    }
                    if(registerData.errorType === 1)
                    {
                        setRegisterEmailError("Correo en uso.")
                        handleRegisterUserNameErrorTooltipOpen()
                        setRegisterLoading(false)
                        return;
                    }
                    if(registerData.errorType === 3)
                    {
                        setVerificateError("El código de confirmación no coincide con el enviado a su correo")
                        setRegisterLoading(false)
                        return;
                    }
                    setOpenRegisterApiErrorSnack()
                    setRegisterLoading(false)
                }
                
            }
            catch(e){
                setOpenRegisterApiErrorSnack()
                setRegisterLoading(false)
            }
        }
    }
    
    return(
        <div>
            <Dialog
                PaperProps={{
                    style: { borderRadius: '10px', backgroundColor: '#f5f5f5' }
                }}
                open={openLogin}
                onClose={handleLoginClose}
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogContent>
                    <Snackbar open={openChangePasswordSuccesSnack} autoHideDuration={2000} onClose={handleCloseChangePasswordSuccesSnack}>
                        <Alert onClose={handleCloseChangePasswordSuccesSnack} severity="success">
                            Contraseña cambiada corectamente. Inicie sesión con su nueva contraseña.
                        </Alert>
                    </Snackbar>
                    {recoveringPassword? 
                    <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                    >
                        <Stepper style={{backgroundColor: '#f5f5f5'}} activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <div>
                            {(activeStep === 0) &&
                                <div style={{maxWidth: '300px'}}>
                                    <img className={classes.stepperImage} src={forget_password_secondary}/>
                                    <Typography className={classes.stepperMainText}>
                                        Olvidaste tu contraseña?
                                    </Typography>
                                    <Typography className={classes.stepperSecondaryText}>
                                        Introduce tu dirección de correo y te enviaremos un código de 4 dígitos.
                                    </Typography>
                                    <div className={classes.inputHolder}>
                                        <InputBase
                                            elevation={4}
                                            onClick={() => 
                                                {
                                                    if(recoverEmailError) handleRecoverEmailErrorTooltipOpen()
                                                }}
                                            placeholder="Correo"
                                            type="email"
                                            value={recoverEmail}
                                            onChange={handleChangeRecoverEmail}
                                            classes={{
                                                root: classes.inputRootLogin,
                                                input: classes.inputInputLogin,
                                            }}
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                        {recoverEmailError &&
                                            <div>
                                            <MuiThemeProvider theme={themeMUI}>
                                                <Tooltip classes={{arrow: classes.arrow}}
                                                    arrow
                                                    PopperProps={{
                                                    disablePortal: true,
                                                    }}
                                                    onClose={handleRecoverEmailErrorTooltipClose}
                                                    open={recoverEmailErrorOpen}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title={recoverEmailError}
                                                >
                                                    <IconButton onClick={handleRecoverEmailErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                                        <WarningAmberIcon fontSize="small" />
                                                    </IconButton>
                                                    
                                                </Tooltip>
                                            </MuiThemeProvider>
                                            </div>                       
                                        }
                                    </div>
                                    {sendEmailError &&
                                        <Typography className={classes.verificateErrorText}>
                                            {sendEmailError}
                                        </Typography>                
                                    }
                                </div>
                            }
                            {(activeStep === 1) &&
                                <div style={{maxWidth: '300px'}}>
                                    <img className={classes.stepperImage} src={verificate_password_secondary}/>
                                    <Typography className={classes.stepperMainText}>
                                        Verificación
                                    </Typography>
                                    <Typography className={classes.stepperSecondaryText}>
                                        Introduce el código que te hemos enviado a tu correo.
                                    </Typography>
                                    <div style={{display:'flex'}}>
                                        <div style={{marginLeft: 'auto'}} className={classes.inputVerificateHolder}>
                                            <InputBase
                                                elevation={4}
                                                onClick={() => 
                                                    {
                                                        if(verificateError) setVerificateError("")
                                                        if(setCodeResended) setCodeResended(false)
                                                        setVerificate1("")
                                                    }}
                                                type="number"
                                                name='ver-1'
                                                value={verificate1}
                                                onChange={handleChangeVerificate1}
                                                classes={{
                                                    root: classes.inputRootVerificate,
                                                    input: classes.inputInputVerificate,
                                                }}
                                                inputProps={{ 'aria-label': 'search', maxLength:'1' }}
                                            />
                                        </div>
                                        <div className={classes.inputVerificateHolder}>
                                            <InputBase
                                                elevation={4}
                                                onClick={() => 
                                                    {
                                                        if(verificateError) setVerificateError("")
                                                        if(setCodeResended) setCodeResended(false)
                                                        setVerificate2("")
                                                    }}
                                                type="number"
                                                name='ver-2'
                                                value={verificate2}
                                                onChange={handleChangeVerificate2}
                                                classes={{
                                                    root: classes.inputRootVerificate,
                                                    input: classes.inputInputVerificate,
                                                }}
                                                inputProps={{ 'aria-label': 'search', maxLength:'1' }}
                                            />
                                        </div>
                                        <div className={classes.inputVerificateHolder}>
                                            <InputBase
                                                elevation={4}
                                                onClick={() => 
                                                    {
                                                        if(verificateError) setVerificateError("")
                                                        if(setCodeResended) setCodeResended(false)
                                                        setVerificate3("")
                                                    }}
                                                type="number"
                                                name='ver-3'
                                                value={verificate3}
                                                maxLength={1}
                                                onChange={handleChangeVerificate3}
                                                classes={{
                                                    root: classes.inputRootVerificate,
                                                    input: classes.inputInputVerificate,
                                                }}
                                                inputProps={{ 'aria-label': 'search', maxLength:'1' }}
                                            />
                                        </div>
                                        <div style={{marginRight: 'auto'}} className={classes.inputVerificateHolder}>
                                            <InputBase
                                                elevation={4}
                                                onClick={() => 
                                                    {
                                                        if(verificateError) setVerificateError("")
                                                        if(setCodeResended) setCodeResended(false)
                                                        setVerificate4("")
                                                    }}
                                                type="number"
                                                name='ver-4'
                                                value={verificate4}
                                                onChange={handleChangeVerificate4}
                                                classes={{
                                                    root: classes.inputRootVerificate,
                                                    input: classes.inputInputVerificate,
                                                }}
                                                inputProps={{ 'aria-label': 'search', maxLength:'1' }}
                                            />
                                        </div>
                                    </div>
                                    {verificateError &&
                                        <Typography className={classes.verificateErrorText}>
                                            {verificateError}
                                        </Typography>                
                                    }
                                    {reSendingCode &&
                                        <CircularProgress size={24} style={{display: 'block', margin: '10px auto 0px auto', color: colors.primary}}/>
                                    }
                                    {codeResended &&
                                        <Typography className={classes.codeResendedText}>
                                            Código Reenviado Correctamente. Verifique su correo. Si no lo recibe 
                                            escriba un mensaje a nuestro soporte via <a style={{textDecoration: 'underline', color: 'red'}} href={mailToSupportEmail}>correo</a> o <a style={{textDecoration: 'underline', color: 'green'}} target="_blank" href={`https://wa.me/${supportNumber}?text=${encodeURI("Hola, tengo una pregunta.")}`}>whatsap</a> 
                                        </Typography>
                                    }
                                    <Typography className={classes.stepperTerciaryText}
                                        onClick={ResendCode}>
                                        No has recibido ningún código? <Link style={{textDecoration: 'underline', color: 'rgba(0, 0, 0, 0.74)'}}>REENVIAR</Link>
                                    </Typography>
                                </div>
                            }
                            {(activeStep === 2) &&
                                <div style={{maxWidth: '300px'}}>
                                    <img className={classes.stepperImage} src={recovery_password_secondary}/>
                                    <Typography className={classes.stepperMainText}>
                                        Recuperar contraseña
                                    </Typography>
                                    <Typography className={classes.stepperSecondaryText}>
                                        Ingresa la nueva contraseña para ingresar a tu cuenta.
                                    </Typography>
                                    <div className={classes.inputHolder}>
                                        <InputBase
                                            elevation={4}
                                            type={recoveryPasswordShow? "text" : "password"}
                                            onClick={() => 
                                                {
                                                    if(changePasswordError) setChangePasswordError("")
                                                    if(recoveryPasswordError) handleRecoveryPasswordErrorTooltipOpen()
                                                    if(recoveryConfirmError) handleRecoveryConfirmErrorTooltipClose()
                                                }}
                                            placeholder="Contraseña"
                                            value={recoveryPassword}
                                            onChange={handleChangeRecoveryPassword}
                                            classes={{
                                                root: classes.inputRootLogin,
                                                input: classes.inputInputLogin,
                                            }}
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                        {recoveryPasswordError &&
                                            <div>
                                            <MuiThemeProvider theme={themeMUI}>
                                                <Tooltip classes={{arrow: classes.arrow}}
                                                    arrow
                                                    PopperProps={{
                                                    disablePortal: true,
                                                    }}
                                                    onClose={handleRecoveryPasswordErrorTooltipClose}
                                                    open={recoveryPasswordErrorOpen}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title={recoveryPasswordError}
                                                >
                                                    <IconButton onClick={handleRecoveryPasswordErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                                        <WarningAmberIcon fontSize="small" />
                                                    </IconButton>
                                                    
                                                </Tooltip>
                                            </MuiThemeProvider>
                                            </div>                       
                                        }
                                        {recoveryPasswordShow?
                                            <IconButton onClick={() => setRecoveryPasswordShow(false)} aria-label="warning" className={classes.showButton}>
                                                <VisibilityOffIcon fontSize="small" />
                                            </IconButton>
                                        :
                                            <IconButton onClick={() => setRecoveryPasswordShow(true)} aria-label="warning" className={classes.showButton}>
                                                <VisibilityOnIcon fontSize="small" />
                                            </IconButton>
                                        }
                                    </div>
                                    <div className={classes.inputHolder}>
                                        <InputBase
                                            elevation={4}
                                            type={recoveryConfirmShow? "text" : "password"}
                                            onClick={() => 
                                                {
                                                    if(changePasswordError) setChangePasswordError("")
                                                    if(recoveryPasswordError) handleRecoveryPasswordErrorTooltipClose()
                                                    if(recoveryConfirmError) handleRecoveryConfirmErrorTooltipOpen()
                                                }}
                                            placeholder="Confirmar"
                                            value={recoveryConfirm}
                                            onChange={handleChangeRecoveryConfirm}
                                            classes={{
                                                root: classes.inputRootLogin,
                                                input: classes.inputInputLogin,
                                            }}
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                        {recoveryConfirmError &&
                                            <div>
                                            <MuiThemeProvider theme={themeMUI}>
                                                <Tooltip classes={{arrow: classes.arrow}}
                                                    arrow
                                                    PopperProps={{
                                                    disablePortal: true,
                                                    }}
                                                    onClose={handleRecoveryConfirmErrorTooltipClose}
                                                    open={recoveryConfirmErrorOpen}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title={recoveryConfirmError}
                                                >
                                                    <IconButton onClick={handleRecoveryConfirmErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                                        <WarningAmberIcon fontSize="small" />
                                                    </IconButton>
                                                    
                                                </Tooltip>
                                            </MuiThemeProvider>
                                            </div>                       
                                        }
                                        {recoveryConfirmShow?
                                            <IconButton onClick={() => setRecoveryConfirmShow(false)} aria-label="warning" className={classes.showButton}>
                                                <VisibilityOffIcon fontSize="small" />
                                            </IconButton>
                                        :
                                            <IconButton onClick={() => setRecoveryConfirmShow(true)} aria-label="warning" className={classes.showButton}>
                                                <VisibilityOnIcon fontSize="small" />
                                            </IconButton>
                                        }
                                    </div>
                                    {changePasswordError &&
                                        <Typography className={classes.verificateErrorText}>
                                            {changePasswordError}
                                        </Typography>                
                                    }
                                </div>
                            }
                            <div style={{display: 'flex', marginTop: '30px'}}>
                            <Button
                                onClick={handleBack}
                                disabled={sendingCode}
                                className={classes.backButton}
                            >
                                Atrás
                            </Button>
                            {sendingCode?
                                <Button
                                style={{marginLeft: 'auto'}}
                                disabled={sendingCode || reSendingCode}
                                className={activeStep === steps.length - 1 ? classes.circularProgressButtonLastStep : classes.circularProgressButton}
                                classes={{
                                    label: classes.label, // class name, e.g. `classes-nesting-label-x`
                                }}
                                ><CircularProgress size={24} style={{color: 'white'}}/></Button>
                            :
                                <Button disabled={sendingCode || reSendingCode} className={classes.nextButton} style={{marginLeft: 'auto'}} variant="contained" color="primary" onClick={handleNext}>
                                    {activeStep === steps.length - 1 ? 'Confirmar' : 'Siguiente'}
                                </Button>
                            }
                            
                            </div>
                        </div>
                    </DialogContentText>
                    :
                    <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                    >   
                        {login? 
                            <div>
                                <Typography
                                    style={{textAlign: 'center', marginTop: '22px', marginBottom: '30px', fontSize: '22px', fontWeight: '700', color: 'black'}}>
                                    Iniciar Sesión
                                </Typography>
                                <form onSubmit={handleSubmitLogin}>
                                    <div className={classes.inputHolder}>
                                        <InputBase
                                            onClick={() => 
                                                {
                                                    if(loginPasswordError) handleLoginPasswordErrorTooltipClose()
                                                    if(loginUserNameError) handleLoginUserNameErrorTooltipOpen()
                                                }}
                                            elevation={4}
                                            placeholder={"Correo"}
                                            value={loginUserName}
                                            onChange={handleChangeLoginUserName}
                                            classes={{
                                                root: classes.inputRootLogin,
                                                input: classes.inputInputLogin,
                                            }}
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                        {loginUserNameError &&
                                            <div>
                                            <MuiThemeProvider theme={themeMUI}>
                                                <Tooltip classes={{arrow: classes.arrow}}
                                                    arrow
                                                    PopperProps={{
                                                    disablePortal: true,
                                                    }}
                                                    onClose={handleLoginUserNameErrorTooltipClose}
                                                    open={loginUserNameErrorOpen}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title={loginUserNameError}
                                                >
                                                    <IconButton onClick={handleLoginUserNameErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                                        <WarningAmberIcon fontSize="small" />
                                                    </IconButton>
                                                    
                                                </Tooltip>
                                            </MuiThemeProvider>
                                            </div>                       
                                        }
                                    </div>

                                    <div className={classes.inputHolder}>
                                        <InputBase
                                            onClick={() => 
                                            {
                                                if(loginPasswordError) handleLoginPasswordErrorTooltipOpen()
                                                if(loginUserNameError) handleLoginUserNameErrorTooltipClose()
                                            }}
                                            type={loginPasswordShow? "text" : "password"}
                                            elevation={4}
                                            placeholder="Contraseña"
                                            value={loginPassword}
                                            onChange={handleChangeLoginPassword}
                                            classes={{
                                                root: classes.inputRootLogin,
                                                input: classes.inputInputLogin,
                                            }}
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                        {loginPasswordError &&
                                            <div>
                                            <MuiThemeProvider theme={themeMUI}>
                                                <Tooltip classes={{arrow: classes.arrow}}
                                                    arrow
                                                    PopperProps={{
                                                    disablePortal: true,
                                                    }}
                                                    onClose={handleLoginPasswordErrorTooltipClose}
                                                    open={loginPasswordErrorOpen}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title={loginPasswordError}
                                                >
                                                    <IconButton onClick={handleLoginPasswordErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                                        <WarningAmberIcon fontSize="small" />
                                                    </IconButton>
                                                    
                                                </Tooltip>
                                            </MuiThemeProvider>
                                            </div>                       
                                        }
                                        {loginPasswordShow?
                                            <IconButton onClick={() => setLoginPasswordShow(false)} aria-label="warning" className={classes.showButton}>
                                                <VisibilityOffIcon fontSize="small" />
                                            </IconButton>
                                        :
                                            <IconButton onClick={() => setLoginPasswordShow(true)} aria-label="warning" className={classes.showButton}>
                                                <VisibilityOnIcon fontSize="small" />
                                            </IconButton>
                                        }
                                    </div>
                                    {loading && <CircularProgress size={30} style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', color: colors.primary}}/>}
                                    <Button
                                        type='submit'
                                        variant="contained"
                                        className={classes.primaryButton}
                                        classes={{
                                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                                        }}
                                    >Continuar</Button>
                                </form>
                                <Typography style={{textAlign: 'center', width: '80%', color: 'rgba(0, 0, 0, 0.74)', marginLeft: '10%'}}>
                                    Olvidaste tu contraseña? Puedes recuperarla <Link onClick={() => {setRecoveringPassword(true)}} style={{textDecoration: 'underline', color: colors.primary}}>aquí</Link>
                                </Typography>
                                <Typography style={{ marginTop: '30px', textAlign: 'center', width: '80%', color: 'rgba(0, 0, 0, 0.74)', marginLeft: '10%'}}>
                                    Si no tienes una cuenta de usuario
                                </Typography>
                                <Button
                                        type='submit'
                                        variant="outline"
                                        onClick={() => {setLogin(false)}}
                                        className={classes.secondaryButton}
                                        classes={{
                                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                                        }}
                                >Regístrate</Button>
                            </div>
                        :
                            <div>
                                <Typography
                                    style={{textAlign: 'center', marginTop: '22px', marginBottom: '30px', fontSize: '22px', fontWeight: '700', color: 'black'}}>
                                    Registrarme
                                </Typography>
                                {/* <Typography
                                    style={{textAlign: 'center', marginTop: '-20px', marginBottom: '15px', fontSize: '13px', fontWeight: '600', color: 'black', opacity: '0.9', marginLeft: 'auto', marginRight: 'auto', maxWidth: '350px'}}>
                                    Por promoción de fin de año, obtendrá de regalo 10 usd en su billetera electrónica de sevendeimport
                                </Typography> */}
                                <form onSubmit={handleSubmitRegister}>
                                    <div className={classes.inputHolder}>
                                        <InputBase
                                            elevation={4}
                                            onClick={() => 
                                                {
                                                    if(registerUserNameError) handleRegisterUserNameErrorTooltipClose()
                                                    if(registerEmailError) handleRegisterEmailErrorTooltipOpen()
                                                    if(registerPasswordError) handleRegisterPasswordErrorTooltipClose()
                                                    if(registerConfirmError) handleRegisterConfirmErrorTooltipClose()
                                                    if(registerFirstNameError) handleRegisterFirstNameErrorTooltipClose()
                                                    if(registerLastNameError) handleRegisterLastNameErrorTooltipClose()
                                                    
                                                    if(registerPhoneError) handleRegisterPhoneErrorTooltipOpen()
                                                }}
                                            placeholder="Correo"
                                            type="email"
                                            value={registerEmail}
                                            onChange={handleChangeRegisterEmail}
                                            classes={{
                                                root: classes.inputRootLogin,
                                                input: classes.inputInputLogin,
                                            }}
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                        {registerEmailError &&
                                            <div>
                                            <MuiThemeProvider theme={themeMUI}>
                                                <Tooltip classes={{arrow: classes.arrow}}
                                                    arrow
                                                    PopperProps={{
                                                    disablePortal: true,
                                                    }}
                                                    onClose={handleRegisterEmailErrorTooltipClose}
                                                    open={registerEmailErrorOpen}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title={registerEmailError}
                                                >
                                                    <IconButton onClick={handleRegisterEmailErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                                        <WarningAmberIcon fontSize="small" />
                                                    </IconButton>
                                                    
                                                </Tooltip>
                                            </MuiThemeProvider>
                                            </div>                       
                                        }
                                    </div>
                                    <div className={classes.inputHolder}>
                                        <InputBase
                                            elevation={4}
                                            onClick={() => 
                                                {
                                                    if(registerUserNameError) handleRegisterUserNameErrorTooltipClose()
                                                    if(registerEmailError) handleRegisterEmailErrorTooltipClose()
                                                    if(registerPasswordError) handleRegisterPasswordErrorTooltipClose()
                                                    if(registerConfirmError) handleRegisterConfirmErrorTooltipClose()
                                                    if(registerFirstNameError) handleRegisterFirstNameErrorTooltipOpen()
                                                    if(registerLastNameError) handleRegisterLastNameErrorTooltipClose()
                                                    
                                                    if(registerPhoneError) handleRegisterPhoneErrorTooltipOpen()
                                                }}
                                            placeholder="Nombre(s)"
                                            value={registerFirstName}
                                            onChange={handleChangeRegisterFirstName}
                                            classes={{
                                                root: classes.inputRootLogin,
                                                input: classes.inputInputLogin,
                                            }}
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                        {registerFirstNameError &&
                                            <div>
                                            <MuiThemeProvider theme={themeMUI}>
                                                <Tooltip classes={{arrow: classes.arrow}}
                                                    arrow
                                                    PopperProps={{
                                                    disablePortal: true,
                                                    }}
                                                    onClose={handleRegisterFirstNameErrorTooltipClose}
                                                    open={registerFirstNameErrorOpen}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title={registerFirstNameError}
                                                >
                                                    <IconButton onClick={handleRegisterFirstNameErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                                        <WarningAmberIcon fontSize="small" />
                                                    </IconButton>
                                                    
                                                </Tooltip>
                                            </MuiThemeProvider>
                                            </div>                       
                                        }
                                    </div>
                                    <div className={classes.inputHolder}>
                                        <InputBase
                                            elevation={4}
                                            onClick={() => 
                                                {
                                                    if(registerUserNameError) handleRegisterUserNameErrorTooltipClose()
                                                    if(registerEmailError) handleRegisterEmailErrorTooltipClose()
                                                    if(registerPasswordError) handleRegisterPasswordErrorTooltipClose()
                                                    if(registerConfirmError) handleRegisterConfirmErrorTooltipClose()
                                                    if(registerFirstNameError) handleRegisterFirstNameErrorTooltipClose()
                                                    if(registerLastNameError) handleRegisterLastNameErrorTooltipOpen()
                                                }}
                                            placeholder="Apellidos"
                                            value={registerLastName}
                                            onChange={handleChangeRegisterLastName}
                                            classes={{
                                                root: classes.inputRootLogin,
                                                input: classes.inputInputLogin,
                                            }}
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                        {registerLastNameError &&
                                            <div>
                                            <MuiThemeProvider theme={themeMUI}>
                                                <Tooltip classes={{arrow: classes.arrow}}
                                                    arrow
                                                    PopperProps={{
                                                    disablePortal: true,
                                                    }}
                                                    onClose={handleRegisterLastNameErrorTooltipClose}
                                                    open={registerLastNameErrorOpen}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title={registerLastNameError}
                                                >
                                                    <IconButton onClick={handleRegisterLastNameErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                                        <WarningAmberIcon fontSize="small" />
                                                    </IconButton>
                                                    
                                                </Tooltip>
                                            </MuiThemeProvider>
                                            </div>                       
                                        }
                                    </div>
                                    <div className={classes.inputHolder}>
                                        <Select
                                            value={phoneRegion}
                                            onChange={handleChangePhoneRegion}
                                            disableUnderline
                                            className={classes.userDataSelectPhone}
                                            inputProps={{ 'aria-label': 'region' }}
                                        >
                                            {phoneAreas.map(phoneName => (  
                                                <MenuItem value={phoneName}>{phoneName}</MenuItem>
                                            ))}
                                        </Select>
                                        <InputBase
                                            elevation={4}
                                            onClick={() => 
                                                {
                                                    if(registerUserNameError) handleRegisterUserNameErrorTooltipClose()
                                                    if(registerEmailError) handleRegisterEmailErrorTooltipClose()
                                                    if(registerPasswordError) handleRegisterPasswordErrorTooltipClose()
                                                    if(registerConfirmError) handleRegisterConfirmErrorTooltipClose()
                                                    if(registerFirstNameError) handleRegisterFirstNameErrorTooltipClose()
                                                    if(registerLastNameError) handleRegisterLastNameErrorTooltipClose()
                                                    if(registerPhoneError) handleRegisterPhoneErrorTooltipOpen()
                                                }}
                                            placeholder="Teléfono"
                                            type='number'
                                            value={registerPhone}
                                            onChange={handleChangeRegisterPhone}
                                            classes={{
                                                root: classes.inputRootLogin,
                                                input: classes.inputInputLoginPhone,
                                            }}
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                        {registerPhoneError &&
                                            <div>
                                            <MuiThemeProvider theme={themeMUI}>
                                                <Tooltip classes={{arrow: classes.arrow}}
                                                    arrow
                                                    PopperProps={{
                                                    disablePortal: true,
                                                    }}
                                                    onClose={handleRegisterPhoneErrorTooltipClose}
                                                    open={registerPhoneErrorOpen}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title={registerPhoneError}
                                                >
                                                    <IconButton onClick={handleRegisterPhoneErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                                        <WarningAmberIcon fontSize="small" />
                                                    </IconButton>
                                                    
                                                </Tooltip>
                                            </MuiThemeProvider>
                                            </div>                       
                                        }
                                    </div>
                                    <div className={classes.inputHolder}>
                                        <InputBase
                                            elevation={4}
                                            type={registerPasswordShow? "text" : "password"}
                                            onClick={() => 
                                                {
                                                    if(registerUserNameError) handleRegisterUserNameErrorTooltipClose()
                                                    if(registerEmailError) handleRegisterEmailErrorTooltipClose()
                                                    if(registerPasswordError) handleRegisterPasswordErrorTooltipOpen()
                                                    if(registerConfirmError) handleRegisterConfirmErrorTooltipClose()
                                                }}
                                            placeholder="Contraseña"
                                            value={registerPassword}
                                            onChange={handleChangeRegisterPassword}
                                            classes={{
                                                root: classes.inputRootLogin,
                                                input: classes.inputInputLogin,
                                            }}
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                        {registerPasswordError &&
                                            <div>
                                            <MuiThemeProvider theme={themeMUI}>
                                                <Tooltip classes={{arrow: classes.arrow}}
                                                    arrow
                                                    PopperProps={{
                                                    disablePortal: true,
                                                    }}
                                                    onClose={handleRegisterPasswordErrorTooltipClose}
                                                    open={registerPasswordErrorOpen}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title={registerPasswordError}
                                                >
                                                    <IconButton onClick={handleRegisterPasswordErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                                        <WarningAmberIcon fontSize="small" />
                                                    </IconButton>
                                                    
                                                </Tooltip>
                                            </MuiThemeProvider>
                                            </div>                       
                                        }
                                        {registerPasswordShow?
                                            <IconButton onClick={() => setRegisterPasswordShow(false)} aria-label="warning" className={classes.showButton}>
                                                <VisibilityOffIcon fontSize="small" />
                                            </IconButton>
                                        :
                                            <IconButton onClick={() => setRegisterPasswordShow(true)} aria-label="warning" className={classes.showButton}>
                                                <VisibilityOnIcon fontSize="small" />
                                            </IconButton>
                                        }
                                    </div>
                                    <div className={classes.inputHolder}>
                                        <InputBase
                                            elevation={4}
                                            type={registerConfirmShow? "text" : "password"}
                                            onClick={() => 
                                                {
                                                    if(registerUserNameError) handleRegisterUserNameErrorTooltipClose()
                                                    if(registerEmailError) handleRegisterEmailErrorTooltipClose()
                                                    if(registerPasswordError) handleRegisterPasswordErrorTooltipClose()
                                                    if(registerConfirmError) handleRegisterConfirmErrorTooltipOpen()
                                                }}
                                            placeholder="Confirmar"
                                            value={registerConfirm}
                                            onChange={handleChangeRegisterConfirm}
                                            classes={{
                                                root: classes.inputRootLogin,
                                                input: classes.inputInputLogin,
                                            }}
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                        {registerConfirmError &&
                                            <div>
                                            <MuiThemeProvider theme={themeMUI}>
                                                <Tooltip classes={{arrow: classes.arrow}}
                                                    arrow
                                                    PopperProps={{
                                                    disablePortal: true,
                                                    }}
                                                    onClose={handleRegisterConfirmErrorTooltipClose}
                                                    open={registerConfirmErrorOpen}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title={registerConfirmError}
                                                >
                                                    <IconButton onClick={handleRegisterConfirmErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                                        <WarningAmberIcon fontSize="small" />
                                                    </IconButton>
                                                    
                                                </Tooltip>
                                            </MuiThemeProvider>
                                            </div>                       
                                        }
                                        {registerConfirmShow?
                                            <IconButton onClick={() => setRegisterConfirmShow(false)} aria-label="warning" className={classes.showButton}>
                                                <VisibilityOffIcon fontSize="small" />
                                            </IconButton>
                                        :
                                            <IconButton onClick={() => setRegisterConfirmShow(true)} aria-label="warning" className={classes.showButton}>
                                                <VisibilityOnIcon fontSize="small" />
                                            </IconButton>
                                        }
                                    </div>
                                    {emailConfirming&&
                                    <div>
                                        <Typography className={classes.stepperSecondaryTextRegister}>
                                            Introduce el código que te hemos enviado a tu correo.
                                        </Typography>
                                        <div style={{display:'flex'}}>
                                            <div style={{marginLeft: 'auto'}} className={classes.inputVerificateHolder}>
                                                <InputBase
                                                    elevation={4}
                                                    onClick={() => 
                                                        {
                                                            if(verificateError) setVerificateError("")
                                                            if(setCodeResended) setCodeResended(false)
                                                            setVerificate1("")
                                                        }}
                                                    type="number"
                                                    name='ver-1'
                                                    value={verificate1}
                                                    onChange={handleChangeVerificate1}
                                                    classes={{
                                                        root: classes.inputRootVerificate,
                                                        input: classes.inputInputVerificate,
                                                    }}
                                                    inputProps={{ 'aria-label': 'search', maxLength:'1' }}
                                                />
                                            </div>
                                            <div className={classes.inputVerificateHolder}>
                                                <InputBase
                                                    elevation={4}
                                                    onClick={() => 
                                                        {
                                                            if(verificateError) setVerificateError("")
                                                            if(setCodeResended) setCodeResended(false)
                                                            setVerificate2("")
                                                        }}
                                                    type="number"
                                                    name='ver-2'
                                                    value={verificate2}
                                                    onChange={handleChangeVerificate2}
                                                    classes={{
                                                        root: classes.inputRootVerificate,
                                                        input: classes.inputInputVerificate,
                                                    }}
                                                    inputProps={{ 'aria-label': 'search', maxLength:'1' }}
                                                />
                                            </div>
                                            <div className={classes.inputVerificateHolder}>
                                                <InputBase
                                                    elevation={4}
                                                    onClick={() => 
                                                        {
                                                            if(verificateError) setVerificateError("")
                                                            if(setCodeResended) setCodeResended(false)
                                                            setVerificate3("")
                                                        }}
                                                    type="number"
                                                    name='ver-3'
                                                    value={verificate3}
                                                    maxLength={1}
                                                    onChange={handleChangeVerificate3}
                                                    classes={{
                                                        root: classes.inputRootVerificate,
                                                        input: classes.inputInputVerificate,
                                                    }}
                                                    inputProps={{ 'aria-label': 'search', maxLength:'1' }}
                                                />
                                            </div>
                                            <div style={{marginRight: 'auto'}} className={classes.inputVerificateHolder}>
                                                <InputBase
                                                    elevation={4}
                                                    onClick={() => 
                                                        {
                                                            if(verificateError) setVerificateError("")
                                                            if(setCodeResended) setCodeResended(false)
                                                            setVerificate4("")
                                                        }}
                                                    type="number"
                                                    name='ver-4'
                                                    value={verificate4}
                                                    onChange={handleChangeVerificate4}
                                                    classes={{
                                                        root: classes.inputRootVerificate,
                                                        input: classes.inputInputVerificate,
                                                    }}
                                                    inputProps={{ 'aria-label': 'search', maxLength:'1' }}
                                                />
                                            </div>
                                        </div>
                                        {verificateError &&
                                            <Typography className={classes.verificateErrorText}>
                                                {verificateError}
                                            </Typography>                
                                        }
                                        {reSendingCode &&
                                            <CircularProgress size={24} style={{display: 'block', margin: '10px auto 0px auto', color: colors.primary}}/>
                                        }
                                        {codeResended &&
                                            <Typography className={classes.codeResendedText}>
                                                Código Reenviado Correctamente. Verifique su correo.<br /> Si no lo recibe 
                                                escriba un mensaje<br/> a nuestro soporte via <a style={{textDecoration: 'underline', color: 'red'}} href={mailToSupportEmail}>correo</a> o <a style={{textDecoration: 'underline', color: 'green'}} target="_blank" href={`https://wa.me/${supportNumber}?text=${encodeURI("Hola, tengo una pregunta.")}`}>whatsap</a> 
                                            </Typography>
                                        }
                                        <Typography className={classes.stepperTerciaryText}
                                            onClick={ResendRegisterCode}>
                                            No has recibido ningún código? <Link style={{textDecoration: 'underline', color: 'rgba(0, 0, 0, 0.74)'}}>REENVIAR</Link>
                                        </Typography>
                                        </div>
                                    }
                                    {registerLoading && <CircularProgress size={30} style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', color: colors.primary}}/>}
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        className={classes.primaryButton}
                                        classes={{
                                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                                        }}
                                    >Continuar</Button>
                                </form>
                                <Typography style={{textAlign: 'center', width: '80%', color: 'rgba(0, 0, 0, 0.74)', marginLeft: '10%', maxWidth: '400px'}}>
                                    Registrándose acepta nuestros <Link to='legalinformation' href="#" target="_blank" style={{textDecoration: 'underline', color: 'rgba(0, 0, 0, 0.74)'}}>Términos y Condiciones</Link> y nuestras <Link to='privacypolicy' href="#" target="_blank" style={{textDecoration: 'underline', color: 'rgba(0, 0, 0, 0.74)'}}>Politicas de Privacidad</Link>
                                </Typography>
                                <Typography style={{ marginTop: '30px', textAlign: 'center', width: '80%', color: 'rgba(0, 0, 0, 0.74)', marginLeft: '10%'}}>
                                    Ya tiene una cuenta de usuario?
                                </Typography>
                                <Button
                                        variant="outline"
                                        onClick={() => {
                                                        setLogin(true)
                                                        setVerificate1("")
                                                        setVerificate2("")
                                                        setVerificate3("")
                                                        setVerificate4("")
                                                        setResendingCode(false)
                                                        setCodeResended(false)
                                                        setSendingCode(false)
                                                        setVerificateError("")
                                                        setCode("")
                                                    }}
                                        className={classes.secondaryButton}
                                        classes={{
                                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                                        }}
                                >Iniciar Sesión</Button>
                            </div>
                        }
                    </DialogContentText>
                    }
                    
                </DialogContent>
                
            </Dialog>
        </div>
    )
}