import React, { useLayoutEffect } from "react";
import { useState, useEffect } from "react";
import NoAccess from "../global/NoAccess";
import Navbar from "../global/NavbarGlobal";
import Footer from "../global/Footer";
import { useSelector } from "react-redux";
import { CircularProgress, Typography, alpha, makeStyles } from "@material-ui/core";
import url, { colors } from "../../config";
import dashboard_1 from '../../assets/img/dashboard_1.jpg'
import dashboard_2 from '../../assets/img/dashboard_2.jpg'
import dashboard_3 from '../../assets/img/dashboard_3.jpg'
import { Legend, Line, LineChart, ResponsiveContainer } from "recharts";
import { useHistory } from "react-router-dom";
import reduceDecimalValue from "../../tools/productTools";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        backgroundColor: colors.whiteAppbar,
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up('xl')]: {
          maxWidth: '1140px',
        },
        [theme.breakpoints.down('md')]: {
          marginLeft: '40px',
          marginRight: '40px',
        },
        [theme.breakpoints.down('sm')]: {
          marginLeft: '35px',
          marginRight: '35px',
        },
        [theme.breakpoints.down('xs')]: {
          marginLeft: '10px',
          marginRight: '10px',
          marginTop: '10px',
        },
    },
    sellDashboardText: {
        textAlign: 'center',
        fontSize: '30px',
        fontWeight: '700',
        color: colors.secondary,
        paddingTop: '25px',
        paddingBottom: '25px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '25px',
            fontWeight: '700',
            color: colors.secondary,
            paddingTop: '0px',
            paddingBottom: '10px',
        },
    },
    optionMainText: {
        textAlign: 'center',
        fontSize: '26px',
        fontWeight: '600',
        color: colors.whiteAppbar,
    },
    mainOptionsHolder: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: '40px',
        marginRight: '40px',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            marginLeft: '20px',
            marginRight: '20px'
        },
    },
    "@keyframes enter": { 
        "0%": {
          opacity: '0'
        },
        "100%": {
          opacity: '1'
        }
    },
    optionHolder: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        flex: '1',
        marginLeft: '20px',
        marginRight: '20px',
        aspectRatio: '1 / 1',
        backgroundColor: colors.secondary,
        transition: '0.3s',
        // animation: '$enter  350ms ease-in',
        [theme.breakpoints.down('md')]: {
            marginLeft: '10px',
            marginRight: '10px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: '20px',
            marginLeft: '10px',
            marginRight: '10px',
        },
        '&:hover': {
            backgroundColor: colors.primary,
            cursor: 'pointer',
            "& $optionSecondaryText": {
                backgroundColor: alpha(colors.cartBlueBackground, 0.8),
                color: colors.secondary
            }
        },
    },
    optionSecondaryText: {
        transition: '0.3s',
        position: 'absolute',
        width: '100%',
        fontSize: '12px',
        textAlign: 'center',
        bottom: 0,
        paddingBottom: '15px',
        paddingTop: '15px',
        backgroundColor: alpha(colors.secondary, 0.8),
        color: colors.whiteAppbar
    },
    dashboardImage: {
        width: '100%',
        height: '100%',
        opacity: '0.15',
        position: "absolute"
    },
    hideOnlySm: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'column'
        }
    },
    showOnlySm: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    },
    chartInfoHolder: {
        display: 'flex',
        marginTop: '40px',
        marginLeft: '60px',
        marginRight: '60px',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            marginTop: '20px'
        }
    },
    chartInfoMainText: {
        textAlign: 'center',
        fontSize: '24px',
        fontWeight: '700',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left'
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        },
    },
    chartInfoSecondaryText: {
        textAlign: 'center',
        fontSize: '16px',
        fontWeight: '500',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left'
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        },
    },
    sellInfoHolder: {
        justifyContent: 'center',
        display: 'flex',
        marginTop: '20px', 
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
        }
    },
    infoHolder: {
        flex: 1
    },
    chartHolder: {
        flex: 1,
        paddingLeft: '5px',
        paddingRight: '5px',
        marginLeft: '20px'
    },
    sellInfoLeftText: {
        fontSize: '19px',
        fontWeight: '600'
    },
    sellInfoRightText: {
        fontSize: '19px',
        fontWeight: '700'
    },

    sellInfoLeftTextProducts: {
        fontSize: '17px',
        fontWeight: '600', 
        textAlign: 'right'
    },
    sellInfoRightTextProducts: {
        fontSize: '17px',
        fontWeight: '700',
        textAlign: 'left'
    }
}))

  

export default function SellDashboardPage({props, children}){
    const classes  = useStyles();
    const history = useHistory()
    const [noAccess, setNoAccess] = useState(false);
    const userLogged = useSelector(state => state.user_store.user)
    const accessToken = useSelector(state => state.user_store.accessToken)
    const [widthSize, setWidthSize] = useState()
    const [sellInfo, setSellInfo] = useState({totalSells: 0, totalIncomes: 0, totalProfits: 0, loading: true, bestSellers: [], bestEarnings: []})

    useEffect(() => {
        if(!accessToken){
            setNoAccess(true)
        }
        else{
            if(userLogged && !userLogged.isImportCompany){
                setNoAccess(true)
            }
        }
    }, [userLogged])

    useEffect(() => {
        const getSellingData = async () => {
            if(accessToken){
                const requestOptions = {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json',
                                'Authorization': `${accessToken}`
                    },
                };
                let result = await fetch(`${url}/api/sellingData/getUserSellingData`, requestOptions);
                if(result.status === 200){
                    const data = await result.json()
                    setSellInfo({
                        totalSells: reduceDecimalValue(data.totalSells, 0, false),
                        totalIncomes: reduceDecimalValue(data.totalIncomes, 2, false),
                        totalProfits: reduceDecimalValue(data.totalProfits, 2, false),
                        loading: false,
                        bestSellers: data.bestSellers,
                        bestEarnings: data.bestEarnings,
                    })
                }
            }
        }
        getSellingData()
    }, [userLogged])

    useLayoutEffect(() => {
        function updateSize() {
          setWidthSize([window.innerWidth]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    
    const setAccess = () => {
        setNoAccess(false)
    }

    const toCreateProductPage = (event) => {
        history.push("/myproducts/create")
        window.scrollTo(0,0)
    }

    const toMyProductsPage = (event) => {
        history.push("/myproducts")
        window.scrollTo(0,0)
    }
    const toMyDeliversPage = (event) => {
        history.push(`/delivers/${userLogged._id}`)
        window.scrollTo(0,0)
    }
    
    return (
        <React.Fragment>
            <Navbar setAccess={setAccess} sellPage/>
            {noAccess?
                <NoAccess />
            :
                <React.Fragment>
                    <div style={{backgroundColor: colors.cartBlueBackground, minHeight: '75vh'}}>
                        <div className={classes.mainContainer}>
                            <Typography className={classes.sellDashboardText}>DASHBOARD DE VENTA</Typography>
                            <div className={classes.mainOptionsHolder}>
                                <div onClick={toCreateProductPage} className={classes.optionHolder}>
                                    <img src={dashboard_1} className={classes.dashboardImage}/>
                                    <Typography className={classes.optionMainText}>VENDER</Typography>
                                    <Typography className={classes.optionSecondaryText}>{widthSize < 1050? "VENDE EN SOLO DOS PASOS" : "COMIENZA A VENDER EN SOLO DOS PASOS"}</Typography>
                                </div>
                                <div onClick={toMyProductsPage} className={classes.optionHolder}>
                                    <img src={dashboard_2} className={classes.dashboardImage}/>
                                    <Typography className={classes.optionMainText}>MIS ANUNCIOS</Typography>
                                    <Typography className={classes.optionSecondaryText}>LISTA DE PRODUCTOS EN VENTA</Typography>
                                </div>
                                <div onClick={toMyDeliversPage} className={`${classes.optionHolder} ${classes.hideOnlySm}`}>
                                    <img src={dashboard_3} className={classes.dashboardImage}/>
                                    <Typography className={classes.optionMainText}>MIS VENTAS</Typography>
                                    <Typography className={classes.optionSecondaryText}>LISTA DE ENTREGAS</Typography>
                                </div>
                            </div>
                            <div className={`${classes.mainOptionsHolder} ${classes.showOnlySm}`} style={{marginTop: '20px'}}>
                                <div onClick={toMyDeliversPage} className={`${classes.optionHolder}`}>
                                    <img src={dashboard_3} className={classes.dashboardImage}/>
                                    <Typography className={classes.optionMainText}>MIS VENTAS</Typography>
                                    <Typography className={classes.optionSecondaryText}>LISTA DE ENTREGAS</Typography>
                                </div>
                                <div className={classes.infoHolder} style={{flex: '1', marginLeft: '10px', marginRight: '10px'}}>
                                    <Typography className={classes.chartInfoMainText} style={{paddingLeft: '10px', paddingRight: '10px'}}>CONTABILIDAD</Typography>
                                    <Typography className={classes.chartInfoSecondaryText} style={{paddingLeft: '10px', paddingRight: '10px'}}>REPORTE DE VENTAS EN SEVENDEIMPORT</Typography>
                                    <div className={`${classes.sellInfoHolder}`} style={{paddingLeft: '10px', paddingRight: '10px'}}>
                                        <div>
                                            <Typography className={classes.sellInfoLeftText}>VENTA</Typography>
                                            <Typography className={classes.sellInfoLeftText}>INGRESO</Typography>
                                            {/* <Typography className={classes.sellInfoLeftText}>GANANCIAS</Typography> */}
                                        </div>
                                        <div style={{marginLeft: '20px'}}>
                                            <Typography className={classes.sellInfoRightText}>{sellInfo.loading? <CircularProgress style={{width: '15px', height: '15px', color: colors.secondary, marginBottom: '1px'}}/> : sellInfo.totalSells}</Typography>
                                            <Typography className={classes.sellInfoRightText}>{sellInfo.loading? <CircularProgress style={{width: '15px', height: '15px', color: colors.secondary, marginBottom: '1px'}}/> : `$${sellInfo.totalProfits}`}</Typography>
                                            {/* <Typography className={classes.sellInfoRightText}>{sellInfo.loading? <CircularProgress style={{width: '15px', height: '15px', color: colors.secondary, marginBottom: '1px'}}/> : `$${sellInfo.totalProfits}`}</Typography> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.chartInfoHolder} style={{paddingBottom: '20px'}}>
                                <div className={`${classes.infoHolder} ${classes.hideOnlySm}`}>
                                    <Typography className={classes.chartInfoMainText}>CONTABILIDAD</Typography>
                                    <Typography className={classes.chartInfoSecondaryText}>REPORTE DE VENTAS EN SEVENDEIMPORT</Typography>
                                    <div className={`${classes.sellInfoHolder}`}>
                                        <div>
                                            <Typography className={classes.sellInfoLeftText}>VENTAS</Typography>
                                            <Typography className={classes.sellInfoLeftText}>INGRESO</Typography>
                                            {/* <Typography className={classes.sellInfoLeftText}>GANANCIAS</Typography> */}
                                        </div>
                                        <div style={{marginLeft: '20px'}}>
                                            <Typography className={classes.sellInfoRightText}>{sellInfo.loading? <CircularProgress style={{width: '15px', height: '15px', color: colors.secondary, marginBottom: '1px'}}/> : sellInfo.totalSells}</Typography>
                                            <Typography className={classes.sellInfoRightText}>{sellInfo.loading? <CircularProgress style={{width: '15px', height: '15px', color: colors.secondary, marginBottom: '1px'}}/> : `$${sellInfo.totalProfits}`}</Typography>
                                            {/* <Typography className={classes.sellInfoRightText}>{sellInfo.loading? <CircularProgress style={{width: '15px', height: '15px', color: colors.secondary, marginBottom: '1px'}}/> : `$${sellInfo.totalProfits}`}</Typography> */}
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            {sellInfo.bestSellers.length > 0 &&
                            <div className={classes.chartInfoHolder} style={{paddingBottom: '20px'}}>
                                <div className={`${classes.infoHolder}`}>
                                    <Typography className={classes.chartInfoMainText}>ESTADISTICAS POR PRODUCTOS</Typography>
                                    <Typography className={classes.chartInfoSecondaryText} style={{fontWeight: '700'}}>TOP 5 MAS VENDIDOS</Typography>
                                    {sellInfo.bestSellers.map((element, index) => {
                                        return (
                                            <div key={index} className={`${classes.sellInfoHolder}`}>
                                                <div style={{width: '50%'}}>
                                                    <Typography className={classes.sellInfoLeftTextProducts}>{element.name.toUpperCase()}</Typography>
                                                </div>
                                                <div style={{marginLeft: '20px'}}>
                                                    <Typography className={classes.sellInfoRightText}>{sellInfo.loading? <CircularProgress style={{width: '15px', height: '15px', color: colors.secondary, marginBottom: '1px'}}/> : element.salesCount}</Typography>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <Typography className={classes.chartInfoSecondaryText} style={{marginTop: '50px', fontWeight: '700'}}>TOP 5 MAS INGRESO</Typography>
                                    {sellInfo.bestEarnings.map((element, index) => {
                                        return (
                                            <div key={index} className={`${classes.sellInfoHolder}`}>
                                                <div style={{width: '50%'}}>
                                                    <Typography className={classes.sellInfoLeftTextProducts}>{element.name.toUpperCase()}</Typography>
                                                </div>
                                                <div style={{marginLeft: '20px'}}>
                                                    <Typography className={classes.sellInfoRightText}>{sellInfo.loading? <CircularProgress style={{width: '15px', height: '15px', color: colors.secondary, marginBottom: '1px'}}/> : `$${element?.incomesStats?.profits}`}</Typography>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                
                            </div>
                            }
                        </div>
                    </div>
                    <Footer /> 
                </React.Fragment>
            }
        </React.Fragment>
    )
}