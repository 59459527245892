import React from "react";
import { useState, useEffect } from "react";
import url, { colors } from "../../config";
import { makeStyles, Typography } from "@material-ui/core";
import { alpha } from "@material-ui/core";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Skeleton } from "@material-ui/lab";
import NoConnection from "../global/NoConnection";
import { v4 as uuidv4 } from 'uuid'
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../global/NavbarGlobal";
import ProductImageContainer from "../pagesComponents/ProductDetailPage/ProductImageContainer";
import ProductNameAndDescriptionContainer from "../pagesComponents/ProductDetailPage/ProductNameAndDescriptionContainer";
import { ProductsDetailPageProductList } from "../pagesComponents/ProductDetailPage/ProductsDetailPageProductList";
import Footer from "../global/Footer";
import ProductInfoContainer from "../pagesComponents/ProductDetailPage/ProductInfoContainer";
import { ArrowBackIos } from "@material-ui/icons";
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    mainContainer: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '30px',
      [theme.breakpoints.up('xl')]: {
        maxWidth: '1140px',
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: '40px',
        marginRight: '40px',
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '35px',
        marginRight: '35px',
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: '10px',
        marginRight: '10px',
        marginTop: '10px',
      },
    },
    categoryHolder: {
      backgroundColor: colors.secondary,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    backArrow: {
      height: '27px',
      width: '27px',
      minWidth: '27px',
      backgroundColor: colors.whiteAppbar,
      borderRadius: '50%',
      margin: '5px 10px 5px 0px',
      cursor: 'pointer',
      color: colors.secondary,
      [theme.breakpoints.down('xs')]: {
        marginLeft: '20px',
        marginRight: '20px'
      },
      '&:hover': {
        color: colors.whiteAppbar,
        backgroundColor: colors.primary
      }
    },
    categorySkeleton: {
      width: '180px',
      backgroundColor: colors.whiteAppbar,
      borderRadius: '3px'
    },
    categoryNameText: {
      color: colors.whiteAppbar,
      fontWeight: '700',
      fontSize: '22px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '18px',
        lineHeight: '22px',
        paddingBottom: '5px',
        paddingTop: '5px',
        marginRight: '20px'
      }
    },
    imageAndProductInfoHolder: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    imageHolder: {
      marginLeft: '10px'
    },
    nameAndDescriptionHolder: {
      width: '400px',
      [theme.breakpoints.down('md')]: {
        display: 'none'
      },
      [theme.breakpoints.down('xs')]: {
        display: 'inherit',
        marginTop: '10px',
        marginLeft: '10px',
        marginRight: '10px',
        width: 'inherit'
      },
    },
    nameAndDescriptionHolderMd: {
      display: 'none',
      [theme.breakpoints.down('md')]: {
        display: 'inherit',
        marginTop: '10px'
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      },
    },
    nameAndDescriptionHolderSm: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'inherit',
        margin: '10px 10px',
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    productInfoHolder: {
      width: '200px', 
      marginRight: '20px',
      [theme.breakpoints.down('md')]: {
        width: '0px',
        flex: '0.6'
      },
      [theme.breakpoints.down('sm')]: {
        width: '0px',
        flex: '0.5',
      },
      [theme.breakpoints.down('xs')]: {
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '12px'
      }
    },
    sameCategoryProductsText: {
      fontSize: '28px',
      color: colors.secondary,
      marginTop: '40px',
      marginBottom: '10px',
      fontWeight: '800',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: '28px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '28px',
        lineHeight: '28px'
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px',
        lineHeight: '20px'
      }
    }
}))

  

 const ProductDetailPage = () => {
    const classes  = useStyles();
    const {id} = useParams();
    const [imageUrls, setImageUrls] = useState([])
    const [apiError, setApiError] = useState(false);
    const [product, setProduct] = useState(null);
    const history = useHistory()

    const accessToken = useSelector((state) => state.user_store.accessToken)
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchProduct = async () => {
            try{
              let requestOptions = {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json'
                  }
                };
              let urlGetProduct = `${url}/api/productsImport/${id}`
              if(accessToken){
                requestOptions = {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `${accessToken}` }
                };
              }
              else{
                const isSession = window.localStorage.getItem('session')
                if(isSession){
                  const session = JSON.parse(isSession);
                  urlGetProduct += `?sessionId=${session._id}`
                }
                else{
                  const newSessionId = uuidv4()
                  window.localStorage.setItem(
                    'session', JSON.stringify({_id: newSessionId})
                  )
                  urlGetProduct += `?sessionId=${newSessionId}`
                }
              }
              let result = await fetch(urlGetProduct, requestOptions)
              if(result.status === 200){
                let newData = await result.json();
                const urls = [];
                if (newData.mainImageUrl) urls.push({index: 0, url:newData.mainImageUrl});
                if (newData.secondaryImagesUrl) {
                  let i = 1
                  newData.secondaryImagesUrl.forEach(element => {
                    if(element) urls.push({index: i, url:element})
                    i += 1
                  });
                }
                setImageUrls(urls);
                setProduct(newData);
              }
              else{
                setApiError(true)
              }
            }catch(error){
                setApiError(true);
            }
        }
        fetchProduct()
    }, [])

    const backArrowOnClick = () => {
      history.goBack()
    }

    return (
      <React.Fragment>
        <Navbar />
        {apiError?
          <NoConnection />
        :
        <React.Fragment>
          <div className={classes.categoryHolder}>
            <div onClick={backArrowOnClick} className={classes.backArrow}>
              <ArrowBackIos style={{marginLeft: '6.8px', height: '22px', width: '22px', marginTop: '2px', color: "inherit"}}/>
            </div>
            {product?
              <Typography className={classes.categoryNameText}>{product.category.name.toUpperCase()}</Typography>
            :
              <Skeleton className={classes.categorySkeleton}
                variant='rect' height='20px'/>
            }
          </div>
          <div className={classes.mainContainer}>
            <div className={classes.imageAndProductInfoHolder}>
              <div className={classes.imageHolder} style={{flex: 1}}>
                <ProductImageContainer imageUrls={imageUrls}/>
              </div>
              <div className={classes.nameAndDescriptionHolder}>
                <ProductNameAndDescriptionContainer name={product?.name} description={product?.description}/>
              </div>
              <div className={classes.productInfoHolder}>
                <ProductInfoContainer product={product} />
                <div className={classes.nameAndDescriptionHolderMd}>
                  <ProductNameAndDescriptionContainer name={product?.name} description={product?.description}/>
                </div>
              </div>
            </div>
            <div className={classes.nameAndDescriptionHolderSm}>
              <ProductNameAndDescriptionContainer name={product?.name} description={product?.description}/>
            </div>
            <div>
              <Typography className={classes.sameCategoryProductsText}>ANUNCIOS DE LA MISMA CATEGORIA</Typography>
            </div>
            
          </div>
          <ProductsDetailPageProductList categoryId={product?.category._id} productId={product?._id}/>
          <div style={{marginTop: '20px'}}>
            <Footer />
          </div>
        </React.Fragment>
        }
      </React.Fragment>
    )
}

export default ProductDetailPage