import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import url from '../../config'
import ProductCard from './ProductCard'
import SkeletonCard from './SkeletonCard'

export default function ProductFavoriteCard({product, isFavorite, fromFavorites, userId, updateFromFavorites}){
    const [loading, setLoading] = useState(true)
    const [productUpdate, setProductUpdate] = useState()
    const accessToken = useSelector((state) => state.user_store.accessToken)

    useEffect(() => {
        const getProduct = async () => {
            try{
                let requestOptions = {
                  method: 'GET',
                  headers: { 
                      'Content-Type': 'application/json',
                      'Authorization': `${accessToken}`
                    }
                  };
                let urlGetProduct = `${url}/api/productsImport/${product._id}`
                let result = await fetch(urlGetProduct, requestOptions)
                if(result.status === 200){
                  let newData = await result.json();
                  if(newData){
                    setProductUpdate(newData)
                  }
                  else{
                    let productErased = {
                      ...product,
                      available: false
                    }
                    setProductUpdate(productErased)
                  }
                  setLoading(false);
                }
                else{
                }
              }catch(error){
              }
        }
        getProduct()
    },[])

    return(
        <React.Fragment>
            {loading?
                <SkeletonCard />
            :
                <ProductCard 
                    product={productUpdate}
                    isFavorite={isFavorite}
                    fromFavorites={fromFavorites}
                    userId={userId}
                    updateFromFavorites={updateFromFavorites}
                    />
            }
        </React.Fragment>
    )
}