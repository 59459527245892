import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import no_search from '../../assets/img/search_not_found.svg';
import no_search_secondary from '../../assets/img/search_not_found_secondary.svg'
import { colors, supportNumber } from '../../config';


const useStyles = makeStyles((theme) => ({
    holder: {
        width: '100%',
        marginTop: '60px'
    },
    image: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width:'auto',
        height: '160px',
    },
    mainText: {
        marginTop: '60px',
        textAlign: 'center',
        fontSize: '18px',
        fontWeight: '600'
    },
    secondaryText: {
        marginTop: '5px',
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: '400'
    },
    primaryButton: {
        backgroundColor: colors.whiteAppbar,
        border: `solid 2px ${colors.primary}`,
        color: colors.primary,
        marginTop: '10px',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
        '&:hover': {
            backgroundColor: '#ebebeb'
        }
    }
}))

  export default function NoSearch() {
    const classes  = useStyles()
    
    const requestNewProductOnClick = () => {
        const textMessage = `Hola, me gustaria solicitar un nuevo producto`
        const whatsapUrl = `https://wa.me/${supportNumber}?text=${encodeURI(textMessage)}`
        window.open(whatsapUrl)
    }

    return(
        <div className={classes.holder}>
            <img src={no_search_secondary} className={classes.image}/>
            <Typography className={classes.mainText}>
                NO ENCONTRAMOS RESULTADOS :(
            </Typography>
            <Typography className={classes.secondaryText}>
                VUELVA A INTENTARLO CON OTRA PALABRA O FRASE, CAMBIANDO LOS PARAMETROS DEL FILTRO
            </Typography>
            {/* <Button onClick={requestNewProductOnClick} variant='contained' className={classes.primaryButton}>
                Solicite un nuevo producto
            </Button> */}
        </div>
    )
}