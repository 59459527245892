const check200FamilyStatus = (statusCode) => {
    if(
        statusCode === 200 ||
        statusCode === 201 ||
        statusCode === 202 ||
        statusCode === 203 ||
        statusCode === 204 ||
        statusCode === 205 ||
        statusCode === 206 ||
        statusCode === 207 ||
        statusCode === 208 ||
        statusCode === 226
    ) return true
    return false
}

export { check200FamilyStatus }