import { makeStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import React, {useState, memo} from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { colors } from '../../config'
import '../../assets/css/layout.css'
import {HomePageProductList} from '../pagesComponents/HomePage/HomePageProductList';
import { Collapse, IconButton, InputBase, Tooltip } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { connect, useDispatch, useSelector } from 'react-redux'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PromotionalBanner from '../pagesComponents/HomePage/PromotionalBanner';
import ColaborationBanner from '../pagesComponents/HomePage/ColaborationBanner';
import TopSellBanner from '../pagesComponents/HomePage/TopSellBanner';
import Navbar from '../global/NavbarGlobal';
import { update_products_action, update_search_params_action } from '../../redux/actions/productsAction';
import { AttachMoney, Score } from '@material-ui/icons';
import WarningAmberIcon from '@material-ui/icons/PriorityHigh'
import CategoryBanner from '../pagesComponents/HomePage/CategoryBanner';
import RequestProductBanner from '../pagesComponents/HomePage/RequestProductBanner';

const themeMUI = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          color: "white",
          backgroundColor: "rgba(251,82,100,0.85)",
          borderTop: 'solid 2px rgb(251,82,100)'
        }
      }
    }
  });

const useStyles = makeStyles((theme) => ({
    listText: {
        fontWeight: '700',
        letterSpacing: '6px',
        fontSize: '13px',
        textAlign: 'center'
    },
    homePageContainers: {
        // [theme.breakpoints.up('lg')]:{
        //     marginLeft: 'auto',
        //     marginRight: 'auto',
        // },
        // [theme.breakpoints.down('md')]: {
        //     paddingLeft: '252px',
        //     paddingRight: '0px',
        //     marginRight: '10px',
        // },
        // [theme.breakpoints.down('sm')]: {
        //     paddingLeft: '0px',
        //     marginLeft: '10px'
        // },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '40px'
        },
    },
    inputHolder: {
        display: 'flex',
        width: '55px',
        marginLeft: '5px',
        backgroundColor:colors.whiteAppbar, 
        height: '23px', 
        borderRadius: '3px'
    },
    filterPriceAndPoints: {
        backgroundColor: colors.secondary,
        marginLeft: 'auto',
        display: 'flex',
        paddingTop: '7px',
        paddingBottom: '7px'
    },
    filterPrice: {
        display:'flex', 
        alignItems: 'center', 
        marginRight: '10px', 
        marginLeft: 'auto'
    },
    filterPoints: {
        display:'flex', 
        alignItems: 'center', 
        marginRight: 'auto'
    },
    filterIcon:{
        height: '18px',
        color: colors.whiteAppbar,
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    filterText: {
        fontSize: '11px', 
        color: colors.whiteAppbar,
        marginRight: '0px'
    },
    inputRootLogin: {
        width: '100%',
    },
    inputInputLogin: {
        height: 22,
        fontSize: '11px',
        fontWeight: 400,
        transition: theme.transitions.create('width'),
        width: '100%',
        paddingLeft: '10px',
        paddingRight: '10px',
        color: colors.secondary,
        '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: colors.secondary,
            textAlign: 'center',
            opacity: 1 /* Firefox */
          },
          
          '&:-ms-input-placeholder': { /* Internet Explorer 10-11 */
            color: colors.secondary,
            textAlign: 'center',
          },
          
          '&::-ms-input-placeholder': { /* Microsoft Edge */
            color: colors.secondary,
            textAlign: 'center',
          },
    },
    topSellText: {
        fontSize: '58px',
        fontWeight: '800',
        textAlign: 'center',
        marginBottom: '-15px',
        marginTop: '0px',
        fontFamily: '"Nerko One", cursive !important',
        color: colors.secondary,
        [theme.breakpoints.down('lg')]: {
            fontSize: '42px',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '36px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '32px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '22px',
            paddingBottom: '5px',
            marginTop: '15px',
        }
    },
}))
  
  // One item component
  // selected prop will be passed
  
  // All items component
  // Important! add unique key
  

const HomePage = ({registerPromotion}) => {
    const classes  = useStyles()
    const [showMainContainer, setShowMainContainer] = useState(true)

    const [minPriceError, setMinPriceError] = useState("")
    const [minPriceErrorOpen, setMinPriceErrorOpen] = useState(false)
    const [maxPriceError, setMaxPriceError] = useState("")
    const [maxPriceErrorOpen, setMaxPriceErrorOpen] = useState(false)
    const [minPointsError, setMinPointsError] = useState("")
    const [minPointsErrorOpen, setMinPointsErrorOpen] = useState(false)
    const [maxPointsError, setMaxPointsError] = useState("")
    const [maxPointsErrorOpen, setMaxPointsErrorOpen] = useState(false)

    const dispatch = useDispatch()
    const searchParamsToExecute = useSelector((state) => state.products_store.searchParameters)

    // #region UseEffect
    React.useEffect(() => {
        if(searchParamsToExecute.selected !== "0") {
            setShowMainContainer(false)
            return
        }
        if(searchParamsToExecute.searchPhrase !== "") {
            setShowMainContainer(false)
            return
        }
        if(searchParamsToExecute.minPrice !== "") {
            setShowMainContainer(false)
            return
        }
        if(searchParamsToExecute.maxPrice !== "") {
            setShowMainContainer(false)
            return
        }
        setShowMainContainer(true)
    }, [searchParamsToExecute]);

    const handleChangeMinPoints = (event) => {
        if(minPointsError){
            setMinPointsError("")
            handleMinPointsErrorTooltipClose()
        }
        dispatch(update_products_action([], 0, false))
        let params = {...searchParamsToExecute}
        params.page = 1
        params.minPoints = event.target.value
        params.onBackPressed = false
        dispatch(update_search_params_action(params))
        if(event.target.value){
            if(!parseInt(event.target.value)){
                setMinPointsError('Los puntos Mininimos deben ser un numero')
                handleMinPointsErrorTooltipOpen()
            }
        }
    };
    const handleChangeMaxPoints = (event) => {
        if(maxPointsError){
            setMaxPointsError("")
            handleMaxPointsErrorTooltipClose()
        }
        dispatch(update_products_action([], 0, false))
        let params = {...searchParamsToExecute}
        params.page = 1
        params.maxPoints = event.target.value
        params.onBackPressed = false
        dispatch(update_search_params_action(params))
        if(event.target.value){
            if(!parseInt(event.target.value)){
                setMaxPointsError('Los puntos Maximos deben ser un numero')
                handleMaxPointsErrorTooltipOpen()
            }
        }
    };
    const handleChangeMinPrice = (event) => {
        if(minPriceError){
            setMinPriceError("")
            handleMinPriceErrorTooltipClose()
        }
        dispatch(update_products_action([], 0, false))
        let params = {...searchParamsToExecute}
        params.page = 1
        params.minPrice = event.target.value
        params.onBackPressed = false
        dispatch(update_search_params_action(params))
        if(event.target.value){
            if(!parseInt(event.target.value)){
                setMinPriceError('El Precio debe ser un numero')
                handleMinPriceErrorTooltipOpen()
            }
        }
    };
    const handleChangeMaxPrice = (event) => {
        if(maxPriceError){
            setMaxPriceError("")
            handleMaxPriceErrorTooltipClose()
        }
        dispatch(update_products_action([], 0, false))
        let params = {...searchParamsToExecute}
        params.page = 1
        params.maxPrice = event.target.value
        params.onBackPressed = false
        dispatch(update_search_params_action(params))
        if(event.target.value){
            if(!parseInt(event.target.value)){
                setMaxPriceError('El Precio debe ser un numero')
                handleMaxPriceErrorTooltipOpen()
            }
        }
    };

    const handleMinPriceErrorTooltipClose = () => {
        setMinPriceErrorOpen(false);
    };
    const handleMaxPriceErrorTooltipClose = () => {
        setMaxPriceErrorOpen(false);
    };
    const handleMinPriceErrorTooltipOpen = () => {
        setMinPriceErrorOpen(true);
    };
    const handleMaxPriceErrorTooltipOpen = () => {
        setMaxPriceErrorOpen(true);
    };

    const handleMinPointsErrorTooltipClose = () => {
        setMinPointsErrorOpen(false);
    };
    const handleMaxPointsErrorTooltipClose = () => {
        setMaxPointsErrorOpen(false);
    };
    const handleMinPointsErrorTooltipOpen = () => {
        setMinPointsErrorOpen(true);
    };
    const handleMaxPointsErrorTooltipOpen = () => {
        setMaxPointsErrorOpen(true);
    };

    // #region Snack Events
    

    return (
        <div>
            <CssBaseline />
            <Navbar registerPromotion={registerPromotion} homePage={true}/>
            <div className={classes.homePageContainers}>
                {showMainContainer &&
                    <PromotionalBanner />
                }
                {showMainContainer &&
                    <React.Fragment>
                        <Typography style={{marginTop: '20px'}} className={classes.listText}>COLABORACIONES</Typography>
                        <ColaborationBanner />
                    </React.Fragment>
                }
                {showMainContainer &&
                    <React.Fragment>
                        <TopSellBanner region={searchParamsToExecute.selectedProvince}/>
                        {/* <RequestProductBanner /> */}
                    </React.Fragment>
                }

                {showMainContainer &&
                    <React.Fragment>
                        {/* <CategoryBanner title={"DECORA TU HOGAR"} categoryId={"63754ff1d1154c3dbd4d8864"} titleColor={colors.secondary} backColor={colors.decoraTuHogar} />
                        <CategoryBanner title={"ELECTRODOMESTICOS"} categoryId={"6336b2b612ee545fae8c8aed"} titleColor={colors.whiteAppbar} backColor={colors.electrodomesticos}/>
                        <CategoryBanner title={"ASEO HIGIENE Y SALUD"} categoryId={"637280dd4b86da82439882b1"} titleColor={colors.whiteAppbar} backColor={colors.aseoHigieneSalud}/>
                        <CategoryBanner title={"ENTREGA RAPIDA"} categoryId={"1"} titleColor={colors.whiteAppbar} backColor={colors.fastDeliveryTag}/> */}
                        <Typography className={classes.topSellText} style={{color: colors.secondary}}>TODOS LOS PRODUCTOS</Typography>
                    </React.Fragment>
                }
                
                {/* {!showMainContainer &&
                <div className={classes.filterPriceAndPoints}>                               
                    <div className={classes.filterPrice}>
                        <AttachMoney className={classes.filterIcon}/>
                        <Typography className={classes.filterText}>PRECIO</Typography>
                        <div className={classes.inputHolder}>
                            <InputBase
                                elevation={4}
                                onClick={() => 
                                    {
                                        if(minPriceError) handleMinPriceErrorTooltipOpen()
                                        if(maxPriceError) handleMaxPriceErrorTooltipClose()
                                        if(minPointsError) handleMinPointsErrorTooltipClose()
                                        if(maxPointsError) handleMaxPointsErrorTooltipClose()
                                    }}
                                type="text"
                                value={searchParamsToExecute.minPrice}
                                placeholder="MIN"
                                onChange={handleChangeMinPrice}
                                classes={{
                                    root: classes.inputRootLogin,
                                    input: classes.inputInputLogin,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                            {minPriceError &&
                                <div>
                                <MuiThemeProvider theme={themeMUI}>
                                    <Tooltip classes={{arrow: classes.arrow}}
                                        arrow
                                        PopperProps={{
                                        disablePortal: true,
                                        }}
                                        onClose={handleMinPriceErrorTooltipClose}
                                        open={minPriceErrorOpen}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title={minPriceError}
                                    >
                                        <IconButton onClick={() => {
                                                        if(minPointsErrorOpen) handleMinPointsErrorTooltipClose()
                                                        if(maxPointsErrorOpen) handleMaxPointsErrorTooltipClose()
                                                        if(maxPriceErrorOpen) handleMaxPriceErrorTooltipClose()
                                                        handleMinPriceErrorTooltipOpen()
                                                    }} 
                                                    aria-label="warning" className={classes.errorButton}>
                                            <WarningAmberIcon fontSize="small" />
                                        </IconButton>
                                        
                                    </Tooltip>
                                </MuiThemeProvider>
                                </div>                       
                            }
                        </div>
                        <div className={classes.inputHolder}>
                            <InputBase
                                elevation={4}
                                onClick={() => 
                                    {
                                        if(minPriceError) handleMinPriceErrorTooltipClose()
                                        if(maxPriceError) handleMaxPriceErrorTooltipOpen()
                                        if(minPointsError) handleMinPointsErrorTooltipClose()
                                        if(maxPointsError) handleMaxPointsErrorTooltipClose()
                                    }}
                                type="text"
                                value={searchParamsToExecute.maxPrice}
                                onChange={handleChangeMaxPrice}
                                placeholder="MAX"
                                classes={{
                                    root: classes.inputRootLogin,
                                    input: classes.inputInputLogin,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                            {maxPriceError &&
                                <div>
                                <MuiThemeProvider theme={themeMUI}>
                                    <Tooltip classes={{arrow: classes.arrow}}
                                        arrow
                                        PopperProps={{
                                        disablePortal: true,
                                        }}
                                        onClose={handleMaxPriceErrorTooltipClose}
                                        open={maxPriceErrorOpen}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title={maxPriceError}
                                    >
                                        <IconButton onClick={() => {
                                                        if(minPointsErrorOpen) handleMinPointsErrorTooltipClose()
                                                        if(maxPointsErrorOpen) handleMaxPointsErrorTooltipClose()
                                                        if(minPriceErrorOpen) handleMinPriceErrorTooltipClose()
                                                        handleMaxPriceErrorTooltipOpen()
                                                    }}  aria-label="warning" className={classes.errorButton}>
                                            <WarningAmberIcon fontSize="small" />
                                        </IconButton>
                                        
                                    </Tooltip>
                                </MuiThemeProvider>
                                </div>                       
                            }
                        </div>
                    </div>
                    <div className={classes.filterPoints}>
                        <Score className={classes.filterIcon}/>
                        <Typography className={classes.filterText}>PUNTOS</Typography>
                        <div className={classes.inputHolder}>
                            <InputBase
                                elevation={4}
                                onClick={() => 
                                    {
                                        if(minPriceError) handleMinPriceErrorTooltipClose()
                                        if(maxPriceError) handleMaxPriceErrorTooltipClose()
                                        if(minPointsError) handleMinPointsErrorTooltipOpen()
                                        if(maxPointsError) handleMaxPointsErrorTooltipClose()
                                    }}
                                type="text"
                                value={searchParamsToExecute.minPoints}
                                placeholder="MIN"
                                onChange={handleChangeMinPoints}
                                classes={{
                                    root: classes.inputRootLogin,
                                    input: classes.inputInputLogin,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                            {minPointsError &&
                                <div>
                                <MuiThemeProvider theme={themeMUI}>
                                    <Tooltip classes={{arrow: classes.arrow}}
                                        arrow
                                        PopperProps={{
                                        disablePortal: true,
                                        }}
                                        onClose={handleMinPointsErrorTooltipClose}
                                        open={minPointsErrorOpen}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title={minPointsError}
                                    >
                                        <IconButton onClick={() => {
                                                        if(maxPointsErrorOpen) handleMaxPointsErrorTooltipClose()
                                                        if(minPriceErrorOpen) handleMinPriceErrorTooltipClose()
                                                        if(maxPriceErrorOpen) handleMaxPriceErrorTooltipClose()
                                                        handleMinPointsErrorTooltipOpen()
                                                    }} 
                                                    aria-label="warning" className={classes.errorButton}>
                                            <WarningAmberIcon fontSize="small" />
                                        </IconButton>
                                        
                                    </Tooltip>
                                </MuiThemeProvider>
                                </div>                       
                            }
                        </div>
                        <div className={classes.inputHolder}>
                            <InputBase
                                elevation={4}
                                onClick={() => 
                                    {
                                        if(minPriceError) handleMinPriceErrorTooltipClose()
                                        if(maxPriceError) handleMaxPriceErrorTooltipClose()
                                        if(minPointsError) handleMinPointsErrorTooltipClose()
                                        if(maxPointsError) handleMaxPointsErrorTooltipOpen()
                                    }}
                                type="text"
                                value={searchParamsToExecute.maxPoints}
                                onChange={handleChangeMaxPoints}
                                placeholder="MAX"
                                classes={{
                                    root: classes.inputRootLogin,
                                    input: classes.inputInputLogin,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                            {maxPointsError &&
                                <div>
                                <MuiThemeProvider theme={themeMUI}>
                                    <Tooltip classes={{arrow: classes.arrow}}
                                        arrow
                                        PopperProps={{
                                        disablePortal: true,
                                        }}
                                        onClose={handleMaxPointsErrorTooltipClose}
                                        open={maxPointsErrorOpen}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title={maxPointsError}
                                    >
                                        <IconButton onClick={() => {
                                                        if(minPointsErrorOpen) handleMinPointsErrorTooltipClose()
                                                        if(minPriceErrorOpen) handleMinPriceErrorTooltipClose()
                                                        if(maxPriceErrorOpen) handleMaxPriceErrorTooltipClose()
                                                        handleMaxPointsErrorTooltipOpen()
                                                    }}  aria-label="warning" className={classes.errorButton}>
                                            <WarningAmberIcon fontSize="small" />
                                        </IconButton>
                                        
                                    </Tooltip>
                                </MuiThemeProvider>
                                </div>                       
                            }
                        </div>
                    </div>            
                </div> 
                } */}

                <div style={{height:"20px"}}></div>
                <HomePageProductList />

            </div>
             
        </div> 
    )
}
  
export default HomePage