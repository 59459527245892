import React, {useState, useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ProductCard from '../../global/ProductCard';
import SkeletonCard from '../../global/SkeletonCard';
import Masonry from 'react-masonry-css';
import url from '../../../config';
import NoConnection from '../../global/NoConnection';
import NoSearch from '../../global/NoSearch';
import { useDispatch, useSelector } from 'react-redux';
import { update_products_action, update_search_params_action } from '../../../redux/actions/productsAction';

const userStyles = makeStyles((theme) => ({
    mainContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up('lg')]: {
            width: '1140px',
        },
        [theme.breakpoints.down('md')]: {
            width: '90%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '95%',
        },
    },
    errorConnection: {
        [theme.breakpoints.up('md')]: {
            marginBottom: '100px'
        }
    },
    productElement: {
        width: '208px',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('lg')]: {
            width: '90%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '90%',
        }
    },
}))
const skeleton = [-1,-2,-3,-4,-5,-6,-7,-8,-9,-10]

export const hasReached = (el) => {
    return el.getBoundingClientRect().top <= window.innerHeight;
  }

export const HomePageProductList = () => {
    const classes = userStyles()
    const [userId, setUserId] = useState()
    const [favoritesList,setFavoritesList] = useState({})
    const [loading, setLoading] = useState(false)
    const [emptySearch, setEmptySearch] = useState(false)
    const [apiError, setApiError] = useState(false)
    const isFetching = useRef(false)

    const searchParamsToExecute = useSelector((state) => state.products_store.searchParameters)
    const selector_reachFinalProduct = useSelector((state) => state.products_store.reachFinalProduct)
    const selector_products = useSelector((state) => state.products_store.products)
    const selector_products_page = useSelector((state) => state.products_store.page)
    const searchParametersRef = useRef()
    searchParametersRef.current = searchParamsToExecute
    const reachFinalProductRef = useRef()
    reachFinalProductRef.current = selector_reachFinalProduct
    const dispatch = useDispatch()
    const userLogged = useSelector((state) => state.user_store.user)

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
      }, []);
    
      useEffect(() => {
        const isUserLogged = () => {
            if(userLogged){
                setUserId(userLogged._id)
            }
        }
        isUserLogged()
    }, [userLogged])
      
    useEffect(() => {
        const abort = {current: false}
        const fetchProducts = async () => {
            try{
                if(selector_products_page === 0){
                    setLoading(true)
                    window.scrollTo(0,0)
                }
                loadFavoritesList(abort)
                if(searchParamsToExecute.page === selector_products_page){
                    if(selector_products.length === 0){
                        if(!abort.current) setEmptySearch(true)
                    }
                    return;
                }
                if(!abort.current) setEmptySearch(false)
                let productsUrl = new URL(`${url}/api/productsImport`)
                let productsPromotedUrl = new URL(`${url}/api/productsImport`)
                let params = { page:searchParamsToExecute.page, limit:10, promoted: false } 
                let paramsPromoted = { page:searchParamsToExecute.page, limit:5, promoted: true }
                if(userLogged){
                    const user = userLogged
                    params.userLoggedId = user._id
                    paramsPromoted.userLoggedId = user._id
                }
                if(searchParamsToExecute.selected !== "0"){
                    params.categoryId = searchParamsToExecute.selected
                    paramsPromoted.categoryId = searchParamsToExecute.selected
                }
                else{
                    delete params["categoryId"]
                }
                if(searchParamsToExecute.selectedProvince === "Todas las Provincias"){
                    delete params["region"]
                }
                else{
                    params.region = searchParamsToExecute.selectedProvince
                    paramsPromoted.region = searchParamsToExecute.selectedProvince
                }
                if(searchParamsToExecute.searchPhrase === null){
                    delete params["searchPhrase"]
                }
                else{
                    params.searchPhrase = searchParamsToExecute.searchPhrase
                    paramsPromoted.searchPhrase = searchParamsToExecute.searchPhrase
                }
                if(!(searchParamsToExecute.minPrice)){
                    delete params["minPrice"]
                }
                else{
                    if(parseInt(searchParamsToExecute.minPrice)){
                        params.minPrice = searchParamsToExecute.minPrice
                        paramsPromoted.minPrice = searchParamsToExecute.minPrice
                    }
                    else{
                        delete params["minPrice"]
                    }
                }
                if(!(searchParamsToExecute.maxPrice)){
                    delete params["maxPrice"]
                }
                else{
                    if(parseInt(searchParamsToExecute.maxPrice)){
                        params.maxPrice = searchParamsToExecute.maxPrice
                        paramsPromoted.maxPrice = searchParamsToExecute.maxPrice
                    }
                    else{
                        delete params["maxPrice"]
                    }
                }
                
                if(searchParamsToExecute.selectedCurrency !== "TODAS"){
                    params.currency = searchParamsToExecute.selectedCurrency
                    paramsPromoted.currency = searchParamsToExecute.selectedCurrency
                }
                else{
                    delete params["currency"]
                }
                Object.keys(params).forEach(key => productsUrl.searchParams.append(key, params[key]))
                Object.keys(paramsPromoted).forEach(key => productsPromotedUrl.searchParams.append(key, paramsPromoted[key]))
                let result = await fetch(productsUrl)
                let newData = await result.json()
                let resultPromoted = await fetch(productsPromotedUrl)
                let newPromotedData = await resultPromoted.json()
                console.log(newData)
                if(newPromotedData.length > 0){
                    for (let index = 0; index < newPromotedData.length; index++) {
                        const element = newPromotedData[index]
                        newData.splice(index*3, 0, element)
                    }
                }
                if(searchParamsToExecute.page === 1){
                    if(newData.length === 0)
                    {
                        if(!abort.current){
                            setEmptySearch(true)
                        }
                    }
                    else{
                        if(!abort.current) dispatch(update_products_action(newData, 1, newData.length < 10))
                    }
                }
                else{
                    if(!abort.current) dispatch(update_products_action(selector_products.concat(newData), searchParamsToExecute.page, newData.length < 10))
                }
                if(!abort.current) setLoading(false)
                isFetching.current = false
            } catch(error){
                console.log(error)
                if(!abort.current) setApiError(true)
                if(!abort.current) setLoading(false)
                isFetching.current = false
            }
        }
        abort.current = false
        fetchProducts()
        return () => {
            abort.current = true
        }
    }, [searchParamsToExecute, userLogged])

    const loadFavoritesList = (abort) => {
        
        if(userLogged){
            const userId = userLogged._id
            const data = window.localStorage.getItem(`importFavorites`)
            if(data){
                const newData = JSON.parse(data)
                if(newData[`${userId}`]){
                    setFavoritesList(newData[`${userId}`])
                }
            }
        }
        
    }

    const handleScroll = () => {
        const wrappedElement = document.getElementById('fecthMoreProducts');
        if (wrappedElement !== null && !isFetching.current && !reachFinalProductRef.current){
            if (wrappedElement.getBoundingClientRect().top <= window.innerHeight){
                isFetching.current = true
                dispatch(update_search_params_action({...searchParametersRef.current, page: searchParametersRef.current.page+1}))  
            }
        }
        return;
    };
    
    const breakpoints = {
        default: 5,
        1140: 4,
        900: 3,
        640: 2,
    }

    return (
        <React.Fragment>
            {apiError?
                <div className={classes.errorConnection}>
                    <NoConnection />
                </div>
            :
            <React.Fragment>
            <div className={classes.mainContainer}>
                {loading? 
                    <Masonry
                    breakpointCols={breakpoints}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                    >
                        {skeleton.map(skeletonId => (
                            <div className={classes.productElement} key={skeletonId}>
                                <SkeletonCard/>
                            </div>
                        ))}
                    </Masonry>
                :
                <React.Fragment>
                    {emptySearch?
                        <NoSearch />
                    :
                        <Masonry
                        breakpointCols={breakpoints}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                        >
                            {selector_products.map((product, index) => {
                                if((index === selector_products.length - 10) && !selector_reachFinalProduct){
                                    return(
                                        <div className={classes.productElement}>
                                            <div id="fecthMoreProducts"></div>
                                            <div key={product._id}>
                                                <ProductCard
                                                    product={product}
                                                    isFavorite={favoritesList[`${product._id}`]}
                                                    userId={userId}
                                                    homePage={true}/>
                                            </div>
                                        </div>
                                    )
                                }
                                else{
                                    return(
                                        <div className={classes.productElement} key={product._id}>
                                            <ProductCard
                                                product={product}
                                                isFavorite={favoritesList[`${product._id}`]}
                                                userId={userId}
                                                homePage={true}/>
                                        </div>
                                    )
                                }
                                
                            })}
                            
                            {!selector_reachFinalProduct?
                                skeleton.map(skeletonId => (  
                                    <div className={classes.productElement} key={skeletonId}>
                                        <SkeletonCard/>
                                    </div>
                            ))
                            :
                            <span></span>
                            }
                        </Masonry>
                    }
                </React.Fragment>
                }
                
            </div>
            </React.Fragment>
            }
        </React.Fragment>
    )
    
}