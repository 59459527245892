import React, { useState } from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { Button, Typography, CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import url, { colors } from '../../config';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles(() => ({
    cancelButton: {
        padding: '4px',
        display: 'block',
        margin: '0px 0px 0px auto'
    },
    deleteButton: {
        marginLeft: 'auto',
        marginTop: '10px',
        display: 'block',
        borderColor: colors.redError,
        borderRadius: '3px',
        border: 'solid 1.5px',
        color: colors.redError,
        width: '115px',
        '&:hover': {
            backgroundColor: alpha(colors.redError, 0.2),
        },
    },
    deleteTitle: {
        color: colors.redError, 
        marginTop:'12px', 
        fontSize:'18px', 
        fontWeight:'600'
    }
}))



  export default function DeleteProductModal({openDeleteModal, productId, productName, setOpenSuccesSnack, setOpenApiErrorSnack, closeDeleteModalParent}) {
    const classes  = useStyles()
    const descriptionElementRef = React.useRef(null);
    const [deleting, setDeleting] = useState(false)
    

    React.useEffect(() => {
        if (openDeleteModal) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
          }
        }
    }, [openDeleteModal]);

    const handleSubmitDelete = async (event) => {
        event.preventDefault();
        try{
            setDeleting(true)
            const userLogged = window.localStorage.getItem('user')
            const user = JSON.parse(userLogged)
            const requestOptions = {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json',
                           'Authorization': `${user.accessToken}`
                }   
            };
            let result = await fetch(`${url}/api/productsImport/${productId}`, requestOptions)
            if(result.status === 200){
                setDeleting(false)
                closeDeleteModalParent()
                setOpenSuccesSnack()
            }
            else{
                setOpenApiErrorSnack()
            }  
            setDeleting(false)
        }
        catch(e){
            console.log(e)
            setOpenApiErrorSnack()
            setDeleting(false)
        }
    }

    const deleteModalOnClose = () => {
        if(deleting) return
        closeDeleteModalParent()
    }
    
    return(
        <div>
            <Dialog
                PaperProps={{
                    style: { borderRadius: '15px', backgroundColor: '#f5f5f5' }
                }}
                open={openDeleteModal}
                onClose={deleteModalOnClose}
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogContent>
                    <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                    >
                        <div style={{display:'flex', marginBottom: '10px'}}>
                            <Typography className={classes.deleteTitle}>Eliminar Producto</Typography>
                            <IconButton size="large" onClick={() => {closeDeleteModalParent()}} aria-label="warning" className={classes.cancelButton}>
                                <CancelIcon />
                            </IconButton>
                        </div>
                        <Typography>Seguro desea eliminar el anuncio:</Typography>
                        <Typography style={{fontWeight: '600'}}>{productName}?</Typography>
                        {deleting?
                            <Button variant="outlined" aria-label="warning" className={classes.deleteButton}>
                                <CircularProgress size={18} style={{color:colors.redError}}/>
                            </Button>
                        :
                            <Button variant="outlined" onClick={handleSubmitDelete} aria-label="warning" className={classes.deleteButton}>
                                Eliminar
                            </Button>
                        }
                        
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    )
}