import url from "./config"

const convertedVapidKey = urlBase64ToUint8Array("BBu-Vcbl-gxrQMm8b7UUSloR5415uUV046n6wKo1TT3l0xEqk12bvu1bMdxbEqAFnqlcZp0fbbE3dyYvWzk08bo")

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - base64String.length % 4) % 4)
  // eslint-disable-next-line
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

async function sendSubscription(subscription) {
  try{
      const userLogged = window.localStorage.getItem('user')
      if(!userLogged) return;
      const user = JSON.parse(userLogged)
      let urlAccount = `${url}/api/accountImport/subscription`
      await fetch(`${urlAccount}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json',
                'Authorization': `${user.accessToken}`
      },
      body: JSON.stringify({
        subscription:subscription,
      })
    })
  }
  catch(e){
    console.log(e)
  }
}
//conditional render
let clicked = true

export function subscribeUser() {
  if(clicked) {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(function(registration) {
      if (!registration.pushManager) {
        // console.log('Push manager unavailable.')
        return
      }

      registration.pushManager.getSubscription().then(function(existedSubscription) {
        if (existedSubscription === null) {
          // console.log('No subscription detected, make a request.')
          registration.pushManager.subscribe({
            applicationServerKey: convertedVapidKey,
            userVisibleOnly: true,
          }).then(function(newSubscription) {
            // console.log('New subscription added.',newSubscription)
            sendSubscription(newSubscription)
          }).catch(function(e) {
            if (Notification.permission !== 'granted') {
              // console.log('Permission was not granted.')
            } else {
              console.error('An error ocurred during the subscription process.', e)
            }
          })
        } else {
          // console.log('Existed subscription detected.')
          sendSubscription(existedSubscription)
        }
      })
    })
      .catch(function(e) {
        console.error('An error ocurred during Service Worker registration.', e)
      })
  }
}else{
// console.log('Can not reachable to the service worker');
}}